/* eslint-disable prettier/prettier */
// Getters
export const GETTER_MONITOR_ACTIVATE_LIST: string = 'monitorActivate/GETTER_MONITOR_ACTIVATE_LIST';

// Mutations
export const MUTATION_MONITOR_ACTIVATE_LIST: string = 'monitorActivate/MUTATION_MONITOR_ACTIVATE_LIST';

// Actions
export const ACTION_MONITOR_ACTIVATE_LIST_GET_REQEUST: string =
  'monitorActivate/ACTION_MONITOR_ACTIVATE_LIST_GET_REQEUST';
