/* eslint-disable prettier/prettier */
// Getters
export const GETTER_LOGIN: string = 'login/GETTER_LOGIN';
export const GETTER_IS_TOKEN: string = 'login/GETTER_IS_TOKEN';
export const GETTER_TOKEN_VALUE: string = 'login/GETTER_TOKEN_VALUE';

// Mutations
export const MUTATION_LOGIN: string = 'login/MUTATION_LOGIN';
export const MUTATION_CLEAR_LOGIN_STATE: string = 'login/MUTATION_CLEAR_LOGIN_STATE';

// Actions
export const ACTION_LOGIN: string = 'login/ACTION_LOGIN';
export const ACTION_LOGOUT: string = 'login/ACTION_LOGOUT';
export const ACTION_SET_TOKEN_EXAM_NAME = 'login/ACTION_SET_TOKEN_EXAM_NAME';
