/* eslint-disable camelcase */
export class LogAdapter {
  constructor(public type: string | null = '', public log: string | null = '') {}
}

export type LogRequestType = {
  type: string;
  log: string;
};

export type LogResponseType = {
  status: number;
  message: string;
};
