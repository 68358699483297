/* eslint-disable camelcase */
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { ErrorStatus } from '../types/adapters/errorAdapter';
import { Endpoint } from '@/store/const/endpoint';
import {
  MonitorActivateListAdapter,
  MonitorActivateListResponseType,
} from '@/store/types/adapters/monitorActivateListAdapter';
import * as types from '@/store/types/monitorActivateListType';
import * as errTypes from '@/store/types/errorType';

const state = () => {
  return new MonitorActivateListAdapter();
};

type MonitorActivateListState = ReturnType<typeof state>;

const getters: GetterTree<MonitorActivateListState, MonitorActivateListState> = {
  [types.GETTER_MONITOR_ACTIVATE_LIST](state: MonitorActivateListState) {
    return { ...state };
  },
};

const mutations: MutationTree<MonitorActivateListState> = {
  [types.MUTATION_MONITOR_ACTIVATE_LIST](state: MonitorActivateListState, payload: MonitorActivateListAdapter) {
    state.monitors = payload.monitors;
  },
};

const actions: ActionTree<MonitorActivateListState, MonitorActivateListState> = {
  [types.ACTION_MONITOR_ACTIVATE_LIST_GET_REQEUST](context) {
    console.log('[LOG INFO] MonitorActivateListState data post');
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(Endpoint.MONITOR_ACTIVATE_LIST.url)
        .then((response: MonitorActivateListResponseType) => {
          if (response.status === 200) {
            context.commit(types.MUTATION_MONITOR_ACTIVATE_LIST, response.result);
            resolve(context.getters[types.GETTER_MONITOR_ACTIVATE_LIST]);
          } else {
            const err: ErrorStatus = {
              endpoint: Endpoint.MONITOR_ACTIVATE_LIST,
              message: response.message,
              status: response.status,
            };
            console.error('[LOG ERROR]: ', err);
            context.dispatch(errTypes.ACTION_SET_ERROR, err);
          }
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
