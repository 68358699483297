/* eslint-disable prettier/prettier */
// Getters
export const GETTER_EXAMINEE_LOGIN_PAGE: string = 'examineeLoginPage/GETTER_EXAMINEE_LOGIN_PAGE';

// Mutations
export const MUTATION_EXAMINEE_LOGIN_PAGE_TEST: string = 'examineeLoginPage/MUTATION_EXAMINEE_LOGIN_PAGE_TEST';
export const MUTATION_EXAMINEE_LOGIN_PAGE_MESSAGES: string = 'examineeLoginPage/MUTATION_EXAMINEE_LOGIN_PAGE_MESSAGES';

// Actions
export const ACTION_EXAMINEE_LOGIN_PAGE_GET_TEST: string = 'examineeLoginPage/ACTION_EXAMINEE_LOGIN_PAGE_GET_TEST';
export const ACTION_EXAMINEE_LOGIN_PAGE_GET_MESSAGE: string =
  'examineeLoginPage/ACTION_EXAMINEE_LOGIN_PAGE_GET_MESSAGE';
