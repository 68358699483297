export type kvsConfigType = {
  region?: string | any;
  channelName: string;
  clientId?: string;
  openDataChannel?: boolean;
  useTrickleICE?: boolean;
  natTraversalDisabled?: boolean;
  forceTURN?: boolean;
  accessKeyId: string;
  secretAccessKey: string;
  endpoint?: string | undefined;
  sessionToken: string | undefined;
};

export class KVSConfig {
  public defaultKVSConfig: kvsConfigType = {
    region: 'ap-northeast-1',
    channelName: '',
    clientId: '',
    openDataChannel: true,
    useTrickleICE: true,
    natTraversalDisabled: false,
    forceTURN: false,
    accessKeyId: '',
    secretAccessKey: '',
    endpoint: undefined,
    sessionToken: undefined,
  };

  constructor() {}

  setKVSConfig(config: kvsConfigType): void {
    this.defaultKVSConfig = Object.assign(this.defaultKVSConfig, config);
  }
}
