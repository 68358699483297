/* eslint-disable prettier/prettier */
// Getters
export const GETTER_MANAGEMENT_PAGE_GET_INITIAL_PARAM_ORIGIN: string =
  'managementPage/GETTER_MANAGEMENT_PAGE_GET_INITIAL_PARAM_ORIGIN';
export const GETTER_MANAGEMENT_PAGE_GET_INITIAL_PARAM_DOMAIN: string =
  'managementPage/GETTER_MANAGEMENT_PAGE_GET_INITIAL_PARAM_DOMAIN';
export const GETTER_MANAGEMENT_PAGE_GET_SUMMARY_LOGINS_TESTER_COUNT: string =
  'managementPage/GETTER_MANAGEMENT_PAGE_GET_SUMMARY_LOGINS_TESTER_COUNT';
export const GETTER_MANAGEMENT_PAGE_GET_SUMMARY_LOGINS_OTHER_COUNT: string =
  'managementPage/GETTER_MANAGEMENT_PAGE_GET_SUMMARY_LOGINS_OTHER_COUNT';
export const GETTER_MANAGEMENT_PAGE_GET_SUMMARY_DELETE_PLAN_TESTER_COUNT: string =
  'managementPage/GETTER_MANAGEMENT_PAGE_GET_SUMMARY_DELETE_PLAN_TESTER_COUNT';
export const GETTER_MANAGEMENT_PAGE_GET_SUMMARY_DELETE_PLAN_RECORD_COUNT: string =
  'managementPage/GETTER_MANAGEMENT_PAGE_PAGE_GET_SUMMARY_DELETE_PLAN_RECORD_COUNT';

// Mutations
export const MUTATION_MANAGEMENT_PAGE_SET_INITIAL_PARAM: string =
  'managementPage/MUTATION_MANAGEMENT_PAGE_SET_INITIAL_PARAM';
export const MUTATION_MANAGEMENT_PAGE_SET_SUMMARY: string = 'managementPage/MUTATION_MANAGEMENT_PAGE_SET_SUMMARY';

// Actions
export const ACTION_MANAGEMENT_PAGE_GET_INITIAL_PARAM: string =
  'managementPage/ACTION_MANAGEMENT_PAGE_GET_INITIAL_PARAM';
export const ACTION_MANAGEMENT_PAGE_GET_SUMMARY: string = 'managementPage/ACTION_MANAGEMENT_PAGE_GET_SUMMARY';
