/* eslint-disable camelcase */
import { TesterAdapter } from './testerAdapter';
import { Matching, SignalingChannel } from '@/store/types/adapters/matchingAdapter';
import { ViewerKVS } from '~/plugins/kvs/viewer';
export class MonitorPageAdapter {
  constructor(
    public matchings: Matching[] = [],
    public mainMediaStream?: MediaStream,
    public mainChat: string[] = [],
    public testerUniqueKey: string = '',
    // TODO : subChatからmainChatに移すか、もしくはsubChatをそのまま使うか。実装にて判断。
    public testerChats: {
      [key: string]: {
        kvsViewer: ViewerKVS;
        subChat: { sender: string; message: string }[];
        tester: TesterAdapter;
        rejected: number;
        elapsedTime: string;
        intervalTimeout: NodeJS.Timeout | null;
      };
    } = {},
    public inAuth: boolean = false,
    public reservedRemoveOfTester = [] as string[]
  ) {}
}
