/* eslint-disable prettier/prettier */
// Getters
export const GETTER_MONITOR_PAGE: string = 'monitorPage/GETTER_MONITOR_PAGE';
export const GETTER_MATCHINGS: string = 'monitorPage/GETTER_MATCHINGS';

// Mutations
export const MUTATION_MONITOR_PAGE_SET_MATCHING: string = 'monitorPage/MUTATION_MONITOR_PAGE_SET_MATCHING';
export const MUTATION_MONITOR_PAGE_SET_TESTER_UNIQUE_KEY: string =
  'monitorPage/MUTATION_MONITOR_PAGE_SET_TESTER_UNIQUE_KEY';
export const MUTATION_MONITOR_PAGE_SET_IN_AUTH: string = 'monitorPage/MUTATION_MONITOR_PAGE_SET_IN_AUTH';

// Actions
export const ACTION_MONITOR_PAGE: string = 'monitorPage/ACTION_MONITOR_PAGE';
export const ACTION_MONITOR_PAGE_MARKS: string = 'monitorPage/ACTION_MONITOR_PAGE_MARKS';
export const ACTION_MONITOR_PAGE_NOTIFY_LIST: string = 'monitorPage/ACTION_MONITOR_PAGE_NOTIFY_LIST';
export const ACTION_MONITOR_PAGE_GET_MATCHING: string = 'monitorPage/ACTION_MONITOR_PAGE_GET_MATCHING';
export const ACTION_MONITOR_PAGE_RECORD_MARKING: string = 'monitorPage/ACTION_MONITOR_PAGE_RECORD_MARKING';
export const ACTION_MONITOR_PAGE_HEAD_SHOT: string = 'monitorPage/ACTION_MONITOR_PAGE_HEAD_SHOT';
export const ACTION_MONITOR_PAGE_TESTER_KICK_OUT: string = 'monitorPage/ACTION_MONITOR_PAGE_TESTER_KICK_OUT';
export const ACTION_MONITOR_PAGE_ACCEPT: string = 'monitorPage/ACTION_MONITOR_PAGE_ACCEPT';
export const ACTION_MONITOR_PAGE_GET_TESTER: string = 'monitorPage/ACTION_MONITOR_PAGE_GET_TESTER';
export const ACTION_MONITOR_PAGE_START_VIEWR_KVS: string = 'monitorPage/ACTION_MONITOR_PAGE_START_VIEWR_KVS';
export const ACTION_MONITOR_PAGE_STOP_VIEWR_KVS: string = 'monitorPage/ACTION_MONITOR_PAGE_STOP_VIEWR_KVS';
export const ACTION_MONITOR_PAGE_SET_TESTER_UNIQUE_KEY: string =
  'monitorPage/ACTION_MONITOR_PAGE_SET_TESTER_UNIQUE_KEY';
export const ACTION_MONITOR_PAGE_SET_HEAD_SHOT_URL: string = 'monitorPage/ACTION_MONITOR_PAGE_SET_HEAD_SHOT_URL';
export const ACTION_MONITOR_PAGE_SET_ID_CARD_URL: string = 'monitorPage/ACTION_MONITOR_PAGE_SET_ID_CARD_URL';
export const ACTION_MONITOR_PAGE_DELETE_MATCHING: string = 'monitorPage/ACTION_MONITOR_PAGE_DELETE_MATCHING';
export const ACTION_MONITOR_PAGE_SET_IN_AUTH: string = 'monitorPage/ACTION_MONITOR_PAGE_SET_IN_AUTH';
export const ACTION_MONITOR_PAGE_REJECT: string = 'monitorPage/ACTION_MONITOR_PAGE_REJECT';
