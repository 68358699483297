import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { ErrorStatus } from '../types/adapters/errorAdapter';
import {
  TesterHeadShotAdapter,
  TesterHeadShotRequestType,
  TesterHeadShotResponseType,
} from '@/store/types/adapters/testerHeadShotAdapter';
import * as types from '@/store/types/testerHeadShotType';
import * as errTypes from '@/store/types/errorType';
import { Endpoint } from '@/store/const/endpoint';

const state = () => {
  return new TesterHeadShotAdapter();
};

type TesterHeadShotState = ReturnType<typeof state>;

const getters: GetterTree<TesterHeadShotState, TesterHeadShotState> = {
  [types.GETTER_TESTER_HEAD_SHOT](state: TesterHeadShotState) {
    return { ...state };
  },
};

const mutations: MutationTree<TesterHeadShotState> = {
  [types.MUTATION_TESTER_HEAD_SHOT](state: TesterHeadShotState, payload: TesterHeadShotAdapter) {
    state.testerId = payload.testerId;
    state.image = payload.image;
    state.contentType = payload.contentType;
    state.url = payload.url;
  },
};

const actions: ActionTree<TesterHeadShotState, TesterHeadShotState> = {
  [types.ACTION_TESTER_HEAD_SHOT](context, req: TesterHeadShotRequestType) {
    console.log(req);
    return new Promise((resolve) => {
      this.$axios
        .$post(Endpoint.TESTER_HEAD_SHOT.url, {
          tester_id: req.testerId,
          image: req.image,
          content_type: req.contentType,
        })
        .then((response: TesterHeadShotResponseType) => {
          if (response.status === 200) {
            context.commit(types.MUTATION_TESTER_HEAD_SHOT, {
              testerId: req.testerId,
              image: req.image,
              contentType: req.contentType,
              url: response.result.url,
            });
            resolve(response.result.url);
          } else {
            const err: ErrorStatus = {
              endpoint: Endpoint.TESTER_HEAD_SHOT,
              status: response.status,
              message: response.message,
            };
            context.dispatch(errTypes.ACTION_SET_ERROR, err);
          }
        })
        .catch((e: any) => {
          // TODO: エラー時の処理を追加
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
