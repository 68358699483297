import { Marking, Record } from '@/store/types/adapters/commonMarkingTimelineAdapter';

/* eslint-disable camelcase */
export class TesterDetailAdapter {
  constructor(
    public userId: number = 0,
    public testId: number | null = null,
    public loginId: string = '',
    public exLoginId: string = '',
    public groupId: string = '',
    public group: string = '',
    public testName: string = '',
    public region: string = '',
    public testAt: string | null = null,
    public stopAt: string | null = null,
    public examName: string = '',
    public rExamName: string = '',
    public isMcStartup: boolean = false,
    public matchedAt: string | null = null,
    public authenticatedAt: string | null = null,
    public loginAt: string | null = null,
    public logoutAt: string | null = null,
    public aiNameMatch: number | null = null,
    public startupParameters: {
      inServer: string;
      target: string;
      actor: number | null;
      lang: string;
      isMobile: boolean;
      isMcStartup: boolean;
      isProctor: boolean;
      isAuth: boolean;
      examUrl: string;
      region: string;
      examName: string;
      examDatetime: string;
      isVoiceRecording: boolean;
      isRecord: boolean;
      isSummary: boolean;
      maxRectime: number | null;
      matchingTimeout: number | null;
      testerRetry: number | null;
      checkerRetry: number | null;
      webrtcTimeout: number | null;
      intervaltime: number | null;
      webrtcMaxTime: number | null;
      isDebug: number | null;
      isConv: number | null;
      videoRecordingPreference: number | null;
      voiceQualityPreference: number | null;
      passwordUrl: string;
      memo: string;
      isAiAuth: number | null;
      isAiFaildManual: number | null;
      isAiIdcardRequest: number | null;
      aiFaceRetry: number | null;
      aiIdcardRetry: number | null;
      aiAllRetry: number | null;
      aiIdcardType: number[];
      aiNameMatch: number | null;
      aiAfterAnalysis: number[];
    } = {
      inServer: '',
      target: '',
      actor: null,
      lang: '',
      isMobile: false,
      isMcStartup: false,
      isProctor: false,
      isAuth: false,
      examUrl: '',
      region: '',
      examName: '',
      examDatetime: '',
      isVoiceRecording: false,
      isRecord: false,
      isSummary: false,
      maxRectime: null,
      matchingTimeout: null,
      testerRetry: null,
      checkerRetry: null,
      webrtcTimeout: null,
      intervaltime: null,
      webrtcMaxTime: null,
      isDebug: null,
      isConv: null,
      videoRecordingPreference: null,
      voiceQualityPreference: null,
      passwordUrl: '',
      memo: '',
      isAiAuth: null,
      isAiFaildManual: null,
      isAiIdcardRequest: null,
      aiFaceRetry: null,
      aiIdcardRetry: null,
      aiAllRetry: null,
      aiIdcardType: [],
      aiNameMatch: null,
      aiAfterAnalysis: [],
    },
    public headShotUri: string = '',
    public idCardUri: string = '',
    public markingSummaries: MarkingSummary[] = [],
    public markingTotalScore: number = 0,
    public markings: Marking[] = [],
    public aiMarkingSummaries: MarkingSummary[] = [],
    public aiMarkingTotalScore: number = 0,
    public aiMarkings: Marking[] = [],
    public records: RecordDetail[] = [],
    public exchanges: {
      message: string;
      sendType: number;
      sendAt: string;
    }[] = []
  ) {}

  public static fromResponse(response: Result): TesterDetailAdapter {
    const r = new TesterDetailAdapter();

    r.userId = response.user_id;
    r.testId = response.test_id;
    r.loginId = response.login_id;
    r.exLoginId = response.ex_login_id;
    r.groupId = response.group_id;
    r.group = response.group;
    r.testName = response.test_name;
    r.region = response.region;
    r.testAt = response.test_at;
    r.stopAt = response.stop_at;
    r.examName = response.exam_name;
    r.rExamName = response.r_exam_name;
    r.isMcStartup = response.is_mc_startup;
    r.matchedAt = response.matched_at;
    r.authenticatedAt = response.authenticated_at;
    r.loginAt = response.login_at;
    r.logoutAt = response.logout_at;
    r.aiNameMatch = response.ai_namematch;
    r.startupParameters = {
      inServer: response.startup_parameters.in_server as string,
      target: response.startup_parameters.target as string,
      actor: response.startup_parameters.actor as number | null,
      lang: response.startup_parameters.lang as string,
      isMobile: response.startup_parameters.is_mobile as boolean,
      isMcStartup: response.startup_parameters.is_mc_startup as boolean,
      isProctor: response.startup_parameters.is_proctor as boolean,
      isAuth: response.startup_parameters.is_auth as boolean,
      examUrl: response.startup_parameters.exam_url as string,
      region: response.startup_parameters.region as string,
      examName: response.startup_parameters.exam_name as string,
      examDatetime: response.startup_parameters.exam_datetime as string,
      isVoiceRecording: response.startup_parameters.is_voice_recording as boolean,
      isRecord: response.startup_parameters.is_record as boolean,
      isSummary: response.startup_parameters.is_summary as boolean,
      maxRectime: response.startup_parameters.max_rectime as number | null,
      matchingTimeout: response.startup_parameters.matching_timeout as number | null,
      testerRetry: response.startup_parameters.tester_retry as number | null,
      checkerRetry: response.startup_parameters.checker_retry as number | null,
      webrtcTimeout: response.startup_parameters.webrtc_timeout as number | null,
      intervaltime: response.startup_parameters.intervaltime as number | null,
      webrtcMaxTime: response.startup_parameters.webrtc_max_time as number | null,
      isDebug: response.startup_parameters.is_debug as number | null,
      isConv: response.startup_parameters.is_conv as number | null,
      videoRecordingPreference: response.startup_parameters.video_recording_preference as number | null,
      voiceQualityPreference: response.startup_parameters.voice_quality_preference as number | null,
      passwordUrl: response.startup_parameters.password_url as string,
      memo: response.startup_parameters.memo as string,
      isAiAuth: response.startup_parameters.is_ai_auth as number | null,
      isAiFaildManual: response.startup_parameters.is_ai_faild_manual as number | null,
      isAiIdcardRequest: response.startup_parameters.is_ai_idcard_request as number | null,
      aiFaceRetry: response.startup_parameters.ai_face_retry as number | null,
      aiIdcardRetry: response.startup_parameters.ai_idcard_retry as number | null,
      aiAllRetry: response.startup_parameters.ai_all_retry as number | null,
      aiIdcardType: response.startup_parameters.ai_idcard_type as number[],
      aiNameMatch: response.startup_parameters.ai_namematch as number | null,
      aiAfterAnalysis: response.startup_parameters.ai_after_analysis as number[],
    };
    r.headShotUri = response.head_shot_uri;
    r.idCardUri = response.id_card_uri;
    // 手動マーキング
    r.markingSummaries = (response.marking_summaries || []).map((v) => {
      return {
        mark: v.mark,
        count: v.count,
      };
    });
    r.markingTotalScore = response.marking_total_score;
    r.markings = (response.markings || []).map((v) => {
      return {
        markingAt: v.marking_at,
        url: v.url,
        mark: v.mark,
        recordId: v.record_id,
      };
    });
    // AIマーキング
    r.aiMarkingSummaries = (response.ai_marking_summaries || []).map((v) => {
      return {
        mark: v.mark,
        count: v.count,
      };
    });
    r.aiMarkingTotalScore = response.ai_marking_total_score;
    r.aiMarkings = (response.ai_markings || []).map((v) => {
      return {
        markingAt: v.marking_at,
        url: v.url,
        mark: v.mark,
        recordId: v.record_id,
      };
    });
    // レコード
    r.records = (response.records || []).map((v) => {
      return {
        id: v.id,
        startAt: v.start_at as string,
        stopAt: v.stop_at as string,
        url: v.url,
        encodedAt: v.encoded_at,
        aiAnalysisBatchReflectedAt: v.ai_analysis_batch_reflected_at,
        aiAnalysisReflectedAt: v.ai_analysis_reflected_at,
        aiAnalysisBatchFlag: v.ai_analysis_batch_flag,
        aiAnalysisFlag: v.ai_analysis_flag,
        movieStatus: v.movie_status,
        detail: v.detail,
        playTimeFrom: v.play_time_from,
        playTimeTo: v.play_time_to,
      };
    });
    r.exchanges = (response.exchanges || []).map((v) => {
      return {
        message: v.message,
        sendType: v.send_type,
        sendAt: v.send_at,
      };
    });

    return r;
  }
}

export type MarkingSummary = {
  mark: string | null;
  count: number | null;
};

export type RecordDetail = Record & {
  encodedAt: string | null;
  aiAnalysisBatchReflectedAt: string | null;
  aiAnalysisReflectedAt: string | null;
  aiAnalysisBatchFlag: number;
  aiAnalysisFlag: number;
  movieStatus: number;
  detail: string;
};

export enum TesterDetailParamLatestMode {
  NONE = 0,
  LATEST = 1,
  EXAMINING_LATEST = 2,
}

export type TesterDetailRequestType = {
  user_id: number;
  test_id: number | null;
  latest_mode: TesterDetailParamLatestMode;
};

export type TesterDetailResponseType = {
  status: number;
  message: string;
  result: Result;
};

type Result = {
  user_id: number;
  test_id: number | null;
  login_id: string;
  ex_login_id: string;
  group_id: string;
  group: string;
  test_name: string;
  region: string;
  test_at: string | null;
  stop_at: string | null;
  exam_name: string;
  r_exam_name: string;
  is_mc_startup: boolean;
  matched_at: string | null;
  authenticated_at: string | null;
  login_at: string | null;
  logout_at: string | null;
  ai_namematch: number | null;
  startup_parameters: {
    in_server?: string;
    target?: string;
    actor?: number | null;
    lang?: string;
    is_mobile?: boolean;
    is_mc_startup?: boolean;
    is_proctor?: boolean;
    is_auth?: boolean;
    exam_url?: string;
    region?: string;
    exam_name?: string;
    exam_datetime?: string;
    is_voice_recording?: boolean;
    is_record?: boolean;
    is_summary?: boolean;
    max_rectime?: number | null;
    matching_timeout?: number | null;
    tester_retry?: number | null;
    checker_retry?: number | null;
    webrtc_timeout?: number | null;
    intervaltime?: number | null;
    webrtc_max_time?: number | null;
    is_debug?: number | null;
    is_conv?: number | null;
    video_recording_preference?: number | null;
    voice_quality_preference?: number | null;
    password_url?: string;
    memo?: string;
    is_ai_auth?: number | null;
    is_ai_faild_manual?: number | null;
    is_ai_idcard_request?: number | null;
    ai_face_retry?: number | null;
    ai_idcard_retry?: number | null;
    ai_all_retry?: number | null;
    ai_idcard_type?: number[];
    ai_namematch?: number | null;
    ai_after_analysis?: number[];
  };
  head_shot_uri: string;
  id_card_uri: string;
  marking_summaries: {
    mark: string;
    count: number;
  }[];
  marking_total_score: number;
  markings: {
    marking_at: string;
    url: string;
    mark: string;
    record_id: number;
  }[];
  ai_marking_summaries: {
    mark: string;
    count: number;
  }[];
  ai_marking_total_score: number;
  ai_markings: {
    marking_at: string;
    url: string;
    mark: string;
    record_id: number;
  }[];
  records: {
    id: string;
    start_at: string | null;
    stop_at: string | null;
    url: string;
    encoded_at: string | null;
    ai_analysis_batch_reflected_at: string | null;
    ai_analysis_reflected_at: string | null;
    ai_analysis_batch_flag: number;
    ai_analysis_flag: number;
    movie_status: number;
    detail: string;
    play_time_from: string;
    play_time_to: string;
  }[];
  exchanges: {
    message: string;
    send_type: number;
    send_at: string;
  }[];
};
