/* eslint-disable camelcase */
export class MonitorActivateAdapter {
  constructor(public monitorId: number | null = 0, public activation: number | null = 0) {}
}

type MonitorActivateRequestType = {
  monitor_id: number; // 1~4: sub monitor number
  activation: number; // 0: 無効, 1: 有効
};

// マッチング処理 レスポンスパラメータ
export type MonitorActivateResponseType = {
  status: number;
  result: null | {};
  message: string;
};
