































import Vue, { PropType } from 'vue';
import { LanguageEnum } from '@/store/enum/language';
import * as rootTypes from '@/store/types/rootType';
import { ModalOptions } from '@/plugins/global/modals';

export default Vue.extend({
  name: 'AlertModal',
  props: {
    modalOptions: {
      type: Object as PropType<ModalOptions>,
      required: true,
    },
  },
  computed: {
    displayLang(): LanguageEnum {
      return this.$store.getters[rootTypes.GETTER_DISPLAY_LANG];
    },
    dialogClass(): string {
      let classes: string[] = [];

      return classes.join(' ');
    },
    modalClass(): string {
      let classes: string[] = [];

      switch (this.modalOptions.modalType) {
        case 'error':
          classes = classes.concat(['failure-modal']);
          break;

        case 'success':
        default:
          classes = classes.concat(['success-modal']);
          break;
      }

      return classes.join(' ');
    },
    bodyStrings(): string[] {
      if (typeof this.modalOptions.body === 'string') {
        return (this.modalOptions.body as string).split('\n');
      }

      return this.modalOptions.body as string[];
    },
  },
});
