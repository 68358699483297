























import Vue from 'vue';
import StartupAdapter from '../../store/types/adapters/startupAdapter';
import * as rootTypes from '@/store/types/rootType';
import { LanguageEnum } from '~/store/enum/language';
export default Vue.extend({
  name: 'Footer',
  computed: {
    inParams(): StartupAdapter {
      return this.$store.getters[rootTypes.GETTER_STARTUP];
    },
    displayLang(): LanguageEnum {
      return this.$store.getters[rootTypes.GETTER_DISPLAY_LANG];
    },
  },
  methods: {
    isExamee() {
      // TODO: Enum
      return this.inParams.actor === 1; // FIXME: 受験者のみ
    },
    onClickTerm(event: MouseEvent) {
      const route = this.$router.resolve({ name: 'terms' });
      window.open(route.href + '/');
      event.preventDefault();
    },
  },
});
