/* eslint-disable prettier/prettier */
import { Actor, ActorUtils } from '@/store/enum/Actor';

type PagePathInfo = {
  path: string;
  match: (self: PagePathInfo, path: string) => boolean;
  bodyId: string;
};

/**
 * パスの比較関数
 *
 * @param {PagePathInfo} self
 * @param {string} path
 * @return {boolean}
 */
const equals = (self: PagePathInfo, path: string): boolean => {
  return self.path == path;
};

/**
 * @const ページパス情報の連想配列
 */
export const pagePathInfoMap: { [pageName: string]: PagePathInfo } = {
  //========================================
  // 共通ページ
  //========================================
  LOGIN_PAGE: {
    path: '/login',
    match: equals,
    bodyId: 'PRE00001',
  },
  REDIRECT_PAGE: {
    path: '/redirect',
    match: equals,
    bodyId: '',
  },
  IFRAME_PAGE: {
    path: '/iframe',
    match: equals,
    bodyId: '',
  },
  ALERTING_PAGE: {
    path: '/alerting',
    match: (self: PagePathInfo, path: string): boolean => {
      return /^\/alerting\/\d+\/?$/.test(path);
    },
    bodyId: '',
  },
  ERROR_PAGE: {
    path: '/error',
    match: equals,
    bodyId: '',
  },
  CLOSE_PAGE: {
    path: '/close',
    match: equals,
    bodyId: '',
  },
  //========================================
  // 受験者
  //========================================
  ENV_SETTING_PAGE: {
    path: '/env-setting',
    match: equals,
    bodyId: '',
  },
  IDENTIFICATION_PAGE: {
    path: '/identification',
    match: equals,
    bodyId: '',
  },
  AI_IDENTIFICATION_PAGE: {
    path: '/identification-ai',
    match: equals,
    bodyId: '',
  },
  EXAMINING_PAGE: {
    path: '/examining',
    match: equals,
    bodyId: 'PRE00004',
  },
  EXAM_END_PAGE: {
    path: '/exam-end',
    match: equals,
    bodyId: 'PRE00005',
  },
  //========================================
  // 監視者/管理者
  //========================================
  MONITORING_PAGE: {
    path: '/monitoring',
    match: equals,
    bodyId: '',
  },
  MANAGEMENT_PAGE: {
    path: '/management',
    match: equals,
    bodyId: 'PRE00007',
  },
  EXAMINEES_PAGE: {
    path: '/examinees',
    match: equals,
    bodyId: 'PRE00007',
  },
  EXAMINEES_DETAIL_PAGE: {
    path: '/examinees/\\d', // パスに受験者IDとか入る場合は正規表現でいい？
    match: (self: PagePathInfo, path: string): boolean => {
      return /^\/examinees\/\d+\/?$/.test(path);
    },
    bodyId: 'PRE00007',
  },
  CHECKERS_MONITORING_PAGE: {
    path: '/checkers/monitoring',
    match: equals,
    bodyId: 'PRE00007',
  },
  ANALYSISI_REQUEST_PAGE: {
    path: '/analysis-request',
    match: equals,
    bodyId: 'PRE00007',
  },
  ACCESS_LIMIT_PAGE: {
    path: '/access-limit',
    match: equals,
    bodyId: 'PRE00007',
  },
  LOGVIEW_PAGE: {
    path: '/logview',
    match: equals,
    bodyId: 'PRE00007',
  },
  DELETE_INFO: {
    path: '/delete-info',
    match: equals,
    bodyId: 'PRE00007',
  },
  DELETE_PLAN: {
    path: '/delete-plan',
    match: equals,
    bodyId: 'PRE00007',
  },
  LOGINS_PAGE: {
    path: '/logins',
    match: equals,
    bodyId: 'PRE00007',
  },
};

type AllowedPage = {
  firstPage: PagePathInfo;
  pageList: PagePathInfo[];
};

/**
 * @const
 */
const commonAllowdPageList: PagePathInfo[] = [
  pagePathInfoMap.LOGIN_PAGE,
  pagePathInfoMap.REDIRECT_PAGE,
  pagePathInfoMap.IFRAME_PAGE,
  pagePathInfoMap.ALERTING_PAGE,
  pagePathInfoMap.ERROR_PAGE,
  pagePathInfoMap.CLOSE_PAGE,
];
/**
 * @const 許可されたページパス情報の連想配列
 */
const allowedPageMap: { [actor: number /*Actor*/]: AllowedPage } = (function () {
  const make = (actor: Actor): AllowedPage => {
    // 未ログインの場合に許可されている共通ページ

    // TODO : 引き続きactor専用ページ追加
    if (actor === Actor.TESTER) {
      return {
        firstPage: pagePathInfoMap.ENV_SETTING_PAGE,
        pageList: [
          pagePathInfoMap.ENV_SETTING_PAGE,
          pagePathInfoMap.IDENTIFICATION_PAGE,
          pagePathInfoMap.AI_IDENTIFICATION_PAGE,
          pagePathInfoMap.EXAMINING_PAGE,
          pagePathInfoMap.EXAM_END_PAGE,
        ].concat(commonAllowdPageList),
      };
    } else if (actor === Actor.CHECKER) {
      return {
        firstPage: pagePathInfoMap.MONITORING_PAGE,
        pageList: [pagePathInfoMap.MONITORING_PAGE].concat(commonAllowdPageList),
      };
    } else if (actor === Actor.TEST_MANAGER) {
      return {
        firstPage: pagePathInfoMap.EXAMINEES_PAGE,
        pageList: [pagePathInfoMap.EXAMINEES_PAGE, pagePathInfoMap.EXAMINEES_DETAIL_PAGE].concat(commonAllowdPageList),
      };
    } else if (actor === Actor.SYSTEM_MANAGER) {
      return {
        firstPage: pagePathInfoMap.MANAGEMENT_PAGE,
        pageList: [
          pagePathInfoMap.MANAGEMENT_PAGE,
          pagePathInfoMap.EXAMINEES_PAGE,
          pagePathInfoMap.EXAMINEES_DETAIL_PAGE,
          pagePathInfoMap.CHECKERS_MONITORING_PAGE,
          pagePathInfoMap.ACCESS_LIMIT_PAGE,
          pagePathInfoMap.LOGVIEW_PAGE,
          pagePathInfoMap.DELETE_INFO_PAGE,
          pagePathInfoMap.DELETE_PLAN_PAGE,
          pagePathInfoMap.LOGINS_PAGE,
        ].concat(commonAllowdPageList),
      };
    } else if (actor === Actor.SUPERVISOR) {
      return {
        firstPage: pagePathInfoMap.MONITORING_PAGE,
        pageList: [pagePathInfoMap.MONITORING_PAGE].concat(commonAllowdPageList),
      };
    }
    throw new Error(`定義されていないアクターが指定されました。(actor=${actor})`);
  };

  const result: { [actor: number /*Actor*/]: AllowedPage } = {};
  ActorUtils.getList().forEach((actor) => {
    result[actor as number] = make(actor);
  });
  return result;
})();

export const PagePath: { [pageName: string]: string } = (function () {
  const result: { [pageName: string]: string } = {};
  Object.entries(pagePathInfoMap).forEach(([key, value]) => {
    result[key] = value.path;
  });
  return result;
})();

export class PagePathUtils {
  /**
   * ログイン後のページパスを取得します
   *
   * @param {Actor} actor
   * @return {string}
   */
  public static getFirstPagePath(actor: Actor): string {
    const allowedPage = allowedPageMap[actor];
    if (!allowedPage) {
      return pagePathInfoMap.ERROR_PAGE.path;
    }

    return allowedPage.firstPage.path;
  }

  /**
   * 指定のパスが許可されたパスか調べます
   *
   * @param {boolean} isLoggedIn
   * @param {Actor | null} actor
   * @param {string} currentPath
   * @return {boolean}
   */
  public static isAllowedPage(isLoggedIn: boolean, actor: Actor | null, currentPath: string): boolean {
    if (!isLoggedIn) {
      // 未ログインの場合
      return commonAllowdPageList.some((pagePathInfo: PagePathInfo) => pagePathInfo.match(pagePathInfo, currentPath));
    } else {
      // ログイン済みの場合
      const allowedPage = allowedPageMap[actor as Actor];
      if (!allowedPage) {
        throw new Error(`定義されていないアクターが指定されました。(actor=${actor})`);
      }

      return allowedPage.pageList.some((pagePathInfo: PagePathInfo) => pagePathInfo.match(pagePathInfo, currentPath));
    }
  }

  /**
   * 指定のパスの<body>タグのIDを取得します
   *
   * @param {string} currentPath
   * @return {boolean}
   */
  public static getBodyId(currentPath: string): string {
    const found = Object.values(pagePathInfoMap).find((pagePathInfo) =>
      pagePathInfo.match(pagePathInfo, currentPath)
    ) as PagePathInfo;
    if (found) {
      return found.bodyId;
    }
    return '';
  }
}
