import { Base64 } from 'aws-sdk/clients/ecr';

/* eslint-disable camelcase */
export class TesterHeadShotAdapter {
  constructor(
    public testerId: number | null = 0,
    public image: Base64 | null = '',
    public contentType: string | null = '',
    public url: string = ''
  ) {}
}

// FIXME k-nishigaki 2020/07/15 snake case
export type TesterHeadShotRequestType = {
  testerId: number;
  image: Base64;
  contentType: string;
};

export type TesterHeadShotResponseType = {
  status: number;
  message: string;
  result: {
    url: string;
  };
};
