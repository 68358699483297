/* eslint-disable prettier/prettier */
// Getters
export const GETTER_ACCESS_LIMIT_PAGE_GET_FORM_VALUES: string =
  'accessLimitPage/GETTER_ACCESS_LIMIT_PAGE_GET_FORM_VALUES';
export const GETTER_ACCESS_LIMIT_PAGE_GET_TABLE_VALUES: string =
  'accessLimitPage/GETTER_ACCESS_LIMIT_PAGE_GET_TABLE_VALUES';

// Mutations
export const MUTATION_ACCESS_LIMIT_PAGE_SET_FORM_VALUES: string =
  'accessLimitPage/MUTATION_ACCESS_LIMIT_PAGE_SET_FORM_VALUES';
export const MUTATION_ACCESS_LIMIT_PAGE_CLEAR_FORM_VALUES: string =
  'accessLimitPage/MUTATION_ACCESS_LIMIT_PAGE_CLEAR_FORM_VALUES';
export const MUTATION_ACCESS_LIMIT_PAGE_SET_TABLE_VALUES: string =
  'accessLimitPage/MUTATION_ACCESS_LIMIT_PAGE_SET_TABLE_VALUES';
export const MUTATION_ACCESS_LIMIT_PAGE_CLEAR_TABLE_VALUES: string =
  'accessLimitPage/MUTATION_ACCESS_LIMIT_PAGE_CLEAR_TABLE_VALUES';

// Actions
export const ACTION_ACCESS_LIMIT_PAGE_SET_FORM_VALUES: string =
  'accessLimitPage/ACTION_ACCESS_LIMIT_PAGE_SET_FORM_VALUES';
export const ACTION_ACCESS_LIMIT_PAGE_CLEAR_FORM_VALUES: string =
  'accessLimitPage/ACTION_ACCESS_LIMIT_PAGE_CLEAR_FORM_VALUES';
export const ACTION_ACCESS_LIMIT_PAGE_SET_TABLE_VALUES: string =
  'accessLimitPage/ACTION_ACCESS_LIMIT_PAGE_SET_TABLE_VALUES';
export const ACTION_ACCESS_LIMIT_PAGE_CLEAR_TABLE_VALUES: string =
  'accessLimitPage/ACTION_ACCESS_LIMIT_PAGE_CLEAR_TABLE_VALUES';
export const ACTION_ACCESS_LIMIT_PAGE_CALL_API_SELECT: string =
  'accessLimitPage/ACTION_ACCESS_LIMIT_PAGE_CALL_API_SELECT';
export const ACTION_ACCESS_LIMIT_PAGE_CALL_API_INSERT: string =
  'accessLimitPage/ACTION_ACCESS_LIMIT_PAGE_CALL_API_INSERT';
export const ACTION_ACCESS_LIMIT_PAGE_CALL_API_DELETE: string =
  'accessLimitPage/ACTION_ACCESS_LIMIT_PAGE_CALL_API_DELETE';
