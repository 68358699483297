/* eslint-disable prettier/prettier */
// Getters
export const GETTER_TESTER_RECORDING: string = 'testerRecording/GETTER_TESTER_RECORDING';

// Mutations
export const MUTATION_TESTER_RECORDING: string = 'testerRecording/MUTATION_TESTER_RECORDING';
export const MUTATION_TESTER_RECORDING_CLEAR: string = 'testerRecording/MUTATION_TESTER_RECORDING_CLEAR';

// Actions
export const ACTION_TESTER_RECORDING: string = 'testerRecording/ACTION_TESTER_RECORDING';
export const ACTION_GET_TESTER_RECORDING: string = 'testerRecording/ACTION_GET_TESTER_RECORDING';
export const ACTION_TESTER_RECORDING_START: string = 'testerRecording/ACTION_TESTER_RECORDING_START';
export const ACTION_TESTER_RECORDING_END: string = 'testerRecording/ACTION_TESTER_RECORDING_END';
export const ACTION_TESTER_RECORDING_END_RECOVERY: string = 'testerRecording/ACTION_TESTER_RECORDING_END_RECOVERY';
