/* eslint-disable prettier/prettier */
// Getters
export const GETTER_TESTER_PAGE: string = 'testerPage/GETTER_TESTER_PAGE';
export const GETTER_TESTER_PAGE_IS_AUTH_ERROR: string = 'testerPage/GETTER_TESTER_PAGE_IS_AUTH_ERROR';
export const GETTER_TESTER_PAGE_GET_TESTER_STATE: string = 'testerPage/GETTER_TESTER_PAGE_GET_TESTER_STATE';
export const GETTER_TESTER_PAGE_IS_STARTED_MASTER: string = 'testerPage/GETTER_TESTER_PAGE_IS_STARTED_MASTER';
export const GETTER_TESTER_PAGE_IS_IDENTIFICATION_AUTHENTICATED: string =
  'testerPage/GETTER_TESTER_PAGE_IS_IDENTIFICATION_AUTHENTICATED';
export const GETTER_TESTER_PAGE_IS_IDENTIFICATION_ACCEPTED: string =
  'testerPage/GETTER_TESTER_PAGE_IS_IDENTIFICATION_ACCEPTED';
export const GETTER_TESTER_PAGE_IS_IDENTIFICATION_REJECTED: string =
  'testerPage/GETTER_TESTER_PAGE_IS_IDENTIFICATION_REJECTED';

// Mutations
// export const MUTATION_TESTER_PAGE: string = 'testerPage/MUTATION_TESTER_PAGE';
export const MUTATION_TESTER_PAGE_SET_AUTH_ERROR: string = 'testerPage/MUTATION_TESTER_PAGE_SET_AUTH_ERROR';
export const MUTATION_TESTER_PAGE_SET_TESTER: string = 'testerPage/MUTATION_TESTER_PAGE_SET_TESTER';
export const MUTATION_TESTER_PAGE_SET_TESTER_STATE: string = 'testerPage/MUTATION_TESTER_PAGE_SET_TESTER_STATE';
export const MUTATION_TESTER_PAGE_STEPBAR_STATE: string = 'testerPage/MUTATION_TESTER_PAGE_STEPBAR_STATE';
export const MUTATION_TESTER_PAGE_EXAM_TERM_AGREE: string = 'testerPage/MUTATION_TESTER_PAGE_EXAM_TERM_AGREE';
export const MUTATION_TESTER_PAGE_PROCTER_TERM_READ: string = 'testerPage/MUTATION_TESTER_PAGE_PROCTER_TERM_READ';
export const MUTATION_TESTER_PAGE_PROCTER_TERM_AGREE: string = 'testerPage/MUTATION_TESTER_PAGE_PROCTER_TERM_AGREE';
export const MUTATION_TESTER_PAGE_ENABLE_CAMERA: string = 'testerPage/MUTATION_TESTER_PAGE_ENABLE_CAMERA';
export const MUTATION_TESTER_PAGE_ENABLE_MICROPHONE: string = 'testerPage/MUTATION_TESTER_PAGE_ENABLE_MICROPHONE';
export const MUTATION_TESTER_PAGE_ENABLE_POPUP_BLOCK: string = 'testerPage/MUTATION_TESTER_PAGE_ENABLE_POPUP_BLOCK';
export const MUTATION_TESTER_PAGE_CHANGE_IDENTIFICATION_AUTHENTICATED: string =
  'testerPage/MUTATION_TESTER_PAGE_CHANGE_IDENTIFICATION_AUTHENTICATED';
export const MUTATION_TESTER_PAGE_CHANGE_IDENTIFICATION_ACCEPTED: string =
  'testerPage/MUTATION_TESTER_PAGE_CHANGE_IDENTIFICATION_ACCEPTED';
export const MUTATION_TESTER_PAGE_CHANGE_IDENTIFICATION_REJECTED: string =
  'testerPage/MUTATION_TESTER_PAGE_CHANGE_IDENTIFICATION_REJECTED';
export const MUTATION_TESTER_PAGE_SET_TEST_MARKINGS: string = 'testerPage/MUTATION_TESTER_PAGE_SET_TEST_MARKINGS';
export const MUTATION_TESTER_PAGE_SET_MATCHING: string = 'testerPage/MUTATION_TESTER_PAGE_SET_MATCHING';
export const MUTATION_TESTER_PAGE_SET_TESTER_STATUS: string = 'testerPage/MUTATION_TESTER_PAGE_SET_TESTER_STATUS';
export const MUTATION_TESTER_PAGE_SET_TESTER_RECORDING: string = 'testerPage/MUTATION_TESTER_PAGE_SET_TESTER_RECORDING';
export const MUTATION_TESTER_PAGE_CHANGE_EXAM_STATUS: string = 'testerPage/MUTATION_TESTER_PAGE_CHANGE_EXAM_STATUS';
export const MUTATION_TESTER_PAGE_CHANGE_TEST_STATUS: string = 'testerPage/MUTATION_TESTER_PAGE_CHANGE_TEST_STATUS';
export const MUTATION_TESTER_PAGE_SET_TESTER_RECORDING_START: string =
  'testerPage/MUTATION_TESTER_PAGE_SET_TESTER_RECORDING_START';
export const MUTATION_TESTER_PAGE_SET_TESTER_RECORDING_END: string =
  'testerPage/MUTATION_TESTER_PAGE_SET_TESTER_RECORDING_END';
export const MUTATION_TESTER_MEDIASTREAM: string = 'testerPage/MUTATION_TESTER_MEDIASTREAM';
export const MUTATION_TESTER_PAGE_TESTER_RECORDING: string = 'testerPage/MUTATION_TESTER_PAGE_TESTER_RECORDING';
export const MUTATION_TESTER_PAGE_TESTER_RECORDING_CLEAR: string =
  'testerPage/MUTATION_TESTER_PAGE_TESTER_RECORDING_CLEAR';
export const MUTATION_TESTER_PAGE_SET_GET_MC_TOKEN: string = 'testerPage/MUTATION_TESTER_PAGE_SET_GET_MC_TOKEN';
export const MUTATION_TESTER_PAGE_SET_INTERVAL_MATCHING: string =
  'testerPage/MUTATION_TESTER_PAGE_SET_INTERVAL_MATCHING';
export const MUTATION_TESTER_PAGE_SET_KVS_MASTER: string = 'testerPage/MUTATION_TESTER_PAGE_SET_KVS_MASTER';
export const MUTATION_TESTER_PAGE_SET_DISCONNECT: string = 'testerPage/MUTATION_TESTER_PAGE_SET_DISCONNECT';
export const MUTATION_TESTER_PAGE_IDENTITYIMAGE: string = 'testerPage/MUTATION_TESTER_PAGE_IDENTITYIMAGE';
export const MUTATION_TESTER_PAGE_ID_IMAGE: string = 'testerPage/MUTATION_TESTER_PAGE_ID_IMAGE';
export const MUTATION_TESTER_PAGE_FIXED_STR_AI_DISPLAY: string = 'testerPage/MUTATION_TESTER_PAGE_FIXED_STR_AI_DISPLAY';
export const MUTATION_TESTER_PAGE_NOTICE_AI_DISPLAY: string = 'testerPage/MUTATION_TESTER_PAGE_NOTICE_AI_DISPLAY';
export const MUTATION_TESTER_PAGE_COUNT_RETRY_NUM: string = 'testerPage/MUTATION_TESTER_PAGE_COUNT_RETRY_NUM';

// Actions
export const ACTION_TESTER_PAGE: string = 'testerPage/ACTION_TESTER_PAGE';
export const ACTION_TESTER_PAGE_GET_TESTER: string = 'testerPage/ACTION_TESTER_PAGE_GET_TESTER';
export const ACTION_TESTER_PAGE_SET_TESTER_STATE: string = 'testerPage/ACTION_TESTER_PAGE_SET_TESTER_STATE';
export const ACTION_TESTER_PAGE_TESTER_AUTHENTICATED: string = 'testerPage/ACTION_TESTER_PAGE_TESTER_AUTHENTICA';
export const ACTION_TESTER_PAGE_STEPBAR_STATE: string = 'testerPage/ACTION_TESTER_PAGE_STEPBAR_STATE';
export const ACTION_TESTER_PAGE_EXAM_TERM_AGREE: string = 'testerPage/ACTION_TESTER_PAGE_EXAM_TERM_AGREE';
export const ACTION_TESTER_PAGE_PROCTER_TERM_READ: string = 'testerPage/ACTION_TESTER_PAGE_PROCTER_TERM_READ';
export const ACTION_TESTER_PAGE_PROCTER_TERM_AGREE: string = 'testerPage/ACTION_TESTER_PAGE_PROCTER_TERM_AGREE';
export const ACTION_TESTER_PAGE_ENABLE_CAMERA: string = 'testerPage/ACTION_TESTER_PAGE_ENABLE_CAMERA';
export const ACTION_TESTER_PAGE_ENABLE_MICROPHONE: string = 'testerPage/ACTION_TESTER_PAGE_ENABLE_MICROPHONE';
export const ACTION_TESTER_PAGE_ENABLE_POPUP_BLOCK: string = 'testerPage/ACTION_TESTER_PAGE_ENABLE_POPUP_BLOCK';
export const ACTION_TESTER_PAGE_CHANGE_IDENTIFICATION_AUTHENTICATED: string =
  'testerPage/ACTION_TESTER_PAGE_CHANGE_IDENTIFICATION_AUTHENTICATED';
export const ACTION_TESTER_PAGE_CHANGE_IDENTIFICATION_ACCEPTED: string =
  'testerPage/ACTION_TESTER_PAGE_CHANGE_IDENTIFICATION_ACCEPTED';
export const ACTION_TESTER_PAGE_CHANGE_IDENTIFICATION_REJECTED: string =
  'testerPage/ACTION_TESTER_PAGE_CHANGE_IDENTIFICATION_REJECTED';
export const ACTION_TESTER_PAGE_GET_TEST_MARKINGS: string = 'testerPage/ACTION_TESTER_PAGE_GET_TEST_MARKINGS';
export const ACTION_TESTER_PAGE_GET_TEST_MARKINGS_LATEST: string =
  'testerPage/ACTION_TESTER_PAGE_GET_TEST_MARKINGS_LATEST';
export const ACTION_TESTER_PAGE_START_MASTER: string = 'testerPage/ACTION_TESTER_PAGE_START_MASTER';
export const ACTION_TESTER_PAGE_STOP_MASTER: string = 'testerPage/ACTION_TESTER_PAGE_STOP_MASTER';
export const ACTION_TESTER_PAGE_SEND_MASTER_MESSAGE: string = 'testerPage/ACTION_TESTER_PAGE_SEND_MASTER_MESSAGE';
export const ACTION_TESTER_PAGE_UPDATE_TESTER_STATUS: string = 'testerPage/ACTION_TESTER_PAGE_GET_TESTER_STATUS';
export const ACTION_TESTER_PAGE_CHANGE_EXAM_STATUS: string = 'testerPage/ACTION_TESTER_PAGE_CHANGE_EXAM_STATUS';
export const ACTION_TESTER_PAGE_CHANGE_TEST_STATUS: string = 'testerPage/ACTION_TESTER_PAGE_CHANGE_TEST_STATUS';
export const ACTION_TESTER_PAGE_START_TESTER_RECORDING: string = 'testerPage/ACTION_TESTER_PAGE_START_TESTER_RECORDING';
export const ACTION_TESTER_PAGE_END_TESTER_RECORDING: string = 'testerPage/ACTION_TESTER_PAGE_END_TESTER_RECORDING';
export const ACTION_TESTER_PAGE_END_TESTER_RECORDING_RECOVERY: string =
  'testerPage/ACTION_TESTER_PAGE_END_TESTER_RECORDING_RECOVERY';
export const ACTION_TESTER_PAGE_GET_TESTER_RECORDING: string = 'testerPage/ACTION_TESTER_PAGE_GET_TESTER_RECORDING';
export const ACTION_TESTER_PAGE_KVS_CONNECTION: string = 'testerPage/ACTION_TESTER_PAGE_KVS_CONNECTION';
export const ACTION_TESTER_PAGE_MEDIASTREAM: string = 'testerPage/ACTION_TESTER_PAGE_MEDIASTREAM';
export const ACTION_TESTER_PAGE_GET_MC_TOKEN: string = 'testerPage/ACTION_TESTER_PAGE_GET_MC_TOKEN';
export const ACTION_TESTER_PAGE_LOGOUT: string = 'testerPage/ACTION_TESTER_PAGE_LOGOUT';
export const ACTION_TESTER_PAGE_POST_WEBRTC_MESSAGE: string = 'testerPage/ACTION_TESTER_PAGE_POST_WEBRTC_MESSAGE';
export const ACTION_TESTER_PAGE_CREATE_VIDEO_ELEMENT: string = 'testerPage/ACTION_TESTER_PAGE_CREATE_VIDEO_ELEMENT';
export const ACTION_TESTER_PAGE_IDENTITY_AI_INIT: string = 'testerPage/ACTION_TESTER_PAGE_IDENTITY_AI_INIT';
export const ACTION_TESTER_PAGE_IDENTITYIMAGE: string = 'testerPage/ACTION_TESTER_PAGE_IDENTITYIMAGE';
export const ACTION_TESTER_PAGE_ID_IMAGE: string = 'testerPage/ACTION_TESTER_PAGE_ID_IMAGE';
export const ACTION_TESTER_PAGE_FIXED_STR_AI_DISPLAY: string = 'testerPage/ACTION_TESTER_PAGE_INIT_FIXED_AI_DISPLAY';
export const ACTION_TESTER_PAGE_FIXED_STR_AI_DISPLAY_CHECK: string =
  'testerPage/ACTION_TESTER_PAGE_FIXED_STR_AI_DISPLAY_CHECK';
export const ACTION_TESTER_PAGE_NOTICE_AI_DISPLAY: string = 'testerPage/ACTION_TESTER_PAGE_NOTICE_AI_DISPLAY';
export const ACTION_TESTER_PAGE_COUNT_RETRY_NUM: string = 'testerPage/ACTION_TESTER_PAGE_COUNT_RETRY_NUM';
export const ACTION_TESTER_PAGE_INIT_AI_IDENTIFY: string = 'testerPage/ACTION_TESTER_PAGE_INIT_AI_IDENTIFY';
