export default class StartupAdapter {
  // eslint-disable-next-line max-lines-per-function
  constructor(
    public rawStartupUrl: string = '',

    public inServer: string = '',
    public target: string = '',
    public actor: number = 0,
    public lang: string = '',
    public isMobile: number = 0,
    public isMcStartup: number = 0,
    public isProctor: number = 0,
    public isAuth: number = 0,
    public examUrl: string = '',
    public region: string = '',
    public examName: string = '',
    public examDatetime: Date | any = null,
    public isVoiceRecording: number = 0,
    public isRecord: number = 0,
    public isSummary: number = 0,
    public maxRectime: number = 0,
    public matchingTimeout: number = 0,
    public testerRetry: number = 0,
    public checkerRetry: number = 0,
    public webrtcTimeout: number = 0,
    public intervaltime: number = 0,
    public webrtcMaxTime: number = 0,
    public isDebug: IsDebug = 0, // 1-3:する 0:しない
    public isConv: number = 0,
    public videoRecordingPreference: number = 0,
    public voiceQualityPreference: number = 0,
    public useWebrtc: number = 1, // 録画時に WebRTC を経由するか. 1:する 0:しない ... WebRTC が使えない団体の場合は 0 にする. その場合、機器のストリームをそのまま録画する.
    public passwordUrl: string = '',
    public memo: string = '',
    public isAiAuth: number = 1, // 1：監視者 2:AI
    public isAiFaildManual: number = 1, // 1: 強制ログアウト 2:監視者 3:続行
    public isAiIdcardRequest: number = 0, // 1:する 0:しない
    public aiFaceRetry: number = 0,
    public aiIdcardRetry: number = 0,
    public aiAllRetry: number = 0,
    public aiIdcardType: number = 0, // 1：運転免許証、２：パスポート、３：マイナンバーカード
    public aiNameMatch: number = 0,

    public loginId: string = '',
    public password: string = '',
    public accessToken: string = '',
    public examUserName: string = '',
    public isForce: number = 0,
    public group: string = '',
    public groupId: string = '',
    public eai: string = '',
    public termSetCode: string = ''
  ) {}

  setStartupValue(obj: any) {
    if (obj) {
      for (const key of Object.keys(this)) {
        if (obj[key] !== undefined) {
          (this as any)[key] = obj[key];
        }
      }
    }

    return this;
  }

  /**
   * J-Testingで起動したか調べます
   *
   * @param {StartupAdapter} startup
   * @return {boolean}
   */
  public static isJtStartUp(startup: StartupAdapter): boolean {
    return startup.loginId !== null && startup.loginId !== '';
  }
}

export type IsDebug = null | number; // 1-3:する 0:しない
