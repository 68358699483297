/* eslint-disable camelcase */
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { MatchingAdapter, Matching, DeleteMatchingRequestType } from '../types/adapters/matchingAdapter';
import { TesterMarkingsRequestType, TesterMarkingsAdapter } from '../types/adapters/testerMarkingsAdapter';
import { TesterHeadShotRequestType } from '../types/adapters/testerHeadShotAdapter';
import { TesterKickOutRequestType } from '../types/adapters/testerKickOutAdapter';
import { MonitorPageAdapter } from '@/store/types/adapters/monitorPageAdapter';
import * as rootTypes from '@/store/types/rootType';
import * as types from '@/store/types/monitorPageType';
import * as matchingTypes from '@/store/types/matchingType';
import * as testerMarkingTypes from '@/store/types/testerMarkingsType';
import * as testerHeadShotTypes from '@/store/types/testerHeadShotType';
import * as testerKickOutTypes from '@/store/types/testerKickOutType';
import * as testerRejectedTypes from '@/store/types/testerRejectedType';
import * as marksTypes from '@/store/types/marksType';
import * as notifyListType from '@/store/types/fixedNotificationsType';
import { mergeDeep } from '~/utils/mergeDeep';
import { ViewerKVS } from '~/plugins/kvs/viewer';
import * as testerTypes from '@/store/types/testerType';
import { TesterAdapter, TesterStatusRequestType } from '~/store/types/adapters/testerAdapter';
import { KvsDataType, KvsCommand, CommandObject, MessageObject } from '~/plugins/kvs/type/sendMessageType';
import { debounce } from 'lodash';

const state = () => {
  return new MonitorPageAdapter();
};

type monitorPage = ReturnType<typeof state>;

const getters: GetterTree<monitorPage, monitorPage> = {
  [types.GETTER_MONITOR_PAGE](state: MonitorPageAdapter) {
    return { ...state };
  },
  [types.GETTER_MATCHINGS](state: MonitorPageAdapter) {
    return state.matchings;
  },
};

const mutations: MutationTree<monitorPage> = {
  // マッチング情報設定
  [types.MUTATION_MONITOR_PAGE_SET_MATCHING](state: monitorPage, payload: MatchingAdapter) {
    // mergeDeep(state.matchings, payload.matchings);
    state.matchings = payload.matchings;
  },
  [types.MUTATION_MONITOR_PAGE_SET_TESTER_UNIQUE_KEY](state: monitorPage, payload: string) {
    state.testerUniqueKey = payload;
  },
  setMainMediaStream(state: monitorPage, payload: MediaStream) {
    state.mainMediaStream = payload;
  },
  stackTesterChat(
    state: monitorPage,
    payload: { testerUniqueKey: string; kvsViewer: ViewerKVS; tester: TesterAdapter }
  ) {
    state.testerChats[payload.testerUniqueKey] = {
      subChat: [],
      kvsViewer: payload.kvsViewer,
      tester: payload.tester,
      rejected: 0,
      elapsedTime: '',
      intervalTimeout: null,
    };
  },
  [types.MUTATION_MONITOR_PAGE_SET_IN_AUTH](state: monitorPage, payload: boolean) {
    state.inAuth = payload;
  },
};

const actions: ActionTree<monitorPage, monitorPage> = {
  // マーク取得
  [types.ACTION_MONITOR_PAGE_MARKS](context) {
    return context.dispatch(marksTypes.ACTION_MARKS_GET_REQEUST);
  },
  [types.ACTION_MONITOR_PAGE_NOTIFY_LIST](context) {
    return context.dispatch(notifyListType.ACTION_FIXEDNOTIFICATIONS_GET_REQEUST);
  },
  // マッチング処理取得
  [types.ACTION_MONITOR_PAGE_GET_MATCHING](context) {
    return new Promise((resolve) => {
      context.dispatch(matchingTypes.ACTION_MATCHING).then((matchingAdapter: MatchingAdapter) => {
        context.commit(types.MUTATION_MONITOR_PAGE_SET_MATCHING, matchingAdapter);
        resolve();
      });
    });
  },
  // 受験者情報取得処理
  [types.ACTION_MONITOR_PAGE_GET_TESTER](context, testerId: number) {
    return new Promise((resolve) => {
      context.dispatch(testerTypes.ACTION_TESTER, testerId).then((testerAdapter: TesterAdapter) => {
        let quoteRemovedMemo = testerAdapter.startupParameters.memo
          .replace(/"$/, '')
          .replace(/^"/, '')
          .replace(/'$/, '')
          .replace(/^'/, '');
        testerAdapter.startupParameters.memo = quoteRemovedMemo;
        resolve(testerAdapter);
      });
    });
  },
  /**
   * マッチング開始処理(KVS接続,matching.phpポーリング開始)
   *
   * @param {Context} context
   * @param {{ testerUniqueKey: string; matching: Matching; tester: TesterAdapter }} payload
   */
  [types.ACTION_MONITOR_PAGE_START_VIEWR_KVS](
    context,
    payload: { testerUniqueKey: string; matching: Matching; tester: TesterAdapter }
  ) {
    return new Promise((resolve) => {
      const videoTag = document.createElement('video');
      videoTag.autoplay = true;
      (videoTag as any).playsinline = true;
      videoTag.muted = true;
      videoTag.style.width = '100%';
      const { accessKeyId, channelName, region, secretAccessKey, sessionToken } = payload.matching.signalingChannel;

      // KVSの切断処理 正常系の場合に事前通知を取得したかのフラグ
      let beforeCloseCommandReceived: boolean = false;

      const kvsViewer = this.$kvsViewer(
        videoTag,
        // コネクションステータスの監視
        // @see https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/getStats
        (data: RTCStatsReport | DOMError) => {
          if (!(data instanceof DOMError)) {
            // 成功
            if (data) {
              data.forEach((stateObj: any) => {
                if (stateObj.type === 'transport') {
                  console.log(stateObj);
                  if (stateObj.dtlsState === 'closed') {
                    if (beforeCloseCommandReceived) {
                      // KVSの切断処理の正常系 通知が来ているので、強制ログアウトしない
                      // ただし、一定期間マッチングしている場合は、やっぱり不具合の可能性があるので閉じる
                      window.setTimeout(() => {
                        const matchings = context.getters[types.GETTER_MATCHINGS] as Matching[];
                        if (
                          matchings.find(
                            (v) =>
                              v.testerId == payload.matching.testerId &&
                              v.matchingConnectedTime == payload.matching.matchingConnectedTime
                          )
                        ) {
                          this.dispatch(testerKickOutTypes.ACTION_TESTER_KICK_OUT, {
                            tester_id: payload.tester.testerId,
                            method: 'DISCONNECT',
                          });
                        }
                      }, context.getters[rootTypes.GETTER_STARTUP].matchingTimeout * 1000 * 4);
                    } else {
                      // KVSの切断処理の以上系 通知が来ていないので、ブラウザ「x」閉じと判断し強制ログアウトする
                      this.dispatch(testerKickOutTypes.ACTION_TESTER_KICK_OUT, {
                        tester_id: payload.tester.testerId,
                        method: 'DISCONNECT',
                      });
                    }
                  }
                  if (stateObj.bytesReceived === 0) {
                    console.log('[LOG INFO] : TSL bytesReceived', stateObj);
                    // context.state.testerChats[payload.testerUniqueKey].kvsViewer.stopViewer();
                    // clearTimeout(context.state.testerChats[payload.testerUniqueKey].intervalTimeout as any);
                    // delete context.state.testerChats[payload.testerUniqueKey];
                  }
                }
              });
            }
          } else {
            // 失敗
            const error = data as DOMError;
          }
        },
        // メッセージの送受信
        // @see https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/datachannel_event
        (event: any) => {
          console.log('[LOG INFO]: receive dataChannel message', event);

          const parsedData = JSON.parse(event.data);
          switch (parsedData.dataType) {
            case KvsDataType.COMMAND:
              const commandObj = parsedData as CommandObject;
              switch (commandObj.command) {
                case KvsCommand.BEFORE_CLOSE:
                  beforeCloseCommandReceived = true;
                  kvsViewer.sendBeforeCloseOk();
                  break;

                default:
                  console.error('[KVS ERROR] unknown command error!!', event);
              }
              break;

            case KvsDataType.MESSAGE:
              // TODO : 監視者・受験者の「LoginID」と「ドメイン名」が渡されることで何かの処理が今後入る
              const messageObject = parsedData as MessageObject;
              const date = new Date();
              const format = (num: number) => ('0' + num).slice(-2);
              const messageObj = {
                sender: 'examinee',
                message: `${format(date.getHours())}:${format(date.getMinutes())}:${format(date.getSeconds())}(${
                  context.state.testerChats[payload.testerUniqueKey].elapsedTime
                }) ${messageObject.alert}`,
              };
              context.state.testerChats[payload.testerUniqueKey].subChat.push(messageObj);
              break;

            default:
              console.error('[KVS ERROR] unknown dataType error!!', event);
          }
        }
      );
      kvsViewer.setKVSConfig({
        region,
        channelName,
        clientId: channelName,
        accessKeyId,
        secretAccessKey,
        sessionToken,
      });
      kvsViewer.startViewer().then(() => {
        resolve(true);
      });
      this.$kvsEventBus.$once(`${channelName}-viewer-open`, (value: string) => {
        console.log('[LOG INFO] viewer KVS open', value);
        context.dispatch(matchingTypes.ACTION_MATCHING_POST, {
          testerId: payload.matching.testerId,
          channelStatus: value,
        });
        context.state.testerChats[payload.testerUniqueKey].intervalTimeout = setInterval(() => {
          // TODO: このコードはおかしいけど、保留　よくわからない
          kvsViewer.startViewer();
        }, context.getters[rootTypes.GETTER_STARTUP].matchingTimeout * 1500);
      });
      this.$kvsEventBus.$once(`${channelName}-viewer-close`, (value: string) => {
        console.log('[LOG INFO] viewer KVS close', value);
        context.dispatch(matchingTypes.ACTION_MATCHING_POST, {
          testerId: payload.matching.testerId,
          channelStatus: value,
        });
      });
      this.$kvsEventBus.$on(
        `${channelName}-viewer-track`,
        debounce((track: RTCTrackEvent) => {
          console.log('[LOG INFO] viewer KVS tack', track, ' : -- channelName -- : ', channelName);
          this.$kvsEventBus.$emit(payload.testerUniqueKey, track);
        }, 500)
      );
      this.$kvsEventBus.$on(`${channelName}-viewer-audioprocess`, (process: number) => {
        this.$kvsEventBus.$emit(`${payload.testerUniqueKey}-audioprocess`, process);
      });

      this.$kvsEventBus.$once(`${channelName}-viewer-sdpAnswer`, (value: string) => {
        console.log('[LOG INFO] viewer sdpAnswer', value);
        clearInterval(context.state.testerChats[payload.testerUniqueKey].intervalTimeout as any);
        context.state.testerChats[payload.testerUniqueKey].intervalTimeout = null;
      });

      context.commit('stackTesterChat', {
        testerUniqueKey: payload.testerUniqueKey,
        kvsViewer,
        tester: payload.tester,
      });
    });
  },
  /**
   * マッチング停止処理(KVS接続,matching.phpポーリング停止)
   *
   * @param {Context} context
   * @param {{ testerUniqueKey: string; matching: Matching; tester: TesterAdapter }} payload
   */
  [types.ACTION_MONITOR_PAGE_STOP_VIEWR_KVS](
    context,
    payload: { testerUniqueKey: string; matching: Matching; tester: TesterAdapter }
  ) {
    // TODO: いずれ必要になったら書く
  },
  // マーキング処理
  [types.ACTION_MONITOR_PAGE_RECORD_MARKING](context, request: TesterMarkingsRequestType) {
    return new Promise((resolve, reject) => {
      context
        .dispatch(testerMarkingTypes.ACTION_TESTER_MARKINGS_RECORD_MARKING, request)
        .then((resData: TesterMarkingsAdapter) => {
          resolve(resData);
        })
        .catch((e) => {
          console.error(e);
        });
    });
  },
  // 顔認証画像登録処理
  [types.ACTION_MONITOR_PAGE_HEAD_SHOT](context, request: TesterHeadShotRequestType) {
    return context.dispatch(testerHeadShotTypes.ACTION_TESTER_HEAD_SHOT, request);
  },
  // 受験者チャット配列生成生成
  actionCreateTesterChat(context, payload: { testerUniqueKey: string; kvsViewer: ViewerKVS }) {
    context.commit('stackTesterChat', payload);
  },
  actionMainView(context, payload) {
    context.commit('setMainMediaStream', payload);
  },
  [types.ACTION_MONITOR_PAGE_TESTER_KICK_OUT](context, testerId: number) {
    context.dispatch(testerKickOutTypes.ACTION_TESTER_KICK_OUT, {
      tester_id: testerId,
      method: 'PUT',
    });
  },
  [types.ACTION_MONITOR_PAGE_ACCEPT](context, request: TesterStatusRequestType) {
    context.dispatch(testerTypes.ACTION_TESTER_STATUS, request);
  },
  [types.ACTION_MONITOR_PAGE_SET_TESTER_UNIQUE_KEY](context, testerUniqueKey: string) {
    context.commit(types.MUTATION_MONITOR_PAGE_SET_TESTER_UNIQUE_KEY, testerUniqueKey);
  },
  [types.ACTION_MONITOR_PAGE_SET_HEAD_SHOT_URL](context, payload: { testerId: number; headShotUrl: string }) {
    context.dispatch(matchingTypes.ACTION_SET_HEAD_SHOT_URL, payload);
  },
  [types.ACTION_MONITOR_PAGE_SET_ID_CARD_URL](context, payload: { testerId: number; idCardUrl: string }) {
    context.dispatch(matchingTypes.ACTION_SET_ID_CARD_URL, payload);
  },
  [types.ACTION_MONITOR_PAGE_DELETE_MATCHING](context, payload: DeleteMatchingRequestType) {
    return context.dispatch(matchingTypes.ACTION_DELETE_MATCHING, payload);
  },
  [types.ACTION_MONITOR_PAGE_SET_IN_AUTH](context, payload: boolean) {
    context.commit(types.MUTATION_MONITOR_PAGE_SET_IN_AUTH, payload);
  },
  [types.ACTION_MONITOR_PAGE_REJECT](context, payload: { testerId: number; method: string }) {
    return context.dispatch(testerRejectedTypes.ACTION_TESTER_REJECTED, payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
