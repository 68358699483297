import { GetterTree, ActionTree, MutationTree } from 'vuex';
import VueRouter from 'vue-router';
import { TestMarkingsAdapter } from '../types/adapters/testMarkingsAdapter';
import { MatchingAdapter, DeleteMatchingRequestType } from '../types/adapters/matchingAdapter';
import { TesterPageAdapter } from '~/store/types/adapters/testerPageAdapter';
import { TesterAdapter } from '~/store/types/adapters/testerAdapter';
import {
  TesterRecordingAdapter,
  TesterRecordingRecoveryAdapter,
  TesterRecordingRecoveryRequestType,
} from '~/store/types/adapters/testerRecordingAdapter';
import { GetMcTokenAdapter, GetMcTokenRequestType } from '~/store/types/adapters/getMcTokenAdapter';
import { TesterState } from '~/store/enum/TesterState';
import { StepbarState } from '~/store/enum/StepbarState';
import {
  ExamStatusAdapter,
  ExamStatusRequestType,
  ExamStatusResponseType,
} from '@/store/types/adapters/examStatusAdapter';
import {
  TestStatusAdapter,
  TestStatusRequestType,
  TestStatusResponseType,
} from '@/store/types/adapters/testStatusAdapter';
import * as testerPageTypes from '@/store/types/testerPageType';
import * as types from '@/store/types/testerPageType';
import * as loginTypes from '@/store/types/loginType';
import * as commonTypes from '@/store/types/commonType';
import * as testerTypes from '@/store/types/testerType';
import * as testMarkingsTypes from '@/store/types/testMarkingsType';
import * as testerRecordingTypes from '@/store/types/testerRecordingType';
import * as examStatusTypes from '@/store/types/examStatusType';
import * as testStatusTypes from '@/store/types/testStatusType';
import * as matchingTypes from '@/store/types/matchingType';
import * as getMcTokenTypes from '@/store/types/getMcTokenType';
import * as rootTypes from '@/store/types/rootType';
import * as webrtcMessageTypes from '@/store/types/webrtcMessageType';
import * as aiAuthStatusType from '@/store/types/aiAuthStatusType';
import * as faceType from '@/store/types/faceType';
import * as idCardType from '@/store/types/idCardType';

import { DeviceState } from '~/store/enum/deviceState';
import StartupAdapter from '~/store/types/adapters/startupAdapter';
import { MasterKVS } from '~/plugins/kvs/master';
import { Endpoint } from '~/store/const/endpoint';
import { ErrorAdapter, ErrorStatus } from '@/store/types/adapters/errorAdapter';
import { pushMessage } from '~/static/push';
import { WebRTCMessageRequestType } from '~/store/types/adapters/webrtcMessageAdapter';
import { LanguageEnum } from '../enum/language';
import { AiAuthStatusAdapter } from '../types/adapters/aiAuthStatusAdapter';
import { FaceAdapter } from '../types/adapters/faceAdapter';
import { IdCardAdapter } from '../types/adapters/idCardAdapter';
import { KvsDataType, KvsCommand, CommandObject, MessageObject } from '@/plugins/kvs/type/sendMessageType';

const state = () => {
  return new TesterPageAdapter();
};

type testerPage = ReturnType<typeof state>;

const getters: GetterTree<testerPage, testerPage> = {
  [testerPageTypes.GETTER_TESTER_PAGE](state: testerPage) {
    return { ...state };
  },
  /**
   * 認証エラーか調べます
   *
   * @param {testerPage} state
   * @return {boolean}
   */
  [testerPageTypes.GETTER_TESTER_PAGE_IS_AUTH_ERROR](state: testerPage): boolean {
    return state.isAuthError;
  },
  /**
   * 受験者ステータスを取得します
   *
   * @param {testerPage} state
   * @return {TesterState}
   */
  [testerPageTypes.GETTER_TESTER_PAGE_GET_TESTER_STATE](state: testerPage): TesterState {
    return state.testerState;
  },
  /**
   * マッチング処理が開始されているか調べます
   *
   * @param {Context} context
   * @see front/components/Mixins/TesterMatchingPollingMixin.ts startMatching()メソッド
   */
  [testerPageTypes.GETTER_TESTER_PAGE_IS_STARTED_MASTER](state: testerPage): boolean {
    return !!state.kvsMaster;
  },
  /**
   * 認証中か調べます
   *
   * @return {boolean}
   */
  [testerPageTypes.GETTER_TESTER_PAGE_IS_IDENTIFICATION_AUTHENTICATED](state: testerPage): boolean {
    return state.isIdentifyCheckPending;
  },
  /**
   * 認証が承認されたか調べます
   *
   * @return {boolean}
   */
  [testerPageTypes.GETTER_TESTER_PAGE_IS_IDENTIFICATION_ACCEPTED](state: testerPage): boolean {
    return state.isIdentifyCheckPending && state.isIdentityCheckFinished && !state.isIdentifyCheckNG;
  },
  /**
   * 認証が拒否されたか調べます
   *
   * @return {boolean}
   */
  [testerPageTypes.GETTER_TESTER_PAGE_IS_IDENTIFICATION_REJECTED](state: testerPage): boolean {
    return state.isIdentifyCheckPending && state.isIdentityCheckFinished && state.isIdentifyCheckNG;
  },
};

const mutations: MutationTree<testerPage> = {
  // [testerPageTypes.MUTATION_TESTER_PAGE](state: testerPage, payload: TesterPageAdapter) {
  //   state.testerState = payload.testerState;
  //   state.stepbarState = payload.stepbarState;
  // },
  /**
   * 認証エラーを設定します
   *
   * @param {testerPage} state
   * @param {boolean} isAuthError
   */
  [testerPageTypes.MUTATION_TESTER_PAGE_SET_AUTH_ERROR](state: testerPage, isAuthError: boolean) {
    state.isAuthError = isAuthError;
  },
  // 受験者情報取得情報の更新
  [testerPageTypes.MUTATION_TESTER_PAGE_SET_TESTER](state: testerPage, payload: TesterAdapter) {
    state.testerId = payload.testerId;
    state.examName = payload.examName;
    state.testerState = payload.status;
    state.isProctor = payload.startupParameters.isProctor;
    state.rejected = payload.rejected;
  },
  // 受験者ステータスの更新処理
  [testerPageTypes.MUTATION_TESTER_PAGE_SET_TESTER_STATE](state: testerPage, payload: TesterState) {
    state.testerState = payload;
  },
  // ステップバー状態の更新
  [testerPageTypes.MUTATION_TESTER_PAGE_STEPBAR_STATE](state: testerPage, payload: StepbarState) {
    state.stepbarState = payload;
  },
  // 試験利用規約同意状態の更新
  [testerPageTypes.MUTATION_TESTER_PAGE_EXAM_TERM_AGREE](state: testerPage, payload: boolean) {
    state.isExamTermAgree = payload;
  },
  // プロクター利用規約既読状態の更新
  [testerPageTypes.MUTATION_TESTER_PAGE_PROCTER_TERM_READ](state: testerPage, payload: boolean) {
    state.isProcterTermRead = payload;
  },
  // プロクター利用規約同意状態の更新
  [testerPageTypes.MUTATION_TESTER_PAGE_PROCTER_TERM_AGREE](state: testerPage, payload: boolean) {
    state.isProcterTermAgree = payload;
  },
  // カメラ状態の更新
  [testerPageTypes.MUTATION_TESTER_PAGE_ENABLE_CAMERA](state: testerPage, payload: DeviceState) {
    state.enableCamera = payload;
  },
  // マイク状態の更新
  [testerPageTypes.MUTATION_TESTER_PAGE_ENABLE_MICROPHONE](state: testerPage, payload: DeviceState) {
    state.enableMicrophone = payload;
  },
  /**
   * 本人認証状態の確認中更新
   */
  [testerPageTypes.MUTATION_TESTER_PAGE_CHANGE_IDENTIFICATION_AUTHENTICATED](state: testerPage) {
    state.isIdentifyCheckPending = true;
    state.isIdentityCheckFinished = false;
    state.isIdentifyCheckNG = false;
  },
  /**
   * 本人認証状態の作業完了更新
   */
  [testerPageTypes.MUTATION_TESTER_PAGE_CHANGE_IDENTIFICATION_ACCEPTED](state: testerPage) {
    state.isIdentifyCheckPending = true;
    state.isIdentityCheckFinished = true;
    state.isIdentifyCheckNG = false;
  },
  /**
   * 本人認証状態の失敗時更新
   */
  [testerPageTypes.MUTATION_TESTER_PAGE_CHANGE_IDENTIFICATION_REJECTED](state: testerPage) {
    state.isIdentifyCheckPending = true;
    state.isIdentityCheckFinished = true;
    state.isIdentifyCheckNG = true;
  },
  // マッチング情報の更新
  [testerPageTypes.MUTATION_TESTER_PAGE_SET_MATCHING](state: testerPage, payload: MatchingAdapter) {
    state.matchings = payload.matchings;
  },
  // 不正疑惑行為サマリ情報の更新
  [testerPageTypes.MUTATION_TESTER_PAGE_SET_TEST_MARKINGS](state: testerPage, payload: TestMarkingsAdapter) {
    state.markings = payload.markings;
    state.markingTotalScore = payload.markingTotalScore;
    state.records = payload.records;
  },
  // 試験開始・終了処理（MC+の試験）
  [testerPageTypes.MUTATION_TESTER_PAGE_CHANGE_EXAM_STATUS](state: testerPage, payload: ExamStatusResponseType) {
    state.status = payload.status;
    state.message = payload.message;
    state.result = payload.result;
  },
  // 試験開始・終了処理（MC+以外の試験）
  [testerPageTypes.MUTATION_TESTER_PAGE_CHANGE_TEST_STATUS](state: testerPage, payload: TestStatusResponseType) {
    state.status = payload.status;
    state.message = payload.message;
    state.result = payload.result;
  },
  // 録画開始処理
  [testerPageTypes.MUTATION_TESTER_PAGE_SET_TESTER_RECORDING_START](
    state: testerPage,
    payload: TesterRecordingAdapter
  ) {
    state.accessKeyId = payload.accessKeyId;
    state.secretAccessKey = payload.secretAccessKey;
    state.sessionToken = payload.sessionToken;
    state.bucket = payload.bucket;
    state.key = payload.key;
    state.uploadId = payload.uploadId;
    state.interval = payload.interval;
  },
  // 録画終了処理
  [testerPageTypes.MUTATION_TESTER_PAGE_SET_TESTER_RECORDING_END](
    state: testerPage,
    payload: TesterRecordingAdapter
  ) {},
  // メディアストリーム格納
  [testerPageTypes.MUTATION_TESTER_MEDIASTREAM](state: testerPage, payload: MediaStream) {
    if (state.master.localStream) state.master.localStream = null;
    state.master.localStream = payload;
  },
  // 録画ステータス取得
  [testerPageTypes.MUTATION_TESTER_PAGE_TESTER_RECORDING](state: testerPage, payload: TesterRecordingAdapter) {
    state.accessKeyId = payload.accessKeyId;
    state.secretAccessKey = payload.secretAccessKey;
    state.sessionToken = payload.sessionToken;
    state.bucket = payload.bucket;
    state.key = payload.key;
    state.uploadId = payload.uploadId;
    state.interval = payload.interval;
  },
  // 録画ステータスクリア
  [testerPageTypes.MUTATION_TESTER_PAGE_TESTER_RECORDING_CLEAR](state: testerPage) {
    state.accessKeyId = '';
    state.secretAccessKey = '';
    state.sessionToken = '';
    state.bucket = '';
    state.key = '';
    state.uploadId = '';
  },
  // MC+起動処理
  [testerPageTypes.MUTATION_TESTER_PAGE_SET_GET_MC_TOKEN](state: testerPage, payload: GetMcTokenAdapter) {
    state.mode = payload.mode;
    state.accessToken = payload.accessToken;
  },
  [testerPageTypes.MUTATION_TESTER_PAGE_SET_INTERVAL_MATCHING](state: testerPage, payload: NodeJS.Timeout | null) {
    state.intervalMatching = payload;
  },
  [testerPageTypes.MUTATION_TESTER_PAGE_SET_KVS_MASTER](state: testerPage, payload: MasterKVS) {
    state.kvsMaster = payload;
  },
  [testerPageTypes.MUTATION_TESTER_PAGE_SET_DISCONNECT](state: testerPage, payload: boolean) {
    state.disconnect = payload;
  },
  [testerPageTypes.MUTATION_TESTER_PAGE_IDENTITYIMAGE](state: testerPage, payload: string) {
    state.identityImage = payload;
  },
  [testerPageTypes.MUTATION_TESTER_PAGE_ID_IMAGE](state: testerPage, payload: string) {
    state.idImage = payload;
  },
  [testerPageTypes.MUTATION_TESTER_PAGE_FIXED_STR_AI_DISPLAY](
    state: testerPage,
    payload: { str: string; check: boolean }[]
  ) {
    state.fixedStrAiIdentifyList = payload;
  },
  [testerPageTypes.MUTATION_TESTER_PAGE_NOTICE_AI_DISPLAY](
    state: testerPage,
    payload: { selectedStr: string; noticeType: number; retry?: number; limite?: number; failStr?: string }
  ) {
    state.noticeAiDisplay.selectedStr = payload.selectedStr;
    state.noticeAiDisplay.limite = payload.limite;
    state.noticeAiDisplay.noticeType = payload.noticeType;
    state.noticeAiDisplay.retry = payload.retry;
    state.noticeAiDisplay.failStr = payload.failStr;
  },
  [testerPageTypes.MUTATION_TESTER_PAGE_COUNT_RETRY_NUM](state: testerPage, payload: number) {
    // state.countRetryNum = state.countRetryNum + payload;
    state.countRetryNum = payload;
  },
};

const actions: ActionTree<testerPage, testerPage> = {
  [testerPageTypes.ACTION_TESTER_PAGE_IDENTITYIMAGE](context, payload: string) {
    context.commit(types.MUTATION_TESTER_PAGE_IDENTITYIMAGE, payload);
  },
  [testerPageTypes.ACTION_TESTER_PAGE_ID_IMAGE](context, payload: string) {
    context.commit(types.MUTATION_TESTER_PAGE_ID_IMAGE, payload);
  },
  // 受験者情報取得処理
  [testerPageTypes.ACTION_TESTER_PAGE_GET_TESTER](context): Promise<TesterAdapter> {
    return new Promise((resolve, reject) => {
      context
        .dispatch(testerTypes.ACTION_TESTER, null)
        .then((testerAdapter: TesterAdapter) => {
          context.commit(testerPageTypes.MUTATION_TESTER_PAGE_SET_TESTER, testerAdapter);
          resolve(testerAdapter);
        })
        .catch((e: any) => {
          if (ErrorAdapter.isErrorState(e) && e.status == 401) {
            // 認証エラー
            context.commit(testerPageTypes.MUTATION_TESTER_PAGE_SET_AUTH_ERROR, true);
          }
          reject(e);
        });
    });
  },
  // 受験者ステータス 更新処理
  [testerPageTypes.ACTION_TESTER_PAGE_SET_TESTER_STATE](context, payload: TesterState): Promise<boolean> {
    return new Promise((resolve, reject) => {
      context
        .dispatch(testerTypes.ACTION_TESTER_STATUS, { status: payload })
        .then(() => {
          context.commit(testerPageTypes.MUTATION_TESTER_PAGE_SET_TESTER_STATE, payload);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [testerPageTypes.ACTION_TESTER_PAGE_TESTER_AUTHENTICATED](context): Promise<boolean> {
    return new Promise((resolve, reject) => {
      context
        .dispatch(testerPageTypes.ACTION_TESTER_PAGE_GET_TESTER)
        .then((testerAdapter: TesterAdapter) => {
          resolve(testerAdapter.authenticatedAt ? true : false);
        })
        .catch(reject);
    });
  },
  // Stepbar 更新処理
  [testerPageTypes.ACTION_TESTER_PAGE_STEPBAR_STATE](context, payload: StepbarState) {
    context.commit(types.MUTATION_TESTER_PAGE_STEPBAR_STATE, payload);
  },
  // 受験利用規約同意 更新処理
  [testerPageTypes.ACTION_TESTER_PAGE_EXAM_TERM_AGREE](context, payload: boolean) {
    context.commit(types.MUTATION_TESTER_PAGE_EXAM_TERM_AGREE, payload);
  },
  // プロクター利用規約 既読処理
  [testerPageTypes.ACTION_TESTER_PAGE_PROCTER_TERM_READ](context, payload: boolean) {
    context.commit(types.MUTATION_TESTER_PAGE_PROCTER_TERM_READ, payload);
  },
  // プロクター利用規約同意 更新処理
  [testerPageTypes.ACTION_TESTER_PAGE_PROCTER_TERM_AGREE](context, payload: boolean) {
    context.commit(types.MUTATION_TESTER_PAGE_PROCTER_TERM_AGREE, payload);
  },
  // カメラ状態 更新処理
  [testerPageTypes.ACTION_TESTER_PAGE_ENABLE_CAMERA](context, payload: DeviceState) {
    context.commit(types.MUTATION_TESTER_PAGE_ENABLE_CAMERA, payload);
  },
  // マイク状態 更新処理
  [testerPageTypes.ACTION_TESTER_PAGE_ENABLE_MICROPHONE](context, payload: DeviceState) {
    context.commit(types.MUTATION_TESTER_PAGE_ENABLE_MICROPHONE, payload);
  },
  // 本人認証状態の確認中 更新処理
  [testerPageTypes.ACTION_TESTER_PAGE_CHANGE_IDENTIFICATION_AUTHENTICATED](context) {
    context.commit(types.MUTATION_TESTER_PAGE_CHANGE_IDENTIFICATION_AUTHENTICATED);
  },
  // 本人認証状態の作業完了 更新処理
  [testerPageTypes.ACTION_TESTER_PAGE_CHANGE_IDENTIFICATION_ACCEPTED](context) {
    context.commit(types.MUTATION_TESTER_PAGE_CHANGE_IDENTIFICATION_ACCEPTED);
  },
  // 本人認証状態の失敗時 更新処理
  [testerPageTypes.ACTION_TESTER_PAGE_CHANGE_IDENTIFICATION_REJECTED](context) {
    context.commit(types.MUTATION_TESTER_PAGE_CHANGE_IDENTIFICATION_REJECTED);
  },
  // メディアストリーム格納
  [testerPageTypes.ACTION_TESTER_PAGE_MEDIASTREAM](context, payload: MediaStream) {
    context.commit(testerPageTypes.MUTATION_TESTER_MEDIASTREAM, payload);
  },
  // AI 本人認証の初期化
  [testerPageTypes.ACTION_TESTER_PAGE_IDENTITY_AI_INIT](context) {
    if (!context.state.master.localView) {
      context.dispatch(testerPageTypes.ACTION_TESTER_PAGE_CREATE_VIDEO_ELEMENT);
    }
    (context.state.master.localView as HTMLVideoElement).srcObject = context.state.master.localStream as MediaStream;
  },
  // video tag 生成 生成
  [testerPageTypes.ACTION_TESTER_PAGE_CREATE_VIDEO_ELEMENT](context) {
    const videoTag = document.createElement('video');
    videoTag.autoplay = true;
    (videoTag as any).playsinline = true;
    videoTag.muted = true;
    videoTag.style.width = '100%';
    videoTag.style.height = '100%';
    context.state.master.localView = videoTag;
  },
  /**
   * マッチング開始処理(KVS接続,matching.phpポーリング開始)
   *
   * 注意:この処理はこのモジュールの外からは直接使わないでください。TesterMatchingPollingMixin::startMatching()メソッドを使うようにしてください。
   *
   * @param {Context} context
   * @see front/components/Mixins/TesterMatchingPollingMixin.ts startMatching()メソッド
   */
  [testerPageTypes.ACTION_TESTER_PAGE_START_MASTER](context) {
    return new Promise((resolve, reject) => {
      context
        .dispatch(matchingTypes.ACTION_MATCHING)
        .then((matchingAdapter: MatchingAdapter) => {
          context.commit(testerPageTypes.MUTATION_TESTER_PAGE_SET_MATCHING, matchingAdapter);
          context.commit(testerPageTypes.MUTATION_TESTER_PAGE_SET_DISCONNECT, false);
          if (
            context.getters[rootTypes.GETTER_STARTUP].isAuth === 1 ||
            context.getters[rootTypes.GETTER_STARTUP].isProctor === 1
          ) {
            const matchingValue = context.state.matchings[0];
            const { accessKeyId, region, channelName, secretAccessKey, sessionToken } = matchingValue.signalingChannel;
            context.dispatch(testerPageTypes.ACTION_TESTER_PAGE_CREATE_VIDEO_ELEMENT);
            if (!context.state.master.localStream) {
              console.error(
                '[LOG INFO] : there is no medaiStream at testerPageStroe.',
                context.state.master.localStream
              );
              return;
            }
            if (!context.state.master.localView) {
              console.error('[LOG INFO] : there is no localView at testerPageStroe.', context.state.master.localView);
              return;
            }
            const kvsMaster = this.app.$kvsMaster(
              context.state.master.localStream,
              context.state.master.localView,
              // コネクションステータスの監視
              // @see https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/getStats
              (data: RTCStatsReport | DOMError) => {
                if (!(data instanceof DOMError)) {
                  // 成功
                } else {
                  // 失敗
                  const error = data as DOMError;
                }
              },
              // メッセージの送受信
              // @see https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/datachannel_event
              (event: any) => {
                const parsedData = JSON.parse(event.data);

                switch (parsedData.dataType) {
                  case KvsDataType.COMMAND:
                    const commandObj = parsedData as CommandObject;
                    switch (commandObj.command) {
                      // 強制ログアウト (kickout)
                      case KvsCommand.KICKOUT:
                        // TODO: KVSによる通信と「監視者」側からの強制ログアウトでDBへの書き込みのどちらが先に行われるか保証がない これは厳密には正しくない
                        // 暫定処理?　強制ログアウトを食らったので、速やかにmatching.phpのポーリング処理を停止する
                        if (context.state.intervalMatching) {
                          clearInterval(context.state.intervalMatching);
                          context.commit(testerPageTypes.MUTATION_TESTER_PAGE_SET_INTERVAL_MATCHING, null);
                        }

                        const displayLang = context.getters[rootTypes.GETTER_DISPLAY_LANG];
                        const loginData = context.getters[loginTypes.GETTER_LOGIN];
                        const inParams = context.getters[rootTypes.GETTER_STARTUP];
                        if (
                          context.getters[rootTypes.GETTER_STARTUP].loginId === undefined ||
                          context.getters[rootTypes.GETTER_STARTUP].loginId === ''
                        ) {
                          pushMessage(
                            {
                              alert: (displayLang as any).FORCE_LOGOUT,
                              loginId: loginData.loginId as string,
                              domainName: inParams.target,
                            },
                            // @ts-ignore
                            this.app.$ua.browser() === 'Safari'
                          );

                          context.dispatch(testerPageTypes.ACTION_TESTER_PAGE_LOGOUT);
                        } else {
                          pushMessage(
                            {
                              alert: (displayLang as any).FORCE_CLOSE_PAGE,
                              loginId: loginData.loginId as string,
                              domainName: inParams.target,
                            },
                            // @ts-ignore
                            this.app.$ua.browser() === 'Safari'
                          );

                          context
                            .dispatch(commonTypes.ACTION_COMMON_SET_LOADING, true)
                            .then(
                              () =>
                                new Promise((resolve, reject) => {
                                  context.dispatch(loginTypes.ACTION_LOGOUT, true).then(() => {
                                    this.$router.replace('/close').then(() => resolve(true));
                                  });
                                })
                            )
                            .finally(() => context.dispatch(commonTypes.ACTION_COMMON_SET_LOADING, false));
                        }
                        break;

                      case KvsCommand.BEFORE_CLOSE_OK:
                        kvsMaster.stopMaster((result: boolean) => {});
                        break;

                      case KvsCommand.IDENTIFICATION_AUTHENTICATED:
                        context.commit(testerPageTypes.MUTATION_TESTER_PAGE_CHANGE_IDENTIFICATION_AUTHENTICATED);
                        break;

                      case KvsCommand.IDENTIFICATION_ACCEPTED:
                        // サーバ側のテスターステータスの変更が完了するまで待つ
                        const func = () => {
                          const matchings = context.getters[matchingTypes.GETTER_MATCHING].matchings || [];
                          const matching = matchings[0];
                          if (matching.status === TesterState.IDENTIFICATED) {
                            // テスターステータスの変更が完了していれば終わり

                            context.commit(testerPageTypes.MUTATION_TESTER_PAGE_CHANGE_IDENTIFICATION_ACCEPTED);
                          } else {
                            // 変更されるまで監視を続ける

                            setTimeout(func, 1000);
                          }
                        };

                        func();
                        break;

                      case KvsCommand.IDENTIFICATION_REJECTED:
                        context.commit(testerPageTypes.MUTATION_TESTER_PAGE_CHANGE_IDENTIFICATION_REJECTED);
                        break;

                      default:
                        console.error('[KVS ERROR] unknown command error!!', event);
                    }
                    break;

                  case KvsDataType.MESSAGE:
                    this.app.$kvsEventBus.$emit('master-dataMessage', { data: parsedData });
                    break;

                  default:
                    console.error('[KVS ERROR] unknown dataType error!!', event);
                }
              }
            );
            kvsMaster.setKVSConfig({
              accessKeyId,
              region,
              channelName,
              secretAccessKey,
              sessionToken,
            });
            context.commit(testerPageTypes.MUTATION_TESTER_PAGE_SET_KVS_MASTER, kvsMaster);
            kvsMaster
              .startMaster()
              .then(() => {
                resolve(true);
              })
              .catch((error) => {
                console.log(error);
                resolve(false);
              });
            // kvsEventBus
            this.app.$kvsEventBus.$on('master-open', (value: string) => {
              console.log('[LOG INFO] master KVS open', value);
              context
                .dispatch(matchingTypes.ACTION_MATCHING_POST, {
                  testerId: context.state.matchings[0].testerId,
                  channelStatus: value,
                })
                .then((data: boolean) => {
                  context.commit(types.MUTATION_TESTER_PAGE_SET_DISCONNECT, false);
                  if (data) {
                    console.log('[LOG INFO] master matching post request success! :', data, value);
                    const interval = setInterval(() => {
                      context
                        .dispatch(matchingTypes.ACTION_MATCHING)
                        .then(() => {
                          context.commit(testerPageTypes.MUTATION_TESTER_PAGE_SET_DISCONNECT, false);
                        })
                        .catch((reason) => {
                          if (reason.message === 'Network Error') {
                            context.commit(testerPageTypes.MUTATION_TESTER_PAGE_SET_DISCONNECT, true);
                          }
                          console.log(reason);
                          reject(reason);
                        });
                    }, context.getters[rootTypes.GETTER_STARTUP].matchingTimeout * 1000);

                    context.commit(testerPageTypes.MUTATION_TESTER_PAGE_SET_INTERVAL_MATCHING, interval);
                  }
                })
                .catch((reason) => {
                  if (reason.message === 'Network Error') {
                    context.commit(testerPageTypes.MUTATION_TESTER_PAGE_SET_DISCONNECT, true);
                  }
                  console.log(reason);
                  reject(reason);
                });
            });
            this.app.$kvsEventBus.$on('master-close', (callback: (result: boolean) => void) => {
              // TODO: ここは削除していいはずだが、わからないので保留
              if (kvsMaster && kvsMaster.master && kvsMaster.master.signalingClient) {
                kvsMaster.master.signalingClient.open();
              }

              const testerId = context.state.matchings[0].testerId;

              console.log('[LOG INFO] master KVS close');
              if (context.state.intervalMatching) {
                clearInterval(context.state.intervalMatching);
                context.commit(testerPageTypes.MUTATION_TESTER_PAGE_SET_INTERVAL_MATCHING, null);
              }
              context
                .dispatch(matchingTypes.ACTION_DELETE_MATCHING, {
                  tester_id: testerId,
                } as DeleteMatchingRequestType)
                .then((data: boolean) => {
                  if (data) {
                    console.log('[LOG INFO] master matching post request success! :', data);
                  }
                  callback(true);
                })
                .catch((err) => {
                  console.log('<<<<>>>>' + err);
                  console.log(err);
                  // eslint-disable-next-line standard/no-callback-literal
                  callback(false);
                });
            });
          } else {
            resolve(true);
          }
        })
        .catch((reason) => {
          if (reason.message === 'Network Error') {
            context.commit(testerPageTypes.MUTATION_TESTER_PAGE_SET_DISCONNECT, true);
          }
          console.log(reason);
          reject(reason);
        });
    });
  },
  /**
   * マッチング停止処理(KVS接続,matching.phpポーリング停止)
   *
   * 注意:この処理はこのモジュールの外からは直接使わないでください。TesterMatchingPollingMixin::stopMatchingIfNeeded()メソッドを使うようにしてください。
   *
   * @param {Context} context
   * @param {(result: boolean) => void} callback
   * @see front/components/Mixins/TesterMatchingPollingMixin.ts stopMatchingIfNeeded()メソッド
   */
  [testerPageTypes.ACTION_TESTER_PAGE_STOP_MASTER](context) {
    return new Promise((resolve, reject) => {
      const kvsMaster = context.getters[testerPageTypes.GETTER_TESTER_PAGE].kvsMaster;
      if (kvsMaster) {
        this.app.$kvsEventBus.$on('master-close-finish', (result: boolean) => {
          this.app.$kvsEventBus.$off('master-open');
          this.app.$kvsEventBus.$off('master-close');
          this.app.$kvsEventBus.$off('master-close-finish');

          const isProctor = context.getters[rootTypes.GETTER_STARTUP].isProctor;
          const isAuth = context.getters[rootTypes.GETTER_STARTUP].isAuth;
          if (result) {
            console.log(`[LOG] KVSの切断に成功しました。(isProctor=${isProctor},isAuth=${isAuth})`);
          } else {
            console.error(`[LOG] KVSの切断に失敗しました。(isProctor=${isProctor},isAuth=${isAuth})`);
          }

          if (result) {
            context.commit(testerPageTypes.MUTATION_TESTER_PAGE_SET_KVS_MASTER, null);
            resolve(result);
          } else {
            reject(new Error());
          }
        });

        // 「監視者」側に正常に閉じるための通知を行う
        // 正常に閉じる処理は以下の流れになる
        // 受験者                                監視者
        //    |                                    |
        //    |  KvsCommand.BEFORE_CLOSEを送信     |
        //    |----------------------------------->|
        //    |                                    |
        //    |  KvsCommand.BEFORE_CLOSE_OKを送信  |
        //    |<-----------------------------------|
        //    |                                    |
        //    |  kvsMaster.stopMaster()を呼び出し  |
        //    |  plugins/kvs/master.tsの           |
        //    |  'master-close'イベントをemitする  |
        //    |-----------------                   |
        //    |                |                   |
        //    |<----------------                   |
        //    |                                    |
        //    |  store/modules/testerPageStore.tsの|
        //    |  'master-close'イベントをonした後  |
        //    |  callbackでplugins/kvs/master.ts　 |
        //    |  に制御を返す                      |
        //    |-----------------                   |
        //    |                |                   |
        //    |<----------------                   |
        //    |                                    |
        //    |  plugins/kvs/master.tsの           |
        //    |  'master-close-finish'イベントを   |
        //    |  emitする                          |
        //    |-----------------                   |
        //    |                |                   |
        //    |<----------------                   |
        //    |                                    |
        //    |  plugins/kvs/master.tsの           |
        //    |  'master-close-finish'イベントを   |
        //    |  onして、                          |
        //    |  KVSの切断とmatching.phpポーリング |
        //    |  の停止完了                        |
        //    |-----------------                   |
        //    |                |                   |
        //    |<----------------                   |
        //    |                                    |
        kvsMaster.sendMasterBeforeClose();
      } else {
        console.error('[ERROR] kvs is not started!!');
        resolve(true);
      }
    });
  },
  /**
   * メッセージを送信します
   *
   * 注意:この処理はこのモジュールの外からは直接使わないでください。TesterMatchingPollingMixin::sendMasterMessage()メソッドを使うようにしてください。
   *
   * @param {Context} context
   * @param {MessageObject} message
   * @see front/components/Mixins/TesterMatchingPollingMixin.ts sendMasterMessage()メソッド
   */
  [testerPageTypes.ACTION_TESTER_PAGE_SEND_MASTER_MESSAGE](context, data: MessageObject) {
    const kvsMaster = context.getters[testerPageTypes.GETTER_TESTER_PAGE].kvsMaster;
    if (kvsMaster) {
      kvsMaster.sendMasterMessage(data);
    } else {
      console.error('[ERROR] kvs is not started!!');
    }
  },
  // 不正疑惑行為サマリ取得処理
  [testerPageTypes.ACTION_TESTER_PAGE_GET_TEST_MARKINGS](context, testId: number): Promise<TestMarkingsAdapter> {
    return new Promise((resolve, reject) => {
      context
        .dispatch(testMarkingsTypes.ACTION_TEST_MARKINGS, testId)
        .then((testMarkingsAdapter: TestMarkingsAdapter) => {
          context.commit(testerPageTypes.MUTATION_TESTER_PAGE_SET_TEST_MARKINGS, testMarkingsAdapter);
          resolve(testMarkingsAdapter);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  },
  // 最新の不正疑惑行為サマリ取得処理
  [testerPageTypes.ACTION_TESTER_PAGE_GET_TEST_MARKINGS_LATEST](context): Promise<TestMarkingsAdapter> {
    return new Promise((resolve, reject) => {
      context
        .dispatch(testMarkingsTypes.ACTION_TEST_MARKINGS_LATEST)
        .then((testMarkingsAdapter: TestMarkingsAdapter) => {
          context.commit(testerPageTypes.MUTATION_TESTER_PAGE_SET_TEST_MARKINGS, testMarkingsAdapter);
          resolve(testMarkingsAdapter);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  },
  // 試験開始・終了処理（MC+）
  [testerPageTypes.ACTION_TESTER_PAGE_CHANGE_EXAM_STATUS](context, payload: ExamStatusRequestType) {
    return new Promise((resolve, reject) => {
      context
        .dispatch(examStatusTypes.ACTION_CHANGE_EXAM_STATUS, payload)
        .then((result: ExamStatusAdapter) => {
          context.commit(testerPageTypes.MUTATION_TESTER_PAGE_CHANGE_EXAM_STATUS, result);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 試験開始・終了処理（MC+以外の試験）
  [testerPageTypes.ACTION_TESTER_PAGE_CHANGE_TEST_STATUS](context, payload: TestStatusRequestType) {
    return new Promise((resolve, reject) => {
      context
        .dispatch(testStatusTypes.ACTION_CHANGE_TEST_STATUS, payload)
        .then((result: TestStatusAdapter) => {
          context.commit(testerPageTypes.MUTATION_TESTER_PAGE_CHANGE_TEST_STATUS, result);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 録画開始処理
  [testerPageTypes.ACTION_TESTER_PAGE_START_TESTER_RECORDING](context): Promise<TesterRecordingAdapter> {
    return new Promise((resolve, reject) => {
      context
        .dispatch(testerRecordingTypes.ACTION_TESTER_RECORDING_START)
        .then((testerRecordingAdapter: TesterRecordingAdapter) => {
          context.commit(testerPageTypes.MUTATION_TESTER_PAGE_SET_TESTER_RECORDING_START, testerRecordingAdapter);
          resolve(testerRecordingAdapter);
        })
        .catch(reject);
    });
  },
  // 録画終了処理
  [testerPageTypes.ACTION_TESTER_PAGE_END_TESTER_RECORDING](context): Promise<TesterRecordingAdapter> {
    return new Promise((resolve, reject) => {
      context
        .dispatch(testerRecordingTypes.ACTION_TESTER_RECORDING_END)
        .then((testerRecordingAdapter: TesterRecordingAdapter) => {
          resolve(testerRecordingAdapter);
        })
        .catch(reject);
    });
  },
  // 録画終了処理(リカバリー)
  [testerPageTypes.ACTION_TESTER_PAGE_END_TESTER_RECORDING_RECOVERY](
    context,
    request: TesterRecordingRecoveryRequestType
  ): Promise<TesterRecordingRecoveryAdapter> {
    return new Promise((resolve, reject) => {
      context
        .dispatch(testerRecordingTypes.ACTION_TESTER_RECORDING_END_RECOVERY, request)
        .then((adapter: TesterRecordingRecoveryAdapter) => {
          resolve(adapter);
        })
        .catch(reject);
    });
  },
  // 録画ステータス取得処理
  [testerPageTypes.ACTION_TESTER_PAGE_GET_TESTER_RECORDING](context) {
    return new Promise((resolve, reject) => {
      context
        .dispatch(testerRecordingTypes.ACTION_GET_TESTER_RECORDING)
        .then((testerRecordingAdapter: TesterRecordingAdapter) => {
          context.commit(testerPageTypes.MUTATION_TESTER_PAGE_TESTER_RECORDING, testerRecordingAdapter);
          resolve(testerRecordingAdapter);
        })
        .catch((err) => reject(err));
    });
  },
  // MC+起動処理
  [testerPageTypes.ACTION_TESTER_PAGE_GET_MC_TOKEN](context, mode: string) {
    // console.log(payload)
    context.dispatch(getMcTokenTypes.ACTION_GET_MC_TOKEN, mode).then((getMcTokenAdapter: GetMcTokenAdapter) => {
      context.commit(testerPageTypes.MUTATION_TESTER_PAGE_SET_GET_MC_TOKEN, getMcTokenAdapter);
    });
  },
  /**
   * ログアウトして、ログイン画面に遷移します startupURLにリダイレクトするため、ストア内のデータをすべて初期化します
   *
   * このストアは「受験者」用のストアのため、「監視者」「管理者」のページからこのメソッドを呼び出すことはしないほうがいいです
   *
   * @param {Context} context
   * @return {Promise<any>}
   * @see  front/store/rootStore.ts front/store/types/rootType.ts::ACTION_LOGOUT_AND_REDIRECT_LOGIN_PAGE
   */
  [testerPageTypes.ACTION_TESTER_PAGE_LOGOUT](context) {
    return context.dispatch(rootTypes.ACTION_LOGOUT_AND_REDIRECT_LOGIN_PAGE);
  },
  [testerPageTypes.ACTION_TESTER_PAGE_POST_WEBRTC_MESSAGE](context, payload: WebRTCMessageRequestType) {
    context.dispatch(webrtcMessageTypes.ACTION_WEBRTC_MESSAGE_POST, payload);
  },
  /**
   * payload: number
   * - 1: AI本人認証O
   * - 2: 本人認証✕監視✕録画◯ ・身分証の認証あり
   * - 3: 本人認証✕監視✕録画◯ ・身分証の認証なし
   */
  [testerPageTypes.ACTION_TESTER_PAGE_FIXED_STR_AI_DISPLAY](context, payload: number) {
    const displayLang: LanguageEnum = context.getters[rootTypes.GETTER_DISPLAY_LANG];
    const fixedStrAiIdentifyList = [
      { str: displayLang.AI_IDENTIFICATION_FIXED_STR_1, check: true },
      { str: displayLang.AI_IDENTIFICATION_FIXED_STR_2, check: false },
      { str: displayLang.AI_IDENTIFICATION_FIXED_STR_3, check: false },
      { str: displayLang.AI_IDENTIFICATION_FIXED_STR_4, check: false },
      { str: displayLang.AI_IDENTIFICATION_FIXED_STR_5, check: false },
      { str: displayLang.AI_IDENTIFICATION_FIXED_STR_6, check: false },
      { str: displayLang.AI_IDENTIFICATION_FIXED_STR_7, check: false },
      { str: displayLang.AI_IDENTIFICATION_FIXED_STR_8, check: false },
      { str: displayLang.AI_IDENTIFICATION_FIXED_STR_9, check: false },
    ];
    if (payload === 1) {
      context.commit(types.MUTATION_TESTER_PAGE_FIXED_STR_AI_DISPLAY, fixedStrAiIdentifyList);
    } else if (payload === 2) {
      context.commit(types.MUTATION_TESTER_PAGE_FIXED_STR_AI_DISPLAY, fixedStrAiIdentifyList.slice(0, 8));
    } else {
      context.commit(types.MUTATION_TESTER_PAGE_FIXED_STR_AI_DISPLAY, fixedStrAiIdentifyList.slice(0, 4));
    }
  },
  [testerPageTypes.ACTION_TESTER_PAGE_FIXED_STR_AI_DISPLAY_CHECK](context, payload: { index: number; bool: boolean }) {
    context.state.fixedStrAiIdentifyList[payload.index].check = payload.bool;
    context.commit(types.MUTATION_TESTER_PAGE_FIXED_STR_AI_DISPLAY, context.state.fixedStrAiIdentifyList);
  },
  /**
   *  payload
   * - selectedStr : 注意書きorエラー文字列
   * - noticeType : 1 -> 注意書き, 2 -> エラー
   * - retry? : リトライ回数
   * */
  [testerPageTypes.ACTION_TESTER_PAGE_NOTICE_AI_DISPLAY](
    context,
    payload: { selectedStr: string; noticeType: number; retry?: number; limite?: number; failStr?: string }
  ) {
    if (payload.limite || payload.retry || payload.failStr) {
      const limite = payload.limite || 0;
      const retry = payload.retry || 0;
      const failStr = payload.failStr || '';
      payload.selectedStr = payload.selectedStr.replace(/n/g, String(limite - retry));
      payload.selectedStr = payload.selectedStr.replace(/N/g, String(limite - 1));
      payload.selectedStr = payload.selectedStr.replace(/C/g, String(limite));
      payload.selectedStr = payload.selectedStr.replace(/S/g, failStr);
    }
    context.commit(types.MUTATION_TESTER_PAGE_NOTICE_AI_DISPLAY, payload);
  },
  [testerPageTypes.ACTION_TESTER_PAGE_COUNT_RETRY_NUM](context, payload: number) {
    /*
    const startup = context.getters[rootTypes.GETTER_STARTUP] as StartupAdapter;
    let count: number = 0;
    if (payload === 0) {
      return;
    }

    if (startup.aiAllRetry === 1 && payload === 3) {
      count = 1;
    } else {
      if (payload % startup.aiAllRetry === 0) {
        count = 1;
      }
    }
    context.commit(types.MUTATION_TESTER_PAGE_COUNT_RETRY_NUM, count);
    */
    context.commit(types.MUTATION_TESTER_PAGE_COUNT_RETRY_NUM, payload);
  },
  [testerPageTypes.ACTION_TESTER_PAGE_INIT_AI_IDENTIFY](context, testId?: number): Promise<{ status: number }> {
    return new Promise((resolve, reject) => {
      // 初期ステータス取得処理後、各々連携作業
      context
        .dispatch(aiAuthStatusType.ACTION_REQUEST_GET_AI_AUTH_STATUS, testId)
        .then((data: AiAuthStatusAdapter) => {
          context.state.identityImage = data.faceUrl;
          context.state.idImage = data.idCardUrl;

          if (data.faceRetry || data.idCardRetry || data.aiAllRetry) {
            context.commit(faceType.MUTATION_FACE, data as FaceAdapter);
            context.commit(idCardType.MUTATION_ID_CARD, data as IdCardAdapter);
            context.dispatch(types.ACTION_TESTER_PAGE_COUNT_RETRY_NUM, data.aiAllRetry);

            const obj = {
              status: data.status,
              faceUrl: data.faceUrl,
              idCardUrl: data.idCardUrl,
            };
            resolve(obj);
          } else {
            const obj = {
              status: 0,
            };
            resolve(obj);
          }
        })
        .catch((err) => {
          // TODO : エラーハンドラー処理。
          console.error(err);
          reject(err);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
