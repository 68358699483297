import { GetterTree, ActionTree, MutationTree } from 'vuex';
import {
  ExamStatusAdapter,
  ExamStatusRequestType,
  ExamStatusResponseType,
} from '@/store/types/adapters/examStatusAdapter';
import * as types from '@/store/types/examStatusType';
import { Endpoint } from '@/store/const/endpoint';
import * as errTypes from '@/store/types/errorType';
import { ErrorStatus } from '@/store/types/adapters/errorAdapter';

const state = () => {
  return new ExamStatusAdapter();
};

type StateType = ReturnType<typeof state>;

const getters: GetterTree<StateType, StateType> = {
  [types.GETTER_EXAM_STATUS](state: StateType) {
    return { ...state };
  },
};

const mutations: MutationTree<StateType> = {
  [types.MUTATION_CHANGE_EXAM_STATUS](state: StateType, payload: ExamStatusAdapter) {
    state.process = payload.process;
  },
};

const actions: ActionTree<StateType, StateType> = {
  [types.ACTION_CHANGE_EXAM_STATUS](context, payload: ExamStatusAdapter): Promise<ExamStatusAdapter> {
    console.log('[LOG INFO] get tester recording start');
    context.commit(types.MUTATION_CHANGE_EXAM_STATUS, new ExamStatusAdapter());
    return new Promise((resolve) => {
      this.$axios
        .$post(Endpoint.EXAM_STATUS.url, {
          login_id: payload.loginId,
          process: payload.process,
          eai: payload.eai,
        })
        .then((response: ExamStatusResponseType) => {
          if (response.status === 200) {
            context.commit(types.MUTATION_CHANGE_EXAM_STATUS, {
              status: response.status,
              message: response.message,
            });
            resolve(context.getters[types.GETTER_EXAM_STATUS]);
          } else {
            // TODO : 試験の開始、中断、終了、再開に応じて処理を分岐（必要なら）
            const err: ErrorStatus = {
              endpoint: Endpoint.EXAM_STATUS,
              status: response.status,
              message: response.message,
            };
            context.dispatch(errTypes.ACTION_SET_ERROR, err);
          }
        })
        .catch((e: any) => {
          console.error('[Tester Recording error start] : ', e);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
