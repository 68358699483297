/* eslint-disable camelcase */
import { TesterState } from '@/store/enum/TesterState';
import { MatchingStatus } from '@/store/enum/MatchingStatus';

export class MatchingAdapter {
  constructor(public matchings: Matching[] = []) {}
}

// マッチング情報配列構造体
export class Matching {
  public monitorId: number = 0;
  public testerId: number = 0;
  public fileName: string = '';
  public status: number = 0;
  public matchingStatus: MatchingStatus = MatchingStatus.NONE;
  public matchingTotalTime: string = '0:0:0';
  public matchingConnectedTime: string = '0:0:0';
  public signalingChannel: SignalingChannel | any;
  public testerStatus: TesterState = TesterState.NONE;
  public headShotUrl: string = '';
  public idCardUrl: string = '';
  public headShotUrlUpdatedAt: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() - 1
  );
  public idCardUrlUpdatedAt: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1);
  public auth: boolean = false;
}

// シグナリングチャネル情報配列構造体
export class SignalingChannel {
  public region: string = '';
  public accessKeyId: string = '';
  public secretAccessKey: string = '';
  public sessionToken?: string = undefined;
  public channelName: string = '';
}

// マッチング情報返り値 配列構造体
type MatchingResultResponseType = {
  monitor_id: number;
  tester_id: number;
  file_name: string;
  matching_status: MatchingStatus;
  status: number;
  matching_total_time: string; // 全体時間
  matching_connected_time: string; // 経過時間
  signaling_channel: {
    region: string;
    access_key_id: string;
    secret_access_key: string;
    session_token?: string;
    channel_name: string;
  };
};

type MatchingResult = {
  matchings: MatchingResultResponseType[];
};

// マッチング処理 リクエストパラメータ
export type MatchingRequestType = {
  tester_id: number;
  channel_status: string;
};

// マッチング処理 レスポンスパラメータ
export type MatchingResponseType = {
  status: number;
  message: string;
  result: MatchingResult;
};

export type DeleteMatchingRequestType = {
  tester_id: number;
};
export type DeleteMatchingResponseType = {
  status: number;
  message: string;
  result: null;
};
