/* eslint-disable prettier/prettier */
// Getters
export const GETTER_TESTER: string = 'tester/GETTER_TESTER';
export const GETTER_TESTER_STATUS: string = 'tester/GETTER_TESTER_STATUS';

// Mutations
export const MUTATION_TESTER: string = 'tester/MUTATION_TESTER';
export const MUTATION_TESTER_STATUS: string = 'tester/MUTATION_TESTER_STATUS';

// Actions
export const ACTION_TESTER: string = 'tester/ACTION_TESTER';
export const ACTION_TESTER_STATUS: string = 'tester/ACTION_TESTER_STATUS';
