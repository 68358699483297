const targetBrowsers: {
  os: string;
  osVersion: number;
  name: string;
  version: number;
  customMatch?: (self: any, browserInfo: any) => boolean;
}[] = [
  {
    os: 'Windows',
    osVersion: 10.0,
    name: 'Chrome',
    version: 80.0,
    customMatch: function (self, browserInfo) {
      return (
        browserInfo.os === self.os &&
        (browserInfo.osVersion || self.osVersion || browserInfo.osVersion == 8.1) &&
        browserInfo.name === self.name &&
        browserInfo.version >= self.version
      );
    },
  },
  {
    os: 'Windows',
    osVersion: 10.0,
    name: 'Edge',
    version: 80.0,
    customMatch: function (self, browserInfo) {
      return (
        browserInfo.os === self.os &&
        (browserInfo.osVersion || self.osVersion || browserInfo.osVersion == 8.1) &&
        browserInfo.name === self.name &&
        browserInfo.version >= self.version
      );
    },
  },
  //{ os: 'Mac',     osVersion: 10.157, name: 'Safari', version: 14.03,
  //  customMatch: (self: any, browserInfo: any): boolean => {
  //    // TODO: 暫定対応/社内MACでOSバージョンが正しく取れないためこのOSバージョンは通す
  //    if (
  //      browserInfo.os === self.os &&
  //      browserInfo.osVersion == 10.156 &&
  //      browserInfo.name === self.name &&
  //      browserInfo.version >= self.version
  //    ) {
  //      return true;
  //    }
  //
  //    return browserInfo.os === self.os &&
  //      (browserInfo.osVersion >= self.osVersion) && !(11.02 > browserInfo.osVersion && browserInfo.osVersion >= 11) &&
  //      browserInfo.name === self.name &&
  //      browserInfo.version >= self.version
  //  },
  //},
  { os: 'Mac', osVersion: 10.136, name: 'Chrome', version: 80.0 },
  //{ os: 'Android', osVersion:  8.0,   name: 'Chrome', version: 80.0 },
  //{ os: 'iOS',     osVersion: 14.3,   name: 'Safari', version: 11.0 }, // TODO: iOS/iPhoneの場合、OSバージョン=ブラウザバージョン?っぽいので厳密にはチェックしない.必要であればする
  //{ os: 'iPadOS',  osVersion: 14.3,   name: 'Safari', version: 11.0 }, // TODO: iOS/iPhoneの場合、OSバージョン=ブラウザバージョン?っぽいので厳密にはチェックしない.必要であればする
];

export { targetBrowsers };
