function checkServiceWorker() {
  return new Promise((resolve) => {
    navigator.serviceWorker
      .register('proctor-1.0.0-sw.js')
      .then(() => {
        console.log('register');
        return navigator.serviceWorker.ready;
      })
      .then((registration) => {
        console.log('ready');
        if (registration.active) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
  });
}

function clearServiceWorker() {
  return new Promise((resolve, reject) => {
    navigator.serviceWorker
      .getRegistrations()
      .then(function (registrations) {
        for (const registration of registrations) {
          registration.unregister();
        }
      })
      .then(() => {
        caches
          .keys()
          .then(function (keys) {
            const promises = [];
            // キャッシュストレージを全て削除する
            keys.forEach(function (cacheName) {
              if (cacheName) {
                console.log('[LOG INFO] cache key deleted! : ', cacheName, caches);
                promises.push(caches.delete(cacheName));
              }
            });
          })
          .finally(() => {
            resolve(true);
          });
      })
      .catch((e) => {
        console.error(e);
        reject(new Error(e));
      });
  });
}

function notificationRequestPermission() {
  return new Promise(Notification.requestPermission);
}

function askForNPerm() {
  return new Promise((resolve) => {
    notificationRequestPermission().then(function (permission) {
      console.log(permission);
      if (permission === 'denied') {
        resolve(false);
        console.log('You Have Denied Notification!');
        alert('通知がブロックされています。');
      } else if (permission === 'granted') {
        resolve(true);
        console.log('You Have Granted notification.');
      }
    });
  });
}

function iosNotification(data) {
  if (Object.hasOwnProperty.call(data, 'alert')) {
    var n = new Notification('', {
      body: data.alert,
    });
  } else {
    var n = new Notification('', {
      body: data,
    });
  }
}

function _pushMessage(data) {
  if (!navigator.serviceWorker.controller) {
    console.error('[LOG INFO] serviceWorker controller is not exist! :', navigator.serviceWorker);
    return;
  }
  navigator.serviceWorker.controller.postMessage(data);
}

function pushMessage(data, isSafari) {
  if (isSafari) {
    return iosNotification(data);
  } else {
    return _pushMessage(data);
  }
}

export { checkServiceWorker, pushMessage, clearServiceWorker, askForNPerm };
