/* eslint-disable prettier/prettier */
// Getter
export const GETTER_TEST_PASS = 'testPass/GETTER_TEST_PASS';

// Mutations
export const MUTATION_TEST_PASS = 'testPass/MUTATION_TEST_PASS';

// Actions
export const ACTION_GET_TEST_PASS: string = 'testPass/ACTION_GET_TEST_PASS';
export const ACTION_SWITCH_TEST_PASS: string = 'testPass/ACTION_SWITCH_TEST_PASS';
