import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { LogAdapter, LogRequestType, LogResponseType } from '@/store/types/adapters/logAdapter';
import * as types from '@/store/types/logType';
import { Endpoint } from '@/store/const/endpoint';

const state = () => {
  return new LogAdapter();
};

type LogState = ReturnType<typeof state>;

const getters: GetterTree<LogState, LogState> = {
  [types.GETTER_LOG](state: LogState) {
    return { ...state };
  },
};

const mutations: MutationTree<LogState> = {
  // TODO レスポンスに応じて書き換えるstateがあれば処理を追加すること
};

const actions: ActionTree<LogState, LogState> = {
  [types.ACTION_OUTPUT_LOG](context, { type, log }: LogRequestType) {
    this.$axios
      .$post(Endpoint.LOG.url, {
        type,
        log,
      })
      .then(({ status, message }: LogResponseType) => {
        context.commit(types.MUTATION_LOG);
      })
      .catch((e: any) => {
        // エラー時の処理
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
