/* eslint-disable camelcase */
export class WebRTCMessageAdapter {
  constructor() {}
}

export type Message = {
  message: string;
  messageType: number;
  createdAt: string;
};

export type WebRTCMessageRequestType = {
  messageType: number;
  message: string;
};

export type WebRTCMessageResponseType = {
  status: number;
  message: string;
};

export type WebRTCMessageGetResponseType = {
  status: number;
  message: string;
  result: [
    {
      message: string;
      message_type: number;
      created_at: string;
    }
  ];
};
