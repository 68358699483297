import { ExamProcess } from '@/store/enum/ExamProcess';

/* eslint-disable camelcase */
export class TestStatusAdapter {
  constructor(
    public loginId: string = '',
    public process: ExamProcess | null = null,
    public eai: string | null = '',
    public testName: string | null = '',
    public region: string | null = '',
    public groupId: string | null = '',
    public group: string | null = '',
    public termSetCode: string = ''
  ) {}
}

export type TestStatusRequestType = {
  loginId?: string;
  process: ExamProcess;
  eai: string;
  testName: string;
  region: string;
  groupId: string;
  group: string;
  termSetCode?: string;
};

export type TestStatusResponseType = {
  status: number;
  message: string;
  result: object;
};
