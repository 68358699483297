/* eslint-disable camelcase */
export class MonitorActivateListAdapter {
  constructor(public monitors: number[] = []) {}
}

// マッチング処理 レスポンスパラメータ
export type MonitorActivateListResponseType = {
  status: number;
  result: { monitors: number[] };
  message: string;
};
