/* eslint-disable camelcase */
import { Matching, SignalingChannel } from './matchingAdapter';
import { TesterState } from '@/store/enum/TesterState';
import { GetMcTokenMode } from '@/store/types/adapters/getMcTokenAdapter';
import { StepbarState } from '@/store/enum/StepbarState';
import { Marking, Record } from '@/store/types/adapters/commonMarkingTimelineAdapter';
import { MasterKVS } from '~/plugins/kvs/master';
import { DeviceState } from '@/store/enum/deviceState';

export class TesterPageAdapter {
  constructor(
    // 受験者ID
    public testerId: number | null = 0,
    // 受験者名
    public examName: string | null = '',
    // 試験ID
    public testId: number | null = 0,
    // マッチング情報
    public matchings: Matching[] = [],
    // シグナリングチャンネル情報
    public signalingChannel: SignalingChannel[] = [],
    // マーキング情報
    public markingTotalScore: number = 0,
    public markings: Marking[] = [],
    public records: Record[] = [],
    // 受験者ステータス
    public testerState: TesterState = TesterState.LOGIN,
    public isAuthError: boolean = false,
    // ステップバーステータス
    public stepbarState: StepbarState | null = StepbarState.ENV_SETTING,
    // 試験利用規約同意
    public isExamTermAgree: boolean = false,
    // プロクター利用規約同意
    public isProcterTermAgree: boolean = false,
    // カメラ有効
    public enableCamera: DeviceState = DeviceState.BEFORE_CHECK,
    // マイク有効
    public enableMicrophone: DeviceState = DeviceState.BEFORE_CHECK,
    // 本人確認中
    public isIdentifyCheckPending: boolean = false,
    // 本人認証作業完了
    public isIdentityCheckFinished: boolean = false,
    // 本人認証失敗
    public isIdentifyCheckNG: boolean = false,
    // アクセスキーID
    public accessKeyId: string | null = '',
    // シークレットアクセスキー
    public secretAccessKey: string | null = '',
    // セッショントークン
    public sessionToken: string | null = '',
    // バケット
    public bucket: string | null = '',
    // キー
    public key: string | null = '',
    // アップロードID
    public uploadId: string | null = '',
    // 録画時間
    public interval: number = 0,
    // 試験状態
    public process: number | null = 0,
    // KVSのMaster 情報
    public master: {
      localStream: MediaStream | null;
      localView: HTMLVideoElement | null;
    } = {
      localStream: null,
      localView: null,
    },
    public kvsMaster: MasterKVS | null = null,
    public chatItem: { comment: string; sender: string }[] = [],
    // 利用規約既読
    public isProcterTermRead: boolean = false,
    // モード
    public mode: GetMcTokenMode | null = null,
    // アクセストークン
    public accessToken: string | null = '',
    // 受験者名
    public examUserName: string | null = '',
    // マッチングのポーリング処理のsetIntervalの戻り値
    public intervalMatching: NodeJS.Timeout | null = null,
    public isProctor: number | null = null,
    public disconnect: boolean = false,
    public message: string | null = null,
    public status: number = 0,
    public result: object | null = {},
    public rejected: number = 0,
    public identityImage: string = '',
    public idImage: string = '',
    public countRetryNum: number = 0,
    public fixedStrAiIdentifyList: { str: string; check: boolean }[] = [],
    public noticeAiDisplay: {
      selectedStr: string;
      noticeType: number;
      retry?: number;
      limite?: number;
      failStr?: string;
    } = {
      retry: undefined,
      limite: undefined,
      failStr: undefined,
      noticeType: 1,
      selectedStr: '',
    }
  ) {}
}

export type TesterConditions = {
  isDisconnect: boolean;
  // 試験状況
  isBeforeExam: boolean;
  isExaming: boolean;
  isInterruptionExam: boolean;
  isFinishExam: boolean;
  // 録画状況
  isBeforeRecording: boolean;
  isRecording: boolean;
  isAfterRecording: boolean;
};
