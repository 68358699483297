











































import Vue from 'vue';
import { LoginAdapter } from '@/store/types/adapters/loginAdapter';
import StartupAdapter from '@/store/types/adapters/startupAdapter';
import * as rootTypes from '@/store/types/rootType';
import * as commonTypes from '@/store/types/commonType';
import * as loginTypes from '@/store/types/loginType';
import * as testerPageTypes from '@/store/types/testerPageType';
import { Actor } from '@/store/enum/Actor';
import { TesterState } from '@/store/enum/TesterState';
import { isUG } from '@/utils/ultra-guardian';

export default Vue.extend({
  name: 'Header',
  data() {
    return {
      // actor: this.$store.getters[rootTypes.GETTER_STARTUP].actor,
      displayUserName: '' as string,
    };
  },
  computed: {
    inParam(): StartupAdapter {
      return this.$store.getters[rootTypes.GETTER_STARTUP];
    },
    selectData(): LoginAdapter {
      return this.$store.getters[loginTypes.GETTER_LOGIN];
    },
    target(): string | null {
      const target = this.inParam.target;
      const domain = this.extractDomain(target);
      return domain || this.extractDomain(decodeURIComponent(target));
    },
    displayLang(): any {
      return this.$store.getters[rootTypes.GETTER_DISPLAY_LANG];
    },
    isDisplayLogout() {
      const actor = this.$store.getters[rootTypes.GETTER_STARTUP].actor;
      const name = this.$route.name;
      if (actor !== Actor.TESTER || name === 'login' || name === 'env-setting') {
        return true;
      } else if (name === 'examining') {
        const testerStatus = this.$store.getters[testerPageTypes.GETTER_TESTER_PAGE_GET_TESTER_STATE];
        return testerStatus !== TesterState.EXAMING;
      } else {
        return false;
      }
    },
    isLoginPage(): boolean {
      return this.$route.name === 'login';
    },
    isLogoutButtonDisabled(): boolean {
      return this.$store.getters[commonTypes.GETTER_COMMON_GET_HEADER_LOGOUT_BUTTON_DISABLED];
    },
  },
  created() {
    this.displayUserName = this.setUserName();
    this.$nuxt.$on('setHeaderDisplayUserName', () => {
      this.displayUserName = `${this.selectData.examUserName} (${this.selectData.loginId})`;
    });
  },
  methods: {
    setUserName() {
      // FIXME: 管理者・監視者の時の状態変化を起動パラメータからうける。
      if (this.inParam.loginId) {
        return `${decodeURIComponent(this.inParam.examUserName as string)} (${decodeURIComponent(
          this.inParam.loginId as string
        )})`;
      } else {
        return '';
      }
    },
    extractDomain(target: string): string | null {
      const match = target.match(/^https?:\/{2,}(.*?)(?:\/|\?|#|$)/);
      if (match == null || match.length < 2) {
        return null;
      } else {
        return match[1];
      }
    },
    onClickLogout() {
      if (this.$route.name === 'login') {
        if (confirm(this.displayLang.CONFIRM_WINDOW_CLOSE)) {
          this.$window.setBeforeUnload(false);
          this.$store.dispatch(loginTypes.ACTION_LOGOUT).then(() => {
            window.close();
          });
        }
      } else {
        const actor = this.$store.getters[loginTypes.GETTER_LOGIN].actor;
        switch (actor) {
          case Actor.CHECKER:
            if (confirm(this.displayLang.CONFIRM_LOGOUT_CHECKER)) {
              this.$store.dispatch(rootTypes.ACTION_LOGOUT_AND_REDIRECT_LOGIN_PAGE);
            }
            break;

          case Actor.TESTER:
          default:
            if (confirm(this.displayLang.CONFIRM_LOGOUT)) {
              this.$store.dispatch(rootTypes.ACTION_LOGOUT_AND_REDIRECT_LOGIN_PAGE);
            }
            break;
        }
      }
    },
    isUG,
  },
});
