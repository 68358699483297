/* eslint-disable prettier/prettier */
// Getters
export const GETTER_TEST_MARKINGS: string = 'testMarkings/GETTER_TEST_MARKINGS';

// Mutations
export const MUTATION_TEST_MARKINGS: string = 'testMarkings/MUTATION_TEST_MARKINGS';

// Actions
export const ACTION_TEST_MARKINGS: string = 'testMarkings/ACTION_TEST_MARKINGS';
export const ACTION_TEST_MARKINGS_LATEST: string = 'testMarkings/ACTION_TEST_MARKINGS_LATEST';
