/* eslint-disable camelcase */
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { FaceAdapter, FaceResponseType, FaceRequestType } from '~/store/types/adapters/faceAdapter';
import { ErrorStatus } from '../types/adapters/errorAdapter';
import * as faceType from '~/store/types/faceType';
import * as testerPageType from '~/store/types/testerPageType';
import * as errorType from '~/store/types/errorType';
import { Endpoint } from '~/store/const/endpoint';

const state = () => {
  return new FaceAdapter();
};

type FaceState = ReturnType<typeof state>;

const getters: GetterTree<FaceState, FaceState> = {
  [faceType.GETTER_FACE](state: FaceState) {
    return { ...state };
  },
};

const mutations: MutationTree<FaceState> = {
  [faceType.MUTATION_FACE](state: FaceState, payload: FaceAdapter) {
    state.status = payload.status;
    state.process = payload.process;
    state.faceRetry = payload.faceRetry;
    state.idCardRetry = payload.idCardRetry;
    state.faceUrl = payload.faceUrl;
    state.idCardUrl = payload.idCardUrl;
    state.messages = payload.messages;
  },
};

const actions: ActionTree<FaceState, FaceState> = {
  [faceType.ACTION_REQUEST_POST_FACE](
    context,
    { image, content_type }: FaceRequestType
  ): Promise<FaceResponseType | ErrorStatus> {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(Endpoint.FACE.url, {
          image,
          content_type,
        })
        .then((response: FaceResponseType) => {
          // TODO : 2020/10/14 - レスポンスが正しく修正されてないので、処置
          const result = typeof response.result === 'string' ? JSON.parse(response.result) : response.result;
          if (response.status === 200) {
            context.commit(faceType.MUTATION_FACE, {
              status: result.status,
              faceRetry: result.face_retry,
              idCardRetry: result.id_card_retry,
              faceUrl: result.face_url,
              idCarUrl: result.id_card_url,
              messages: result.messages,
            });
            // context.dispatch(testerPageType.ACTION_TESTER_PAGE_COUNT_RETRY_NUM, result.face_retry);
            context.dispatch(testerPageType.ACTION_TESTER_PAGE_COUNT_RETRY_NUM, result.ai_all_retry);
            resolve(context.getters[faceType.GETTER_FACE]);
          } else {
            const err: ErrorStatus = {
              endpoint: Endpoint.FACE,
              status: response.status,
              message: response.message,
              result: result,
            };
            context.dispatch(errorType.ACTION_SET_ERROR, err);
            resolve(err);
          }
        })
        .catch((e: any) => {
          console.error('[face http request error] : ', e);
          reject(e);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
