/* eslint-disable prettier/prettier */
// Getters
export const GETTER_GET_MC_TOKEN: string = 'getMcToken/GETTER_GET_MC_TOKEN';

// Mutations
export const MUTATION_GET_MC_TOKEN: string = 'getMcToken/MUTATION_GET_MC_TOKEN';
export const MUTATION_SET_MODE: string = 'getMcToken/MUTATION_SET_MODE';

// Actions
export const ACTION_GET_MC_TOKEN: string = 'getMcToken/ACTION_GET_MC_TOKEN';
