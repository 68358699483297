/* eslint-disable no-console */
import AWS from 'aws-sdk';
import { Role, SignalingClient } from 'amazon-kinesis-video-streams-webrtc';
import { Plugin, Context } from '@nuxt/types';
import { KVSConfig } from './kvsconfig';
import { KvsDataType, KvsCommand, CommandObject, MessageObject } from '@/plugins/kvs/type/sendMessageType';

type viewerTypes = {
  remoteStream: MediaStream | null;
  remoteView: HTMLVideoElement | null;
  remoteViewSoundProcessor: ScriptProcessorNode | null;
  gainNode: GainNode | null;
  signalingClient: SignalingClient | null;
  dataChannel: RTCDataChannel | null;
  peerConnection: RTCPeerConnection | null;
  peerConnectionStatsInterval: NodeJS.Timeout | null;
};

declare module 'vue/types/vue' {
  interface Vue {
    $kvsViewer(
      remoteView: HTMLVideoElement,
      onStatsReport: StatusReportHandler,
      onRemoteDataMessage: MessageHandler
    ): ViewerKVS;
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $kvsViewer(
      remoteView: HTMLVideoElement,
      onStatsReport: StatusReportHandler,
      onRemoteDataMessage: MessageHandler
    ): ViewerKVS;
  }
}
declare module 'vuex/types/index' {
  interface Store<S> {
    $kvsViewer(
      remoteView: HTMLVideoElement,
      onStatsReport: StatusReportHandler,
      onRemoteDataMessage: MessageHandler
    ): ViewerKVS;
  }
}

/* -------------------------------------------- declare types end -------------------------------------------- */

type StatusReportHandler = (data: RTCStatsReport | DOMError) => void;
type MessageHandler = (event: any) => void;

export class ViewerKVS extends KVSConfig {
  public viewer: viewerTypes = {
    remoteStream: null,
    remoteView: null,
    remoteViewSoundProcessor: null,
    gainNode: null,
    signalingClient: null,
    dataChannel: null,
    peerConnection: null,
    peerConnectionStatsInterval: null,
  };

  constructor(
    private remoteView: HTMLVideoElement,
    private onStatsReport: StatusReportHandler,
    private onRemoteDataMessage: MessageHandler,
    private context: Context
  ) {
    super();
  }

  async startViewer() {
    this.viewer.remoteView = this.remoteView;
    // Create KVS client
    const kinesisVideoClient = new AWS.KinesisVideo({
      region: this.defaultKVSConfig.region,
      accessKeyId: this.defaultKVSConfig.accessKeyId,
      secretAccessKey: this.defaultKVSConfig.secretAccessKey,
      sessionToken: this.defaultKVSConfig.sessionToken,
      endpoint: this.defaultKVSConfig.endpoint,
      correctClockSkew: true,
    });

    // Get signaling channel ARN
    const describeSignalingChannelResponse = await kinesisVideoClient
      .describeSignalingChannel({
        ChannelName: this.defaultKVSConfig.channelName,
      })
      .promise();

    const channelARN = (describeSignalingChannelResponse as any).ChannelInfo.ChannelARN;
    console.log('[VIEWER] Channel ARN: ', channelARN);

    // Get signaling channel endpoints
    const getSignalingChannelEndpointResponse = await kinesisVideoClient
      .getSignalingChannelEndpoint({
        ChannelARN: channelARN,
        SingleMasterChannelEndpointConfiguration: {
          Protocols: ['WSS', 'HTTPS'],
          Role: Role.VIEWER,
        },
      })
      .promise();
    const endpointsByProtocol = (getSignalingChannelEndpointResponse as any).ResourceEndpointList.reduce(
      (endpoints: any, endpoint: any) => {
        endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
        return endpoints;
      },
      {}
    );
    console.log('[VIEWER] Endpoints: ', endpointsByProtocol);

    const kinesisVideoSignalingChannelsClient = new AWS.KinesisVideoSignalingChannels({
      region: this.defaultKVSConfig.region,
      accessKeyId: this.defaultKVSConfig.accessKeyId,
      secretAccessKey: this.defaultKVSConfig.secretAccessKey,
      sessionToken: this.defaultKVSConfig.sessionToken,
      endpoint: endpointsByProtocol.HTTPS,
    });

    // Get ICE server configuration
    const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
      .getIceServerConfig({
        ChannelARN: channelARN,
      })
      .promise();
    const iceServers = [];
    if (!this.defaultKVSConfig.natTraversalDisabled && !this.defaultKVSConfig.forceTURN) {
      iceServers.push({
        urls: `stun:stun.kinesisvideo.${this.defaultKVSConfig.region}.amazonaws.com:443`,
      });
    }
    if (!this.defaultKVSConfig.natTraversalDisabled) {
      (getIceServerConfigResponse as any).IceServerList.forEach((iceServer: any) =>
        iceServers.push({
          urls: iceServer.Uris,
          username: iceServer.Username,
          credential: iceServer.Password,
        })
      );
    }
    console.log('[VIEWER] ICE servers: ', iceServers);

    // Create Signaling Client
    this.viewer.signalingClient = new SignalingClient({
      channelARN,
      channelEndpoint: endpointsByProtocol.WSS,
      clientId: this.defaultKVSConfig.clientId,
      role: Role.VIEWER,
      region: this.defaultKVSConfig.region,
      credentials: {
        accessKeyId: this.defaultKVSConfig.accessKeyId,
        secretAccessKey: this.defaultKVSConfig.secretAccessKey,
        sessionToken: this.defaultKVSConfig.sessionToken,
      },
    });

    const configuration: any = {
      iceServers,
      iceTransportPolicy: this.defaultKVSConfig.forceTURN ? 'relay' : 'all',
    };
    this.viewer.peerConnection = new RTCPeerConnection(configuration);
    if (this.defaultKVSConfig.openDataChannel) {
      this.viewer.dataChannel = (this.viewer.peerConnection as RTCPeerConnection).createDataChannel(
        this.defaultKVSConfig.channelName
      );
      (this.viewer.peerConnection as RTCPeerConnection).ondatachannel = (event: any) => {
        event.channel.onmessage = this.onRemoteDataMessage;
      };
    }

    // Poll for connection stats
    this.viewer.peerConnectionStatsInterval = setInterval(() => {
      try {
        (this.viewer.peerConnection as RTCPeerConnection).getStats().then(this.onStatsReport).catch(this.onStatsReport);
      } catch (error) {
        if (this.viewer.peerConnectionStatsInterval) {
          window.clearInterval(this.viewer.peerConnectionStatsInterval as NodeJS.Timeout);
          this.viewer.peerConnectionStatsInterval = null;
        }
      }
    }, 10000);
    (this.viewer.signalingClient as SignalingClient).on('open', async () => {
      this.context.app.$kvsEventBus.$emit(
        `${this.defaultKVSConfig.channelName}-viewer-open`,
        this.viewer.peerConnection
      );
      console.log('[VIEWER] Connected to signaling service');
      // Create an SDP offer to send to the master
      console.log('[VIEWER] Creating SDP offer');
      await (this.viewer.peerConnection as RTCPeerConnection).setLocalDescription(
        await (this.viewer.peerConnection as RTCPeerConnection).createOffer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        })
      );

      // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
      if (this.defaultKVSConfig.useTrickleICE) {
        console.log('[VIEWER] Sending SDP offer');
        (this.viewer.signalingClient as SignalingClient).sendSdpOffer(
          (this.viewer.peerConnection as RTCPeerConnection).localDescription as RTCSessionDescription
        );
      }
      console.log('[VIEWER] Generating ICE candidates');
    });

    (this.viewer.signalingClient as SignalingClient).on('sdpAnswer', async (answer: any) => {
      this.context.app.$kvsEventBus.$emit(`${this.defaultKVSConfig.channelName}-viewer-sdpAnswer`, 'sdpAnswer');
      // Add the SDP answer to the peer connection
      console.log('[VIEWER] Received SDP answer');
      await (this.viewer.peerConnection as RTCPeerConnection).setRemoteDescription(answer);
    });

    (this.viewer.signalingClient as SignalingClient).on('iceCandidate', (candidate: any) => {
      // Add the ICE candidate received from the MASTER to the peer connection
      console.log('[VIEWER] Received ICE candidate');
      (this.viewer.peerConnection as RTCPeerConnection).addIceCandidate(candidate);
    });

    (this.viewer.signalingClient as SignalingClient).on('close', () => {
      this.context.app.$kvsEventBus.$emit(`${this.defaultKVSConfig.channelName}-viewer-close`, 'close');
      console.log('[VIEWER] Disconnected from signaling channel');
    });

    (this.viewer.signalingClient as SignalingClient).on('error', (error: any) => {
      this.context.app.$kvsEventBus.$emit(`${this.defaultKVSConfig.channelName}-viewer-error`, 'error');
      console.error('[VIEWER] Signaling client error: ', error);
    });

    // Send any ICE candidates to the other peer
    (this.viewer.peerConnection as RTCPeerConnection).addEventListener('icecandidate', ({ candidate }: any) => {
      if (candidate) {
        // console.log('[VIEWER] Generated ICE candidate');

        // When trickle ICE is enabled, send the ICE candidates as they are generated.
        if (this.defaultKVSConfig.useTrickleICE) {
          // console.log('[VIEWER] Sending ICE candidate');
          (this.viewer.signalingClient as SignalingClient).sendIceCandidate(candidate);
        }
      } else {
        console.log('[VIEWER] All ICE candidates have been generated');

        // When trickle ICE is disabled, send the offer now that all the ICE candidates have ben generated.
        if (!this.defaultKVSConfig.useTrickleICE) {
          console.log('[VIEWER] Sending SDP offer');
          (this.viewer.signalingClient as SignalingClient).sendSdpOffer(
            (this.viewer.peerConnection as RTCPeerConnection).localDescription as RTCSessionDescription
          );
        }
      }
    });

    // this.viewer.peerConnection.addEventListener('track', this.onTrack);
    (this.viewer.peerConnection as RTCPeerConnection).addEventListener('track', (event: RTCTrackEvent) => {
      this.context.app.$kvsEventBus.$emit(`${this.defaultKVSConfig.channelName}-viewer-track`, event);
      console.log('[VIEWER] Received remote track');
      this.viewer.remoteStream = event.streams[0];
      (this.viewer.remoteView as any).srcObject = this.viewer.remoteStream;

      const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
      const audioCtx = new AudioContext();

      const mediaStreamSource = audioCtx.createMediaStreamSource(this.viewer.remoteStream);
      const audioDestination = audioCtx.createMediaStreamDestination();
      const gainNode = audioCtx.createGain();
      gainNode.connect(audioDestination);
      this.viewer.remoteViewSoundProcessor = audioCtx.createScriptProcessor(1024, 1, 1);

      // medaiStreamへgainNode, MediaStreamAudioDestinationNode, ScriptProcessorNodeを繋がせる。
      mediaStreamSource.connect(gainNode);
      mediaStreamSource.connect(audioDestination);
      mediaStreamSource.connect(this.viewer.remoteViewSoundProcessor);

      this.viewer.gainNode = gainNode;
      this.viewer.remoteViewSoundProcessor.connect(audioCtx.destination);
      this.viewer.remoteViewSoundProcessor.addEventListener('audioprocess', this.audioProcess.bind(this));
    });
    console.log('[VIEWER] Starting this.viewer connection');
    (this.viewer.signalingClient as SignalingClient).open();
  }

  audioProcess(e: AudioProcessingEvent) {
    const inputData = e.inputBuffer.getChannelData(0);
    const inputDataLength = inputData.length;
    let total = 0;
    for (let i = 0; i < inputDataLength; i++) {
      total += Math.abs(inputData[i++]);
    }
    const rms = Math.sqrt(total / inputDataLength);
    this.context.app.$kvsEventBus.$emit(
      `${this.defaultKVSConfig.channelName}-viewer-audioprocess`,
      Math.round(rms * 100)
    );
  }

  stopViewer() {
    if (this.viewer.signalingClient) {
      (this.viewer.signalingClient as SignalingClient).close();
      this.viewer.signalingClient = null;
    }

    if (this.viewer.peerConnection) {
      (this.viewer.peerConnection as RTCPeerConnection).close();
      this.viewer.peerConnection = null;
    }

    if (this.viewer.remoteStream) {
      this.viewer.remoteStream.getTracks().forEach((track: any) => track.stop());
      this.viewer.remoteStream = null;
    }

    if (this.viewer.peerConnectionStatsInterval) {
      clearInterval(this.viewer.peerConnectionStatsInterval);
      this.viewer.peerConnectionStatsInterval = null;
    }

    if (this.viewer.remoteViewSoundProcessor) {
      this.viewer.remoteViewSoundProcessor.removeEventListener('audioprocess', this.audioProcess);
      this.viewer.remoteViewSoundProcessor = null;
    }

    if (this.viewer.remoteView) {
      this.viewer.remoteView.srcObject = null;
    }

    if (this.viewer.dataChannel) {
      this.viewer.dataChannel = null;
    }
  }

  /**
   * データを送ります
   *
   * @param {any} data
   */
  private sendViewData(data: any) {
    const dataJson = JSON.stringify(data);

    if (this.viewer.dataChannel) {
      try {
        this.viewer.dataChannel.send(dataJson);
      } catch (e) {
        console.error('[VIEWER] Send DataChannel: ', e.toString());
      }
    }
  }

  /**
   * メッセージを送ります
   *
   * @param {MessageObject} data
   */
  public sendViewerMessage(data: MessageObject) {
    this.sendViewData(data);
  }

  /**
   * [kickout]コマンドを送ります
   */
  sendKickout() {
    this.sendViewData({
      dataType: KvsDataType.COMMAND,
      command: KvsCommand.KICKOUT,
    } as CommandObject);
  }

  /**
   * [AUTHENTICATED]コマンドを送ります
   */
  sendAuthenticate() {
    this.sendViewData({
      dataType: KvsDataType.COMMAND,
      command: KvsCommand.IDENTIFICATION_AUTHENTICATED,
    } as CommandObject);
  }

  /**
   * [ACCEPTED]コマンドを送ります
   */
  sendAccept() {
    this.sendViewData({
      dataType: KvsDataType.COMMAND,
      command: KvsCommand.IDENTIFICATION_ACCEPTED,
    } as CommandObject);
  }

  /**
   * [REJECTED]コマンドを送ります
   */
  sendReject() {
    this.sendViewData({
      dataType: KvsDataType.COMMAND,
      command: KvsCommand.IDENTIFICATION_REJECTED,
    } as CommandObject);
  }

  /**
   * [BEFORE CLOSE OK]コマンドを送ります
   */
  public sendBeforeCloseOk() {
    this.sendViewData({
      dataType: KvsDataType.COMMAND,
      command: KvsCommand.BEFORE_CLOSE_OK,
    } as CommandObject);
  }
}

const kvsViewerPlugin: Plugin = (context, inject) => {
  inject(
    'kvsViewer',
    (remoteView: HTMLVideoElement, onStatsReport: StatusReportHandler, onRemoteDataMessage: MessageHandler) => {
      return new ViewerKVS(remoteView, onStatsReport, onRemoteDataMessage, context);
    }
  );
};
export default kvsViewerPlugin;
