/* eslint-disable camelcase */
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { ActionContext } from 'aws-sdk/clients/codepipeline';
import { ErrorStatus } from '../types/adapters/errorAdapter';
import { Endpoint } from '@/store/const/endpoint';
import {
  MatchingAdapter,
  MatchingResponseType,
  DeleteMatchingRequestType,
  DeleteMatchingResponseType,
} from '@/store/types/adapters/matchingAdapter';
import * as types from '@/store/types/matchingType';
import * as errTypes from '@/store/types/errorType';

const state = () => {
  return new MatchingAdapter();
};

type MatchingState = ReturnType<typeof state>;

const getters: GetterTree<MatchingState, MatchingState> = {
  [types.GETTER_MATCHING](state: MatchingState) {
    return { ...state };
  },
};

const mutations: MutationTree<MatchingState> = {
  [types.MUTATION_MATCHING](state: MatchingState, payload: MatchingAdapter) {
    state.matchings = payload.matchings;
  },
  [types.MUTATION_DELETE_MATCHING](state: MatchingState, testerId: number) {
    state.matchings = state.matchings.filter((v) => v.testerId != testerId);
  },
  [types.MUTATION_SET_HEAD_SHOT_URL](state: MatchingState, payload: { testerId: number; headShotUrl: string }) {
    const matchings = state.matchings;
    matchings.forEach((matching) => {
      if (matching.testerId === payload.testerId) {
        matching.headShotUrl = payload.headShotUrl;
      }
    });
    state.matchings = matchings;
  },
  [types.MUTATION_SET_ID_CARD_URL](state: MatchingState, payload: { testerId: number; idCardUrl: string }) {
    const matchings = state.matchings;
    matchings.forEach((matching) => {
      if (matching.testerId === payload.testerId) {
        matching.idCardUrl = payload.idCardUrl;
      }
    });
    state.matchings = matchings;
  },
};

const actions: ActionTree<MatchingState, MatchingState> = {
  /**
   * マッチング情報を取得します
   *
   * @param {Context} context
   * @param {{ testerId: number; channelStatus: string }} payload
   * @return {Promise<boolean>}
   */
  [types.ACTION_MATCHING_POST](context, payload: { testerId: number; channelStatus: string }): Promise<boolean> {
    console.log('[LOG INFO] matching data post (WebRTC stat Request)');
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(Endpoint.MATCHING.url, {
          tester_id: payload.testerId,
          channel_status: payload.channelStatus,
        })
        .then(({ status, result, message }: MatchingResponseType) => {
          if (status === 200) {
            context.commit(types.MUTATION_MATCHING, {
              matchings: result.matchings.map((x) => ({
                matchingStatus: x.matching_status,
                monitorId: x.monitor_id,
                matchingConnectedTime: x.matching_connected_time,
                matchingTotalTime: x.matching_total_time,
                signalingChannel: {
                  region: x.signaling_channel.region,
                  accessKeyId: x.signaling_channel.access_key_id,
                  secretAccessKey: x.signaling_channel.secret_access_key,
                  sessionToken: x.signaling_channel.session_token,
                  channelName: x.signaling_channel.channel_name,
                },
                status: x.status,
                testerId: x.tester_id,
                fileName: x.file_name,
              })),
            });
            resolve(true);
          } else {
            const err: ErrorStatus = {
              endpoint: Endpoint.MATCHING,
              status,
              message,
            };
            context.dispatch(errTypes.ACTION_SET_ERROR, err);
            reject(err);
          }
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },
  /**
   * マッチング情報を取得します
   *
   * @param {Context} context
   * @return {Promise<MatchingAdapter>}
   */
  [types.ACTION_MATCHING](context): Promise<MatchingAdapter> {
    console.log('[LOG INFO] matching data');
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(Endpoint.MATCHING.url, {})
        .then((response: MatchingResponseType) => {
          if (response.status === 200) {
            context.commit(types.MUTATION_MATCHING, {
              matchings: response.result.matchings.map((x) => {
                return {
                  matchingStatus: x.matching_status,
                  monitorId: x.monitor_id,
                  matchingConnectedTime: x.matching_connected_time,
                  matchingTotalTime: x.matching_total_time,
                  signalingChannel: {
                    region: x.signaling_channel.region,
                    accessKeyId: x.signaling_channel.access_key_id,
                    secretAccessKey: x.signaling_channel.secret_access_key,
                    sessionToken: x.signaling_channel.session_token,
                    channelName: x.signaling_channel.channel_name,
                  },
                  status: x.status,
                  testerId: x.tester_id,
                  fileName: x.file_name,
                };
              }),
            });
            resolve(context.getters[types.GETTER_MATCHING]);
          } else {
            const err: ErrorStatus = {
              endpoint: Endpoint.MATCHING,
              status: response.status,
              message: response.message,
            };
            context.dispatch(errTypes.ACTION_SET_ERROR, err);
            reject(err);
          }
        })
        .catch((e: any) => {
          console.error('[Matching error] : ', e);
          // TODO : will be implement error log api request.
          reject(e);
        });
    });
  },
  /**
   * マッチングを削除します
   *
   * @param {Context} context
   * @param {DeleteMatchingRequestType} payload
   * @return {Promise<void>}
   */
  [types.ACTION_DELETE_MATCHING](context, payload: DeleteMatchingRequestType): Promise<void> {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(Endpoint.DELETE_MATCHING.url, payload)
        .then(({ status, message }: DeleteMatchingResponseType) => {
          if (status === 200) {
            // TODO:本来は消す必要があるが、十分考慮してないためエラーが出たのでとりあえずコメントアウト
            // context.commit(types.MUTATION_DELETE_MATCHING, payload.tester_id)
            resolve();
          } else {
            const err: ErrorStatus = {
              endpoint: Endpoint.DELETE_MATCHING,
              status,
              message,
            };
            context.dispatch(errTypes.ACTION_SET_ERROR, err);
            reject(err);
          }
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },
  [types.ACTION_SET_HEAD_SHOT_URL](context, payload: { testerId: number; headShotUrl: string }) {
    context.commit(types.MUTATION_SET_HEAD_SHOT_URL, payload);
  },
  [types.ACTION_SET_ID_CARD_URL](context, payload: { testerId: number; idCardUrl: string }) {
    context.commit(types.MUTATION_SET_ID_CARD_URL, payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
