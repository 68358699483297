export enum KvsDataType {
  COMMAND = 'command',
  MESSAGE = 'message',
}

export enum KvsCommand {
  KICKOUT = '<<<KICKOUT>>>',
  BEFORE_CLOSE = '<<<BEFORE_CLOSE>>>',
  BEFORE_CLOSE_OK = '<<<BEFORE_CLOSE_OK>>>',
  IDENTIFICATION_AUTHENTICATED = '<<<AUTHENTICATED>>>',
  IDENTIFICATION_ACCEPTED = '<<<ACCEPTED>>>',
  IDENTIFICATION_REJECTED = '<<<REJECTED>>>',
}

type DataHeader = {
  dataType: KvsDataType;
};

export type CommandObject = DataHeader & {
  command: KvsCommand;
  data?: string; // 必要ならJSONでも突っ込む？
};

export type MessageObject = DataHeader & {
  url?: string; // 不正報告画像 URL (監視者から受験者へ送るとき)
  alert: string; // メッセージ
  loginId: string; // ログインID
  domainName: string; // プロクタードメイン名
};
