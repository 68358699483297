import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _02bc7c22 = () => interopDefault(import('../pages/access-limit/index.vue' /* webpackChunkName: "pages/access-limit/index" */))
const _4134ed2e = () => interopDefault(import('../pages/analysis-request/index.vue' /* webpackChunkName: "pages/analysis-request/index" */))
const _6431a184 = () => interopDefault(import('../pages/close/index.vue' /* webpackChunkName: "pages/close/index" */))
const _2e05fa96 = () => interopDefault(import('../pages/delete-info/index.vue' /* webpackChunkName: "pages/delete-info/index" */))
const _7a98f95e = () => interopDefault(import('../pages/delete-plan/index.vue' /* webpackChunkName: "pages/delete-plan/index" */))
const _2e3421fe = () => interopDefault(import('../pages/delete-setting/index.vue' /* webpackChunkName: "pages/delete-setting/index" */))
const _b4c2e714 = () => interopDefault(import('../pages/env-setting/index.vue' /* webpackChunkName: "pages/env-setting/index" */))
const _01896cfd = () => interopDefault(import('../pages/exam-end/index.vue' /* webpackChunkName: "pages/exam-end/index" */))
const _3db65a56 = () => interopDefault(import('../pages/examinees/index.vue' /* webpackChunkName: "pages/examinees/index" */))
const _349147b8 = () => interopDefault(import('../pages/examining/index.vue' /* webpackChunkName: "pages/examining/index" */))
const _13caf83e = () => interopDefault(import('../pages/identification/index.vue' /* webpackChunkName: "pages/identification/index" */))
const _08763ded = () => interopDefault(import('../pages/identification-ai/index.vue' /* webpackChunkName: "pages/identification-ai/index" */))
const _22e4f1f4 = () => interopDefault(import('../pages/iframe/index.vue' /* webpackChunkName: "pages/iframe/index" */))
const _1738e76f = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _47d5840c = () => interopDefault(import('../pages/logins/index.vue' /* webpackChunkName: "pages/logins/index" */))
const _6a53952f = () => interopDefault(import('../pages/logview/index.vue' /* webpackChunkName: "pages/logview/index" */))
const _514deb5a = () => interopDefault(import('../pages/management/index.vue' /* webpackChunkName: "pages/management/index" */))
const _26b7b418 = () => interopDefault(import('../pages/monitoring/index.vue' /* webpackChunkName: "pages/monitoring/index" */))
const _180ec7ec = () => interopDefault(import('../pages/redirect/index.vue' /* webpackChunkName: "pages/redirect/index" */))
const _5a49b40d = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _7d705ad6 = () => interopDefault(import('../pages/alerting/system-error.vue' /* webpackChunkName: "pages/alerting/system-error" */))
const _97080742 = () => interopDefault(import('../pages/checkers/monitoring/index.vue' /* webpackChunkName: "pages/checkers/monitoring/index" */))
const _7bb3547e = () => interopDefault(import('../pages/alerting/_id.vue' /* webpackChunkName: "pages/alerting/_id" */))
const _7e030980 = () => interopDefault(import('../pages/examinees/_id/index.vue' /* webpackChunkName: "pages/examinees/_id/index" */))
const _eab3c352 = () => interopDefault(import('../pages/examinees/_id/login.vue' /* webpackChunkName: "pages/examinees/_id/login" */))
const _51aa4256 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/access-limit",
    component: _02bc7c22,
    name: "access-limit"
  }, {
    path: "/analysis-request",
    component: _4134ed2e,
    name: "analysis-request"
  }, {
    path: "/close",
    component: _6431a184,
    name: "close"
  }, {
    path: "/delete-info",
    component: _2e05fa96,
    name: "delete-info"
  }, {
    path: "/delete-plan",
    component: _7a98f95e,
    name: "delete-plan"
  }, {
    path: "/delete-setting",
    component: _2e3421fe,
    name: "delete-setting"
  }, {
    path: "/env-setting",
    component: _b4c2e714,
    name: "env-setting"
  }, {
    path: "/exam-end",
    component: _01896cfd,
    name: "exam-end"
  }, {
    path: "/examinees",
    component: _3db65a56,
    name: "examinees"
  }, {
    path: "/examining",
    component: _349147b8,
    name: "examining"
  }, {
    path: "/identification",
    component: _13caf83e,
    name: "identification"
  }, {
    path: "/identification-ai",
    component: _08763ded,
    name: "identification-ai"
  }, {
    path: "/iframe",
    component: _22e4f1f4,
    name: "iframe"
  }, {
    path: "/login",
    component: _1738e76f,
    name: "login"
  }, {
    path: "/logins",
    component: _47d5840c,
    name: "logins"
  }, {
    path: "/logview",
    component: _6a53952f,
    name: "logview"
  }, {
    path: "/management",
    component: _514deb5a,
    name: "management"
  }, {
    path: "/monitoring",
    component: _26b7b418,
    name: "monitoring"
  }, {
    path: "/redirect",
    component: _180ec7ec,
    name: "redirect"
  }, {
    path: "/terms",
    component: _5a49b40d,
    name: "terms"
  }, {
    path: "/alerting/system-error",
    component: _7d705ad6,
    name: "alerting-system-error"
  }, {
    path: "/checkers/monitoring",
    component: _97080742,
    name: "checkers-monitoring"
  }, {
    path: "/alerting/:id?",
    component: _7bb3547e,
    name: "alerting-id"
  }, {
    path: "/examinees/:id",
    component: _7e030980,
    name: "examinees-id"
  }, {
    path: "/examinees/:id/login",
    component: _eab3c352,
    name: "examinees-id-login"
  }, {
    path: "/",
    component: _51aa4256,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
