/* eslint-disable camelcase */
export class LoginAdapter {
  constructor(
    public accessToken: string | null = '',
    public examUserName: string | null = '',
    public loginId: string | null = '',
    public actor: number | null = null,
    public kicked: number | null = null,
    public groupId: string = '',
    public group: string = '',
    public startupUrl: string = ''
  ) {}
}

type Result = {
  access_token: string;
  exam_user_name: string;
  login_id: string;
  actor: number;
  kicked: number | null;
  group_id: string;
  group: string;
  startup_url: string;
};

export type LoginRequestType = {
  login_id: string;
  password: string;
  actor: number;
};

export type LoginResponseType = {
  status: number;
  result: Result;
  message: string;
};

export type LogoutRequestType = {};

export type LogoutResponseType = {
  status: number;
  message: string;
};
