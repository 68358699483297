/* eslint-disable no-console */
import AWS from 'aws-sdk';
import { Role, SignalingClient } from 'amazon-kinesis-video-streams-webrtc';
import { Plugin, Context } from '@nuxt/types';
import { KVSConfig } from './kvsconfig';
import { KvsDataType, KvsCommand, CommandObject, MessageObject } from '@/plugins/kvs/type/sendMessageType';

declare module 'vue/types/vue' {
  interface Vue {
    $kvsMaster(
      localStream: MediaStream,
      localView: HTMLVideoElement,
      onStatsMasterReport: StatusReportHandler,
      onRemoteDataMessage: MessageHandler
    ): MasterKVS;
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $kvsMaster(
      localStream: MediaStream,
      localView: HTMLVideoElement,
      onStatsMasterReport: StatusReportHandler,
      onRemoteDataMessage: MessageHandler
    ): MasterKVS;
  }
}
declare module 'vuex/types/index' {
  interface Store<S> {
    $kvsMaster(
      localStream: MediaStream,
      localView: HTMLVideoElement,
      onStatsMasterReport: StatusReportHandler,
      onRemoteDataMessage: MessageHandler
    ): MasterKVS;
  }
}

type StatusReportHandler = (data: RTCStatsReport | DOMError) => void;
type MessageHandler = (event: any) => void;

type masterTypes = {
  localStream: MediaStream | null;
  localView: HTMLVideoElement | null;
  signalingClient: SignalingClient | null;
  dataChannelByClientId: { [key: string]: RTCDataChannel };
  peerConnectionByClientId: { [key: string]: RTCPeerConnection };
  peerConnectionStatsInterval: NodeJS.Timeout | null;
  onCloseCallback: ((result: boolean) => void) | null;
};

/* -------------------------------------------- declare types end -------------------------------------------- */

export class MasterKVS extends KVSConfig {
  public master: masterTypes = {
    localStream: null,
    localView: null,
    signalingClient: null,
    dataChannelByClientId: {},
    peerConnectionByClientId: {},
    peerConnectionStatsInterval: null,
    onCloseCallback: null,
  };

  constructor(
    private localStream: MediaStream,
    private localView: HTMLVideoElement,
    private onStatsMasterReport: StatusReportHandler,
    private onRemoteDataMessage: MessageHandler,
    private context: Context
  ) {
    super();
    // this.defaultKVSConfig = this.
    this.master.localStream = this.localStream;
    this.master.localView = this.localView;
  }

  // setKVSConfig(config: kvsConfigType) {
  //   this.defaultKVSConfig = Object.assign(this.defaultKVSConfig, config);
  // }

  /**
   * KVSを接続します
   */
  public async startMaster() {
    // Create KVS client
    const kinesisVideoClient = new AWS.KinesisVideo({
      region: this.defaultKVSConfig.region,
      accessKeyId: this.defaultKVSConfig.accessKeyId,
      secretAccessKey: this.defaultKVSConfig.secretAccessKey,
      sessionToken: this.defaultKVSConfig.sessionToken,
      endpoint: this.defaultKVSConfig.endpoint,
      correctClockSkew: true,
    });

    // Get signaling channel ARN
    const describeSignalingChannelResponse = await kinesisVideoClient
      .describeSignalingChannel({
        ChannelName: this.defaultKVSConfig.channelName,
      })
      .promise();
    const channelARN = (describeSignalingChannelResponse as any).ChannelInfo.ChannelARN;
    console.log('[MASTER] Channel ARN: ', channelARN);

    // Get signaling channel endpoints
    const getSignalingChannelEndpointResponse = await kinesisVideoClient
      .getSignalingChannelEndpoint({
        ChannelARN: channelARN,
        SingleMasterChannelEndpointConfiguration: {
          Protocols: ['WSS', 'HTTPS'],
          Role: Role.MASTER,
        },
      })
      .promise();
    const endpointsByProtocol = (getSignalingChannelEndpointResponse as any).ResourceEndpointList.reduce(
      (endpoints: any, endpoint: any) => {
        endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
        return endpoints;
      },
      {}
    );
    console.log('[MASTER] Endpoints: ', endpointsByProtocol);

    // Create Signaling Client
    this.master.signalingClient = new SignalingClient({
      channelARN,
      channelEndpoint: endpointsByProtocol.WSS,
      role: Role.MASTER,
      region: this.defaultKVSConfig.region,
      credentials: {
        accessKeyId: this.defaultKVSConfig.accessKeyId,
        secretAccessKey: this.defaultKVSConfig.secretAccessKey,
        sessionToken: this.defaultKVSConfig.sessionToken,
      },
    });

    // Get ICE server configuration
    const kinesisVideoSignalingChannelsClient = new AWS.KinesisVideoSignalingChannels({
      region: this.defaultKVSConfig.region,
      accessKeyId: this.defaultKVSConfig.accessKeyId,
      secretAccessKey: this.defaultKVSConfig.secretAccessKey,
      sessionToken: this.defaultKVSConfig.sessionToken,
      endpoint: endpointsByProtocol.HTTPS,
    });
    const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
      .getIceServerConfig({
        ChannelARN: channelARN,
      })
      .promise();
    const iceServers = [];
    if (!this.defaultKVSConfig.natTraversalDisabled && !this.defaultKVSConfig.forceTURN) {
      iceServers.push({
        urls: `stun:stun.kinesisvideo.${this.defaultKVSConfig.region}.amazonaws.com:443`,
      });
    }
    if (!this.defaultKVSConfig.natTraversalDisabled) {
      (getIceServerConfigResponse as any).IceServerList.forEach((iceServer: any) =>
        iceServers.push({
          urls: iceServer.Uris,
          username: iceServer.Username,
          credential: iceServer.Password,
        })
      );
    }
    console.log('[MASTER] ICE servers: ', iceServers);

    const configuration: any = {
      iceServers,
      iceTransportPolicy: this.defaultKVSConfig.forceTURN ? 'relay' : 'all',
    };

    if (!this.master.localStream || !this.master.localView) {
      console.error('[MASTER] Could not find webcam', this.master.localStream);
      return;
    }
    this.master.localView.srcObject = this.localStream;

    // eslint-disable-next-line require-await
    (this.master.signalingClient as SignalingClient).on('open', async () => {
      this.context.app.$kvsEventBus.$emit('master-open', 'open');
      console.log('[MASTER] Connected to signaling service');
    });

    (this.master.signalingClient as SignalingClient).on('sdpOffer', async (offer: any, remoteClientId: string) => {
      this.context.app.$kvsEventBus.$emit('master-sdpOffer', 'sdpOffer');
      console.log('[MASTER] Received SDP offer from client: ' + remoteClientId);

      if (!this.master.localStream) {
        console.error('[MASTER - SDP offer] there is no localStream');
        return;
      }

      // Create a new peer connection using the offer from the given client
      const peerConnection = new RTCPeerConnection(configuration);
      this.master.peerConnectionByClientId[remoteClientId] = peerConnection;

      if (this.defaultKVSConfig.openDataChannel) {
        this.master.dataChannelByClientId[remoteClientId] = peerConnection.createDataChannel(
          this.defaultKVSConfig.channelName
        );
        // @see https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/datachannel_event
        peerConnection.ondatachannel = (event: any) => {
          event.channel.onmessage = this.onRemoteDataMessage;
        };
      }

      // Poll for connection stats
      if (!this.master.peerConnectionStatsInterval) {
        this.master.peerConnectionStatsInterval = setInterval(() => {
          // @see https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/getStats
          peerConnection.getStats().then(this.onStatsMasterReport).catch(this.onStatsMasterReport);
        }, 1000);
      }

      // Send any ICE candidates to the other peer
      peerConnection.addEventListener('icecandidate', ({ candidate }) => {
        if (candidate) {
          console.log('[MASTER] Generated ICE candidate for client: ' + remoteClientId);

          // When trickle ICE is enabled, send the ICE candidates as they are generated.
          if (this.defaultKVSConfig.useTrickleICE) {
            console.log('[MASTER] Sending ICE candidate to client: ' + remoteClientId);
            (this.master.signalingClient as SignalingClient).sendIceCandidate(candidate, remoteClientId);
          }
        } else {
          console.log('[MASTER] All ICE candidates have been generated for client: ' + remoteClientId);

          // When trickle ICE is disabled, send the answer now that all the ICE candidates have ben generated.
          if (!this.defaultKVSConfig.useTrickleICE) {
            console.log('[MASTER] Sending SDP answer to client: ' + remoteClientId);
            (this.master.signalingClient as SignalingClient).sendSdpAnswer(
              (peerConnection as RTCPeerConnection).localDescription as RTCSessionDescription,
              remoteClientId
            );
          }
        }
      });

      peerConnection.addEventListener('connectionstatechange', () => {
        this.context.app.$kvsEventBus.$emit('master-connectionstatechange', peerConnection.connectionState);
        console.log('connectionstatechange');
        // if (peerConnection.connectionState === 'disconnected') {
        //   peerConnection.close();
        // }
      });

      // As remote tracks are received, add them to the remote view
      // peerConnection.addEventListener('track', (event) => {
      //   console.log('[MASTER] Received remote track from client: ' + remoteClientId);
      //   // const idx = idToIdx(this.master, remoteClientId);
      //   // console.log('[MASTER] idx: ' + idx);
      //   this.localView.srcObject = event.streams[0];
      // });

      // If there's no video/audio, master.localStream will be null. So, we should skip adding the tracks from it.
      this.master.localStream.getTracks().forEach((track: MediaStreamTrack) => {
        if (this.master.localStream) {
          return peerConnection.addTrack(track, this.master.localStream);
        }
      });

      await peerConnection.setRemoteDescription(offer);

      // Create an SDP answer to send back to the client
      console.log('[MASTER] Creating SDP answer for client: ' + remoteClientId);
      await peerConnection.setLocalDescription(
        await peerConnection.createAnswer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        })
      );

      // When trickle ICE is enabled, send the answer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
      if (this.defaultKVSConfig.useTrickleICE) {
        console.log('[MASTER] Sending SDP answer to client: ' + remoteClientId);
        (this.master.signalingClient as SignalingClient).sendSdpAnswer(
          (peerConnection as RTCPeerConnection).localDescription as RTCSessionDescription,
          remoteClientId
        );
      }
      console.log('[MASTER] Generating ICE candidates for client: ' + remoteClientId);
    });

    (this.master.signalingClient as SignalingClient).on(
      'iceCandidate',
      // eslint-disable-next-line require-await
      async (candidate: any, remoteClientId: any) => {
        console.log('[MASTER] Received ICE candidate from client: ' + remoteClientId);
        console.log(candidate);

        // Add the ICE candidate received from the client to the peer connection
        const peerConnection = this.master.peerConnectionByClientId[remoteClientId];
        peerConnection.addIceCandidate(candidate);
      }
    );

    (this.master.signalingClient as SignalingClient).on('close', () => {
      this.context.app.$kvsEventBus.$emit('master-close', (result: boolean) => {
        if (result) {
          Object.keys(this.master.peerConnectionByClientId).forEach((clientId) => {
            this.master.peerConnectionByClientId[clientId].close();
          });
          this.master.peerConnectionByClientId = {};

          if (this.master.peerConnectionStatsInterval) {
            clearInterval(this.master.peerConnectionStatsInterval);
            this.master.peerConnectionStatsInterval = null;
          }

          if (this.master.localStream) {
            this.master.localStream = null;
          }
          if (this.master.localView) {
            this.master.localView.srcObject = null;
            this.master.localView = null;
          }

          if (this.master.dataChannelByClientId) {
            this.master.dataChannelByClientId = {};
          }

          if (this.master.onCloseCallback) {
            this.master.onCloseCallback(result);
            this.master.onCloseCallback = null;
          }

          this.master.signalingClient = null;

          this.context.app.$kvsEventBus.$emit('master-close-finish', true);
        } else {
          this.context.app.$kvsEventBus.$emit('master-close-finish', false);
        }
      });
      console.log('[MASTER] Disconnected from signaling channel');
    });

    (this.master.signalingClient as SignalingClient).on('error', () => {
      this.context.app.$kvsEventBus.$emit('master-error', 'error');
      console.error('[MASTER] Signaling client error');
    });

    console.log('[MASTER] Starting this.master connection');
    (this.master.signalingClient as SignalingClient).open();
  }

  /**
   * KVSを切断します
   *
   * 注意: この関数を呼び出した後、'master-close'イベントの処理が完了してから完全にKVSが切断されることに留意してください
   */
  public stopMaster(callback: (result: boolean) => void) {
    console.log('[MASTER] Stopping this.master connection');
    if (this.master.signalingClient) {
      this.master.onCloseCallback = callback;
      (this.master.signalingClient as SignalingClient).close();
    } else {
      callback(true);
    }
  }

  /**
   * データを送ります
   *
   * @param {any} data
   */
  private sendMasterData(data: any) {
    const dataJson = JSON.stringify(data);

    Object.keys(this.master.dataChannelByClientId).forEach((clientId) => {
      try {
        this.master.dataChannelByClientId[clientId].send(dataJson);
      } catch (e) {
        console.error('[MASTER] Send DataChannel: ', e.toString());
      }
    });
  }

  /**
   * メッセージを送ります
   *
   * @param {MessageObject} data
   */
  public sendMasterMessage(data: MessageObject) {
    return this.sendMasterData(data);
  }

  /**
   * [BEFORE CLOSE]コマンドを送ります
   */
  public sendMasterBeforeClose() {
    return this.sendMasterData({
      dataType: KvsDataType.COMMAND,
      command: KvsCommand.BEFORE_CLOSE,
    } as CommandObject);
  }
}

const kvsMasterPlugin: Plugin = (context, inject) => {
  inject(
    'kvsMaster',
    (
      localStream: MediaStream,
      localView: HTMLVideoElement,
      onStatsMasterReport: StatusReportHandler,
      onRemoteDataMessage: MessageHandler
    ) => {
      return new MasterKVS(localStream, localView, onStatsMasterReport, onRemoteDataMessage, context);
    }
  );
};

export default kvsMasterPlugin;
