










import Vue from 'vue';
import StartupAdapter from '@/store/types/adapters/startupAdapter';
import Header from '@/components/Common/Header.vue';
import Footer from '@/components/Common/Footer.vue';
import Loader from '@/components/Common/Loader.vue';
import Modals from '@/components/Common/Modals/Modals.vue';
import * as rootTypes from '@/store/types/rootType';
import * as commonTypes from '@/store/types/commonType';
import { PagePathUtils } from '@/store/enum/pageTransition';

export default Vue.extend({
  components: {
    Header,
    Footer,
    Loader,
    Modals,
  },
  computed: {
    inParam(): StartupAdapter {
      return this.$store.getters[rootTypes.GETTER_STARTUP];
    },
    /**
     * ヘッダとフッタを非表示か調べます
     *
     * @param {boolean}
     */
    isLayoutVisible(): boolean {
      return this.$store.getters[commonTypes.GETTER_COMMON].isLayoutVisible;
    },
    /**
     * <body>タグに指定するID
     *
     * @param {string}
     */
    bodyId(): string {
      const disabled = this.$store.getters[commonTypes.GETTER_COMMON].isLayoutBodyIdAutoSetupDisabled;
      if (disabled) {
        return '';
      }

      return PagePathUtils.getBodyId(this.$route.path);
    },
  },
  beforeCreate() {
    // TODO: F5を押されても、ログイン画面では復帰できるようにしている？
    let queryJSONString;
    try {
      queryJSONString = window.localStorage.getItem('startup'); //
    } catch (e) {
      console.log('window.localStorageが使用できませんでした。');
      queryJSONString = '{}'; // dummyデータ
    }
    const parsedQueries = JSON.parse(queryJSONString as string) as any;
    this.$store.dispatch(rootTypes.ACTION_STARTUP, parsedQueries);
  },
  mounted() {
    this.$window.setBeforeUnload(true);
    this.$window.startWakeLock();

    (this.$nuxt.context.app.head as any).htmlAttrs.lang = this.inParam.lang;
  },
});
