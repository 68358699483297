export function getStrWithHtmlNewLine(str: string): string {
  return str.trim().replace(/\n/g, '<br/>');
}

// compare two urls but ignore the last / if it exists
export function isSameURL(url1: string, url2: string): boolean {
  const url1WithoutLastSlash = url1.replace(/\/$/, '');
  const url2WithoutLastSlash = url2.replace(/\/$/, '');
  return url1WithoutLastSlash === url2WithoutLastSlash;
}

// theck if link is a local link (start with `/`)
export function isLocalLink(link: string) {
  return link.startsWith('/');
}

// return empty string if value is null or undefined
export function valid(value: string | null | undefined) {
  return value || '';
}
