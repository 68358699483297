/* eslint-disable camelcase */
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import StartupAdapter from '../types/adapters/startupAdapter';
import { ErrorStatus } from '../types/adapters/errorAdapter';
import { LoginAdapter, LoginRequestType, LoginResponseType } from '@/store/types/adapters/loginAdapter';
import * as loginTypes from '@/store/types/loginType';
import * as rootTypes from '@/store/types/rootType';
import * as errorTypes from '@/store/types/errorType';
import { Endpoint } from '@/store/const/endpoint';

const state = () => {
  return new LoginAdapter();
};

type LoginState = ReturnType<typeof state>;

const getters: GetterTree<LoginState, LoginState> = {
  // ログインストアデータ取得処理
  [loginTypes.GETTER_LOGIN](state: LoginState) {
    return { ...state };
  },
  // アクセストークン有無取得処理
  [loginTypes.GETTER_IS_TOKEN](state: LoginState): boolean {
    return !!state.accessToken;
  },
  // アクセストークン取得処理
  [loginTypes.GETTER_TOKEN_VALUE](state: LoginState): string | null {
    return state.accessToken;
  },
};

const mutations: MutationTree<LoginState> = {
  // ログインストア更新処理
  [loginTypes.MUTATION_LOGIN](state: LoginState, payload: LoginAdapter) {
    state.accessToken = payload.accessToken;
    state.examUserName = payload.examUserName;
    state.loginId = payload.loginId;
    state.actor = payload.actor;
    state.kicked = payload.kicked;
    state.group = payload.group;
    state.groupId = payload.groupId;
    state.startupUrl = payload.startupUrl;
  },
  // ログインストアクリア処理
  [loginTypes.MUTATION_CLEAR_LOGIN_STATE](state: LoginState) {
    state.accessToken = null;
    state.examUserName = null;
  },
};

const actions: ActionTree<LoginState, LoginState> = {
  // ログイン処理
  [loginTypes.ACTION_LOGIN](
    context,
    { login_id, password = '', actor }: LoginRequestType
  ): Promise<LoginResponseType | ErrorStatus> {
    console.log('[LOG INFO] login data : ', login_id, ' / ', password);
    if (login_id != null && login_id.length > 0) {
      login_id = login_id.trim();
    }
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(Endpoint.LOGIN.url, {
          login_id,
          password,
          actor,
        })
        .then(({ status, result, message }: LoginResponseType) => {
          // LOGIN_ID or PASSWORD エラー
          if (status === 200) {
            const act = result.actor * 1;
            context.commit(loginTypes.MUTATION_LOGIN, {
              examUserName: result.exam_user_name,
              accessToken: result.access_token,
              loginId: login_id,
              actor: act,
              kicked: result.kicked,
              group: result.group,
              groupId: result.group_id,
              startupUrl: result.startup_url,
            });
            resolve({ status, result, message });
          } else {
            const err: ErrorStatus = {
              endpoint: Endpoint.LOGIN,
              status,
              message,
              result,
            };
            context.dispatch(errorTypes.ACTION_SET_ERROR, err);
            resolve({ status, result, message });
          }
        })
        .catch((e: any) => {
          console.error('[Login error] : ', e);
          this.$router.replace('/login');
          reject(e);
          // TODO : will be implement error log api request.
        });
    });
  },
  // ログアウト処理
  [loginTypes.ACTION_LOGOUT](context): Promise<void> {
    console.log('[LOG INFO] logout data : ');
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(
          Endpoint.LOGOUT.url,
          {},
          {
            params: {
              p: 'self',
            },
          }
        )
        .then(() => {
          context.commit(loginTypes.MUTATION_CLEAR_LOGIN_STATE);
          resolve();
        })
        .catch((e: any) => {
          console.error('[Logout error] : ', e);
          reject(e);
        });
    });
  },
  /**
   * 本来のログインとは異なる方法で、アクセストークンを設定するための処理 別ウィンドウでこのアプリを開いたときのための処理
   *
   * 基本的に使ってはいけない
   * 以下の場所で例外的に使っている 他はいずれ消す
   * <li>plugins/global/window.ts
   * <li>store/const/endpoint.ts
   *
   * @param {Context} context
   * @param {LoginAdapter} payload
   */
  [loginTypes.ACTION_SET_TOKEN_EXAM_NAME](context, payload: LoginAdapter) {
    context.commit(loginTypes.MUTATION_LOGIN, payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
