export default `
「AI監視サービス」は、一般財団法人 国際ビジネスコミュニケーション協会が、株式会社イー･コミュニケーションズが提供する「リモートテスティング」を利用して運営しております。受験にあたり、以下の内容をお読みいただき、同意の上、利用をお願いします。

＜AI監視サービス利用規約について＞
AI監視サービスの利用にあたっては、リモートテスティングの利用規約（https://www.remote-testing.jp/terms/）、ならびに、受験のしおり記載の禁止行為等を遵守してください。

＜AI監視サービス利用における個人情報の取扱いについて＞
●	AI監視サービス利用時に取得した個人情報（カメラ映像・音声等）は、試験実施および試験監督業務およびその改善のために利用いたします。なお、利用目的達成後は、当協会の責任において、適切に処理または廃棄いたします。
●	当協会は上記目的達成のために必要な範囲内で、個人情報保護に関する契約を締結した上で、取得した個人情報の取扱いを委託いたします。
●	記録された映像・音声の解析結果は、試験実施団体または申込団体に提供いたします。また、団体のご要望により、映像・音声データ自体を提供することがあります。その他個人情報保護法第23条第1項各号所定の場合を除いては、第三者に提供・開示することはいたしません。
●	個人情報の利用目的の通知、開示、訂正、追加、削除、利用の停止、消去または第三者への提供の停止、第三者提供記録の開示を希望される場合は、ご本人であることを確認した上で対応いたします。当協会までご連絡ください。

＜提供の任意性について＞
個人情報のご提供は任意ですが、当協会が必須として指定した情報をご提供いただけない場合は、ご受験ができなくなりますのでご了承ください。

<div style='text-align:right'>
個人情報取扱管理者
一般財団法人 国際ビジネスコミュニケーション協会  IP事業本部  本部長
</div>
なお、株式会社イー･コミュニケーションズのプライバシーポリシーは以下のURLよりご確認ください。
https://www.e-coms.co.jp/privacy/

以下のチェックボックスにチェックして「次へ」を押すと、本人確認用画像およびIDの撮影画面に遷移します。

`;
