/* eslint-disable prettier/prettier */
import { Middleware, Context } from '@nuxt/types';
import { camelCase } from 'camel-case';
import * as rootTypes from '@/store/types/rootType';
import * as loginTypes from '@/store/types/loginType';
import { PagePathUtils } from '@/store/enum/pageTransition';
import StartupAdapter from '@/store/types/adapters/startupAdapter';
import { LoginAdapter } from '@/store/types/adapters/loginAdapter';
import { checkServiceWorker, clearServiceWorker, askForNPerm } from '@/static/push';

// 以下のimportで日系、またはそれ以外のブラウザチェックを判定
import { targetBrowsers } from './checkBrowser';
// import { targetBrowsers } from './checkBrowser_nikkei';

/** スタートアップURLのクエリをパースしたオブジェクト */
type ParsedQueries = { [key: string]: string | number };

/**
 * スタートアップURLのクエリをパースします
 *
 * @return {ParsedQueries}
 */
function paseQuery(): ParsedQueries {
  const parsedQueries: ParsedQueries = {};
  let inParams;
  if (true /*! context.isDev */) {
    inParams = location.search;
    if (!inParams) {
      // context.error({
      //   statusCode: 400,
      //   message: "不正な接続です。「起動パラメータ」がありません。"
      // });
      console.error('[Middleware LOG]: 「起動パラメータ」がありません。', inParams);
    }
  } else {
    // MC起動：受験生 => LOGIN 必須 - Actor 1 (login_id x , access_token o)
    inParams =
      '?target=https%3A%2F%2Frp.mc-plus-st.jp&actor=1&is_auth=1&exam_url=http%3A%2F%2Faaaaaaaaaaaaaaaaaaaaa.com&is_mc_startup=1&is_proctor=1&is_record=1&is_summary=1&is_force=1&lang=ja&max_rectime=3600&tester_retry=2&is_mobile=1&matching_timeout=20&intervaltime=100&is_voice_recording=1&video_recording_preference=5&voice_quality_preference=5&is_forget_password=1&target_type=2&memo=%E3%82%81%E3%82%82%E3%82%81%E3%82%82%E3%82%81%E3%82%82%E3%83%BB%E3%83%BB%E3%83%BB%E3%83%BB%E3%83%BB%E3%83%BB%E3%83%BB%E3%83%BB%E3%83%BB%E3%83%BB%E3%83%BB%E3%83%BB%E3%83%BB&access_token=82dbd59d77fd11a98d416c2fbcda39cd44a754507a3e057bf32157c4a4309262a';

    // JT・MC起動：受験生 => LOGIN なし - Actor 2 (login_id o , access_token o)
    // inParams = '?login_id=abcd&target=https%3A%2F%2Ftest.remote-testing-st.jp%2Fapi%2Fv1%2Fcheck_fld%2Fauth_jt.php&actor=1&password=abcd12345&is_auth=1&is_mc_startup=1&exam_url=http%3A%2F%2Faaaaaaaaaaaaaaaaaaaaa.com&is_proctor=1&is_record=1&is_summary=1&is_force=1&lang=ja&max_rectime=3600&tester_retry=2&is_mobile=1&matching_timeout=300&webrtc_timeout=300&intervaltime=100&is_voice_recording=1&video_recording_preference=5&voice_quality_preference=5&memo=%E3%82%81%E3%82%82%E3%82%81%E3%82%82%E3%82%81%E3%82%82%E3%83%BB%E3%83%BB%E3%83%BB%E3%83%BB%E3%83%BB%E3%83%BB%E3%83%BB%E3%83%BB%E3%83%BB%E3%83%BB%E3%83%BB%E3%83%BB%E3%83%BB&access_token=85cb43708e4f75a740f9eff2c5c35290efcb2982c8daee5a0fc0f2b0a72588edb&exam_name=JT%E7%94%B0%E4%B8%AD&exam_user_name=JT%E7%94%B0%E4%B8%AD';

    // 試験監視者 : LOGIN必須 - Actor 3 (login_id o , access_token o)
    // inParams = '?target=https%3A%2F%2Ftest.remote-testing-st.jp%2Fapi%2Fv1%2Fcheck_fld%2Fauth_admin.php&actor=2&lang=ja&max_rectime=3600&checker_retry=2&matching_timeout=300&webrtc_timeout=10000&intervaltime=300';

    // 試験管理者(admin) : LOGIN必須 - Actor 4 (login_id o , access_token o)
    // inParams = '?target=https%3A%2F%2Ftest.remote-testing-st.jp%2Fapi%2Fv1%2Fcheck_fld%2Fauth_admin.php&actor=3&lang=ja&is_forget_password=1&target_type=1';
  }
  inParams
    .split('?')[1]
    .split('&')
    .forEach((v) => {
      // eslint-disable-next-line prefer-const
      let [key, value] = v.split('=');
      if (isNaN(+value)) {
        if (value === '%22%22' || value === '%27%27') {
          value = '';
        }
        parsedQueries[camelCase(key)] = decodeURIComponent(value);
      } else {
        parsedQueries[camelCase(key)] = +value;
      }
    });

  return parsedQueries;
}

/**
 * ログインしていない場合は「ログイン」画面、ログイン済みの場合は初期画面に遷移します
 *
 * @param {Context} context
 * @param {StartupAdapter} startup
 */
function initTransitionPage(context: Context, startup: StartupAdapter) {
  context.store.dispatch(loginTypes.ACTION_SET_TOKEN_EXAM_NAME, {
    accessToken: startup.accessToken,
    examUserName: startup.examUserName,
    loginId: startup.loginId,
  } as LoginAdapter);

  if (startup.actor == 1 && startup.isMcStartup == 0) {
    // 受験者[外部試験]
    const wH = window.screen.availHeight;
    const wW = window.screen.availWidth;
    const wT = 0; // モニター左下 Top計算
    const wL = 0; // モニター左下 Left計算
    const wOption =
      'top=' +
      wT +
      ', left=' +
      wL +
      ', height=' +
      wH +
      ', width=' +
      wW +
      ', menubar=no' +
      ', toolbar=no' +
      ', location=no' +
      ', resizable=no' +
      ', directories=no';
    (context as any).$window.open('/login', {}, '_blank', wOption);

    // 現在のウィンドウを閉じる
    window.close();
  } else {
    // 受験者[MC+] && 受験者以外
    const router = context.app.router as any;
    router.replace('/login');
  }
}

/**
 * ブラウザチェック処理
 *
 * @param {Context} context
 * @return {boolean}
 */
function checkBrowser(context: Context): boolean {
  const browserInfo = getBrowserInfo();
  console.log(browserInfo);

  const target = targetBrowsers.find((targetBrowser) => {
    if (targetBrowser.customMatch) {
      return targetBrowser.customMatch!(targetBrowser, browserInfo);
    } else {
      return (
        browserInfo.os === targetBrowser.os &&
        browserInfo.osVersion >= targetBrowser.osVersion &&
        browserInfo.name === targetBrowser.name &&
        browserInfo.version >= targetBrowser.version
      );
    }
  });

  if (typeof target === 'undefined') {
    context.redirect('/alerting/excluded-browser');
    return false;
  }
  return true;
}

/**
 * バージョン文字列を浮動小数点数に変換する
 *
 * @param {string} versionString
 * @return {number}
 * @private
 */
function toFloatFromVersionString(versionString: string): number {
  const sep = versionString.indexOf('_') !== -1 ? '_' : '.';
  const numbers = versionString.split(sep);
  const major = numbers.length > 0 ? (numbers.shift() as any) - 0 : 0;
  const minor: any = numbers.length > 0 ? (('0.' + numbers.join('')) as any) - 0 : 0.0;
  return major + minor;
}

/**
 * ユーザエージェント解析用モジュール
 */
const userAgentAnalyzer: any /*{[key:strig] : {[key: string] : (userAgent: string) => any}}*/ = {
  osVersion: (function () {
    const template = function (regex: RegExp) {
      return function (userAgent: string) {
        const matched = userAgent.match(regex);
        if (!matched || matched.length <= 1) {
          return 0;
        }

        return toFloatFromVersionString(matched[1]);
      };
    };

    return {
      windows: function (userAgent: string) {
        // @see http://www9.plala.or.jp/oyoyon/html/script/platform.html

        if (userAgent.match(/Win(dows )?NT 6\.3/)) {
          return 8.1; // Windows 8.1 の処理 (Windows Server 2012 R2)
        }

        return template(/Windows NT ([0-9.]+);/i)(userAgent);
      },
      android: template(/Android ([0-9.]+);/i),
      mac: template(/Mac OS X ([0-9_]+)/i),
      iOS: template(/OS ([0-9_]+) like Mac OS X/i),
      iPadOS: template(/OS ([0-9_]+) like Mac OS X/i),
    };
  })(),
};

/**
 * UAからブラウザの各情報を取得します
 *
 * @return {{
 *           os: string;        OS名
 *           osVersion: number; OSバージョン
 *           name: string;      ブラウザ名
 *           version: number;   ブラウザバージョン
 *         }}
 */
function getBrowserInfo() {
  console.log(navigator.userAgent);

  const userAgent = navigator.userAgent;
  const result = {
    os: '',
    osVersion: 0,
    name: '',
    version: 0,
  };

  const match =
    userAgent.match(/(opera|chrome|crios|safari|ucbrowser|firefox|msie|trident|edge|edg(?=\/))\/?\s*([\d\.]+)/i) || [];

  // iPhoneでsafariが確認されないパターンの対策
  const isiPhone = /iPhone/.test(userAgent);

  // ブラウザ名
  let tem = null;
  if (/trident/i.test(match[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
    result.name = 'Internet Explorer';
  } else if (match[1] === 'Chrome') {
    tem = userAgent.match(/\b(OPR|Edge|Edg)\/(\d+)/);
    if (tem && tem[1]) {
      result.name = tem[0].indexOf('Edge') === 0 || tem[0].indexOf('Edg') === 0 ? 'Edge' : 'Opera';
    }
  } else if (match[1] === 'Safari') {
    result.name = 'Safari';
  } else if (match.length === 0 && isiPhone) {
    result.name = 'Safari';
  }
  if (!result.name) {
    tem = userAgent.match(/version\/(\d+)/i); // iOS support
    result.name = match[0].replace(/\/.*/, '');

    if (result.name.indexOf('MSIE') === 0) {
      result.name = 'Internet Explorer';
    }
    if (userAgent.match('CriOS')) {
      result.name = 'Chrome';
    }
  }

  // ブラウザバージョン
  if (match.length !== 0) {
    if (result.name == 'Safari') {
      var versionMatch = userAgent.match(/version\/([\d\.]+)/i);
      result.version =
        versionMatch && versionMatch.length > 1
          ? toFloatFromVersionString(versionMatch[1])
          : toFloatFromVersionString(match[match.length - 1]);
    } else {
      result.version = toFloatFromVersionString(match[match.length - 1]);
    }
  }

  // OS名 / OSバージョン
  if (userAgent.match(/Android/i)) {
    result.os = 'Android';
    result.osVersion = userAgentAnalyzer.osVersion.android(userAgent);
  } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
    result.os = 'iOS';
    result.osVersion = userAgentAnalyzer.osVersion.iOS(userAgent);
  } else if (userAgent.match(/Windows/i)) {
    result.os = 'Windows';
    result.osVersion = userAgentAnalyzer.osVersion.windows(userAgent);
  } else if (userAgent.match(/Macintosh/i)) {
    if (result.name === 'Safari' && typeof document.ontouchstart !== 'undefined') {
      result.os = 'iPadOS';
      result.osVersion = userAgentAnalyzer.osVersion.iPadOS(userAgent);
    } else {
      result.os = 'Mac';
      result.osVersion = userAgentAnalyzer.osVersion.mac(userAgent);
    }
  }

  return result;
}

/**
 * POPUPブロックチェック処理
 *
 * @param {Context} context
 * @return {boolean}
 */
function checkPopupBlocker(context: Context): boolean {
  const href = '#';
  const popUp = window.open(
    href,
    '_blank',
    'top=-100,left=-100,width=100,height=100,menubar=no,toolbar=no,location=no,resizable=no,directories=no'
  );
  if (popUp === null || typeof popUp === 'undefined') {
    context.redirect('/alerting/popup-block');
    return false;
  } else {
    popUp.close();
    return true;
  }
}

/**
 * 通知チェック
 *
 * @param {Context} context
 */
function initCheckServiceWorker(context: Context) {
  checkServiceWorker().then((value) => {
    if (value) {
      console.log('[LOG INFO] ServiceWorker activity!');
      askForNPerm().then((bool: boolean) => {
        console.log('[LOG INFO]: push permission', bool);
        if (!bool) {
          const router = context.app.router as any;
          router.replace('/alerting/notification-block');
        }
        if (!navigator.serviceWorker.controller) {
          setTimeout(() => {
            console.error('[LOG INFO] ServiceWorkerが完全に登録されてないため、再登録します。 (強制ブラウザリロード)');
            context.store.dispatch(rootTypes.ACTION_LOGOUT_AND_REDIRECT_LOGIN_PAGE);
          }, 5000);
        }
      });
    } else {
      console.error('[LOG INFO] ServiceWorker denied!');
    }
  });
}

/**
 * ストレージブロックチェック処理
 *
 * @param {Context} context
 * @return {boolean}
 */
function checkStorage(context: Context): boolean {
  // localStorage/sessionStorage へダミーデータを書き込み、使用可能かどうかを事前チェックする
  const check = function (storageName: string, dataSize: number): boolean {
    try {
      const storage = (window as any)[storageName] as any;

      let data = '';
      if (!data.padStart) {
        while (data.length < dataSize) {
          data = data + 'xxxxxxxxxx';
        }
      } else {
        data = data.padStart(dataSize, 'x');
      }

      let result = true;
      try {
        storage.setItem('prepare', data);
      } catch (e) {
        result = false;
      }
      try {
        const v = storage.getItem('prepare');
        if (v !== data) {
          throw new Error();
        }
      } catch (e) {
        result = false;
      }
      try {
        storage.removeItem('prepare');
      } catch (e) {
        result = false;
      }

      return result;
    } catch (e) {
      return false;
    }
  };
  const dataSize = 2 * 1024 * 1024; // サイズは適当 アプリの要件に合わせて適切に変えること
  const results = [check('localStorage', dataSize), check('sessionStorage', dataSize)];
  if (results.some((v) => !v)) {
    context.redirect('/alerting/storage-block');
    return false;
  } else {
    return true;
  }
}

/**
 * ブラウザチェック & 初期化
 *
 * @param {Context} context
 */
const initCheckMiddleware: Middleware = (context: Context) => {
  console.log('[Middleware INFO] initialization implementation: ', context);
  // URLのGETパラメータをパースします
  let parsedQueries: any = paseQuery();
  // TODO: 本来、sessionStorageが使えるかのチェック前に使うのは、ダメなんだがスタートアップパラメータを設定するためにしょうがない
  // 現在の動作がF5を押しても微妙に復帰するので、その挙動を損なわないようにこの処理を行う
  // layouts/default.vueのbeforeCreatedも参照
  try {
    if (parsedQueries && Object.values(parsedQueries).length > 0) {
      window.localStorage.setItem('startup', JSON.stringify(parsedQueries)); //
    } else {
      parsedQueries = window.localStorage.getItem('startup'); //
    }
  } catch (e) {
    // nop
  }

  // TODO: 本来は、下のブラウザチェックの後にスタートアップパラメータを設定すべきだが、微妙に挙動が変わってしまうため、とりあえず先にセットアップを行う
  // HTMLの言語設定
  (context.app.head as any).htmlAttrs.lang = parsedQueries.lang;
  // スタートアップパラメータをストアにセットします
  context.store.dispatch(rootTypes.ACTION_STARTUP, parsedQueries);
  const startup = context.store.getters[rootTypes.GETTER_STARTUP] as StartupAdapter;

  // 受験者 & 監視者 共通 ブラウザチェック
  if (!checkBrowser(context)) {
    return;
  }
  // 受験者 ポップアップチェック
  if (startup.actor === 1) {
    if (!checkPopupBlocker(context)) {
      return;
    }
  }
  // 受験者 & 監視者 共通 ストレージチェック
  // TODO: $uaがtypescript通らないので暫定処置
  // @ts-ignore
  if (context.$ua.isFromPc()) {
    if (!checkStorage(context)) {
      return;
    }
  }
  // 通知チェック
  initCheckServiceWorker(context);

  // 画面遷移
  initTransitionPage(context, startup);
};

export default initCheckMiddleware;
