/* eslint-disable prettier/prettier */
// Getters
export const GETTER_MATCHING: string = 'matching/GETTER_MATCHING';

// Mutations
export const MUTATION_MATCHING: string = 'matching/MUTATION_MATCHING';
export const MUTATION_DELETE_MATCHING: string = 'matching/MUTATION_DELETE_MATCHING';
export const MUTATION_SET_HEAD_SHOT_URL: string = 'matching/MUTATION_SET_HEAD_SHOT_URL';
export const MUTATION_SET_ID_CARD_URL: string = 'matching/MUTATION_SET_ID_CARD_URL';

// Actions
export const ACTION_MATCHING_POST: string = 'matching/ACTION_MATCHING_POST';
export const ACTION_MATCHING: string = 'matching/ACTION_MATCHING';
export const ACTION_DELETE_MATCHING: string = 'matching/ACTION_DELETE_MATCHING';
export const ACTION_SET_HEAD_SHOT_URL: string = 'matching/ACTION_SET_HEAD_SHOT_URL';
export const ACTION_SET_ID_CARD_URL: string = 'matching/ACTION_SET_ID_CARD_URL';
