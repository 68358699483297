/* eslint-disable camelcase */
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { ErrorStatus } from '../types/adapters/errorAdapter';
import { Endpoint } from '@/store/const/endpoint';
import { MonitorActivateAdapter, MonitorActivateResponseType } from '@/store/types/adapters/monitorActivateAdapter';
import * as types from '@/store/types/monitorActivateType';
import * as errTypes from '@/store/types/errorType';

const state = () => {
  return new MonitorActivateAdapter();
};

type MonitorActivateState = ReturnType<typeof state>;

const getters: GetterTree<MonitorActivateState, MonitorActivateState> = {
  [types.GETTER_MONITOR_ACTIVATE](state: MonitorActivateState) {
    return { ...state };
  },
};

const mutations: MutationTree<MonitorActivateState> = {
  [types.MUTATION_MONITOR_ACTIVATE](state: MonitorActivateState, payload: MonitorActivateAdapter) {
    state.activation = payload.activation;
    state.monitorId = payload.monitorId;
  },
};

const actions: ActionTree<MonitorActivateState, MonitorActivateState> = {
  [types.ACTION_MONITOR_ACTIVATE_POST_REQEUST](context, payload: { monitorId: number; activation: number }) {
    console.log('[LOG INFO] MonitorActivateState data post');
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(Endpoint.MONITOR_ACTIVATE.url, {
          monitor_id: payload.monitorId,
          activation: payload.activation,
        })
        .then((response: MonitorActivateResponseType) => {
          if (response.status === 200) {
            resolve(true);
          } else {
            const err: ErrorStatus = {
              endpoint: Endpoint.MONITOR_ACTIVATE,
              message: response.message,
              status: response.status,
            };
            console.error('[LOG ERROR]: ', err);
            context.dispatch(errTypes.ACTION_SET_ERROR, err);
          }
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
