import { GetterTree, ActionTree, MutationTree } from 'vuex';
import * as type from '@/store/types/examineeLoginPageType';
import * as testerDetailType from '@/store/types/testerDetailType';
import * as webrtcMessageType from '@/store/types/webrtcMessageType';
import { ExamineeLoginPageAdapter } from '@/store/types/adapters/examineeLoginPageAdapter';
import { TesterDetailAdapter, TesterDetailRequestType } from '@/store/types/adapters/testerDetailAdapter';
import { Message } from '@/store/types/adapters/webrtcMessageAdapter';

const state = () => {
  return new ExamineeLoginPageAdapter();
};

type examineeLoginPage = ReturnType<typeof state>;

const getters: GetterTree<examineeLoginPage, examineeLoginPage> = {
  [type.GETTER_EXAMINEE_LOGIN_PAGE](state: ExamineeLoginPageAdapter) {
    return { ...state };
  },
};

const mutations: MutationTree<examineeLoginPage> = {
  [type.MUTATION_EXAMINEE_LOGIN_PAGE_TEST](state: examineeLoginPage, payload: TesterDetailAdapter) {
    state.testerDetail = payload;
  },
  [type.MUTATION_EXAMINEE_LOGIN_PAGE_MESSAGES](state: examineeLoginPage, payload: Message[]) {
    state.messages = payload;
  },
};

const actions: ActionTree<examineeLoginPage, examineeLoginPage> = {
  [type.ACTION_EXAMINEE_LOGIN_PAGE_GET_TEST](context, payload: TesterDetailRequestType): Promise<void> {
    return new Promise((resolve, reject) => {
      context
        .dispatch(testerDetailType.ACTION_GET_TESTER_DETAIL, payload)
        .then((test: TesterDetailAdapter) => {
          context.commit(type.MUTATION_EXAMINEE_LOGIN_PAGE_TEST, test);
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
  [type.ACTION_EXAMINEE_LOGIN_PAGE_GET_MESSAGE](context, payload: number): Promise<void> {
    return new Promise((resolve, reject) => {
      context
        .dispatch(webrtcMessageType.ACTION_WEBRTC_MESSAGE_GET, payload)
        .then((messages: Message[]) => {
          context.commit(type.MUTATION_EXAMINEE_LOGIN_PAGE_MESSAGES, messages);
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
