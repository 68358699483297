import { GetterTree, ActionTree, MutationTree } from 'vuex';
import StartupAdapter from './types/adapters/startupAdapter';
import * as types from './types/rootType';
import * as commonTypes from '~/store/types/commonType';
import * as loginTypes from '~/store/types/loginType';
import { JapaneseLanguageEnum, EnglishLanguageEnum, LanguageEnum } from '~/store/enum/language';

/**
const state = () => ({
  things: [] as string[],
  name: 'Me',
})
 */
const state = () => {
  return {
    startup: new StartupAdapter(),
    displayLang: JapaneseLanguageEnum as LanguageEnum,
  };
};

type rootState = ReturnType<typeof state>;

/**
const getters: GetterTree<RootState, doSomeState> = {
  name: state => state.name,
}
*/
const getters: GetterTree<rootState, rootState> = {
  [types.GETTER_STARTUP](state: rootState): StartupAdapter {
    return state.startup;
  },
  [types.GETTER_STARTUP_URL](state: rootState): string {
    return state.startup.rawStartupUrl;
  },
  [types.GETTER_DISPLAY_LANG](state: rootState): LanguageEnum {
    return state.displayLang;
  },
};

/**
const mutations: MutationTree<RootState> = {
  CHANGE_NAME: (state, newName: string) => (state.name = newName),
}
*/
const mutations: MutationTree<rootState> = {
  [types.MUTATION_STARTUP](state: rootState, payload: StartupAdapter) {
    state.startup = payload;
  },
  [types.MUTATION_SET_LANGUAGE](state: rootState, payload: LanguageEnum) {
    state.displayLang = payload;
  },
};
/**
const actions: ActionTree<RootState, RootState> = {
  fetchThings({ commit }) {
    const dosme_1 = this.$axios.$get('http://icanhazip.com');
    const dosme_2 = this.$axios.get('http://icanhazip.com');
    console.log(things)
    commit('CHANGE_NAME', 'New name')
  },
}
*/
const actions: ActionTree<rootState, rootState> = {
  [types.ACTION_STARTUP]({ commit }, startup: any) {
    // スタートアップパラメータ設定
    const startParamss = new StartupAdapter().setStartupValue(startup);
    commit(types.MUTATION_STARTUP, startParamss);

    // 言語設定
    this.dispatch(types.ACTION_SET_LANGUAGE, startParamss.lang);
  },
  [types.ACTION_SET_LANGUAGE](context, payload: string) {
    const langMap = {
      ja: JapaneseLanguageEnum,
      en: EnglishLanguageEnum,
      zn: JapaneseLanguageEnum, // TODO:
    } as any;
    const lang = langMap[payload] || langMap['ja']; // TODO: デフォルトjaにしているが後で修正?
    context.commit(types.MUTATION_SET_LANGUAGE, lang);
  },
  [types.ACTION_CLEAR_STARTUP]({ commit }) {
    const startParams = new StartupAdapter();
    commit(types.MUTATION_STARTUP, startParams);
    this.dispatch(types.ACTION_SET_LANGUAGE, startParams.lang);
  },
  /**
   * ログアウトして、ログイン画面に遷移します startupURLにリダイレクトするため、ストア内のデータをすべて初期化します
   *
   * @param {Context} context
   * @return {Promise<any>}
   */
  [types.ACTION_LOGOUT_AND_REDIRECT_LOGIN_PAGE](context) {
    const startupUrl = context.getters[types.GETTER_STARTUP_URL];

    return new Promise((resolve) => {
      context
        .dispatch(commonTypes.ACTION_COMMON_SET_LOADING, true)
        .then(
          () =>
            new Promise((resolve, reject) => {
              context
                .dispatch(loginTypes.ACTION_LOGOUT)
                .then(() => this.app.$window.setBeforeUnload(false))
                .then(() => (location.href = startupUrl))
                .finally(() => resolve());
            })
        )
        .finally(() => {
          // 画面遷移前にローディングフィルタが解除されるので、この処理はいらない
          // どうせ、画面遷移するので
          // context.dispatch(commonTypes.ACTION_COMMON_SET_LOADING, false);
          resolve(true);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
