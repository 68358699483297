// Getters
export const GETTER_AI_AUTH_STATUS: string = 'aiAuthStatus/GETTER_AI_AUTH_STATUS';

// Mutations
export const MUTATION_AI_AUTH_STATUS: string = 'aiAuthStatus/MUTATION_AI_AUTH_STATUS';

// Actions
export const ACTION_REQUEST_GET_AI_AUTH_STATUS: string = 'aiAuthStatus/ACTION_REQUEST_GET_AI_AUTH_STATUS';
// aiAuthStatusType

export const ACTION_AI_AUTHENTICATION_PAGE_KICK_OUT: string = 'aiAuthStatus/ACTION_AI_AUTHENTICATION_PAGE_KICK_OUT';
