import { ExamProcess } from '@/store/enum/ExamProcess';

/* eslint-disable camelcase */
export class ExamStatusAdapter {
  constructor(public loginId: string = '', public process: ExamProcess | null = null, public eai: string | null = '') {}
}

export type ExamStatusRequestType = {
  loginId?: string;
  process: ExamProcess;
  eai: string;
};

export type ExamStatusResponseType = {
  status: number;
  message: string;
  result: object;
};
