const targetBrowsers: {
  os: string;
  osVersion: number;
  name: string;
  version: number;
  customMatch?: (self: any, browserInfo: any) => boolean;
}[] = [
  { os: 'Windows', osVersion: 10.0, name: 'Chrome', version: 80.0 },
  { os: 'Windows', osVersion: 10.0, name: 'Edge', version: 80.0 },
  {
    os: 'Mac',
    osVersion: 10.157,
    name: 'Safari',
    version: 14.03,
    customMatch: (self: any, browserInfo: any): boolean => {
      // TODO: 暫定対応/社内MACでOSバージョンが正しく取れないためこのOSバージョンは通す
      if (
        browserInfo.os === self.os &&
        browserInfo.osVersion == 10.156 &&
        browserInfo.name === self.name &&
        browserInfo.version >= self.version
      ) {
        return true;
      }

      return (
        browserInfo.os === self.os &&
        browserInfo.osVersion >= self.osVersion &&
        !(11.02 > browserInfo.osVersion && browserInfo.osVersion >= 11) &&
        browserInfo.name === self.name &&
        browserInfo.version >= self.version
      );
    },
  },
  { os: 'Mac', osVersion: 10.136, name: 'Chrome', version: 80.0 },
];

export { targetBrowsers };
