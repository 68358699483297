import { GetterTree, ActionTree, MutationTree } from 'vuex';
import {
  Message,
  WebRTCMessageAdapter,
  WebRTCMessageGetResponseType,
  WebRTCMessageRequestType,
  WebRTCMessageResponseType,
} from '@/store/types/adapters/webrtcMessageAdapter';
import * as types from '@/store/types/webrtcMessageType';
import { Endpoint } from '@/store/const/endpoint';
import * as errTypes from '@/store/types/errorType';
import { ErrorStatus } from '@/store/types/adapters/errorAdapter';

const state = () => {
  return new WebRTCMessageAdapter();
};

type WebRTCMessageState = ReturnType<typeof state>;

const getters: GetterTree<WebRTCMessageState, WebRTCMessageState> = {};

const mutations: MutationTree<WebRTCMessageState> = {};

const actions: ActionTree<WebRTCMessageState, WebRTCMessageState> = {
  [types.ACTION_WEBRTC_MESSAGE_POST](context, payload: WebRTCMessageRequestType): Promise<void> {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(Endpoint.WEBRTC_MESSAGE.url, {
          message_type: payload.messageType,
          message: payload.message,
        })
        .then((response: WebRTCMessageResponseType) => {
          if (response.status === 200) {
            resolve();
          } else {
            const err: ErrorStatus = {
              endpoint: Endpoint.WEBRTC_MESSAGE,
              status: response.status,
              message: response.message,
            };
            context.dispatch(errTypes.ACTION_SET_ERROR, err);
            reject(err);
          }
        })
        .catch((err: any) => {
          console.error('[WebRTC Message Error] : ', err);
          reject(err);
        });
    });
  },
  [types.ACTION_WEBRTC_MESSAGE_GET](context, payload: number): Promise<Message[]> {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(Endpoint.WEBRTC_MESSAGE.url, {
          tester_id: payload,
        })
        .then((response: WebRTCMessageGetResponseType) => {
          if (response.status === 200) {
            resolve(
              response.result.map((message: any) => ({
                message: message.message,
                messageType: message.message_type,
                createdAt: message.created_at,
              }))
            );
          } else {
            const err: ErrorStatus = {
              endpoint: Endpoint.WEBRTC_MESSAGE,
              status: response.status,
              message: response.message,
            };
            context.dispatch(errTypes.ACTION_SET_ERROR, err);
            reject(err);
          }
        })
        .catch((err: any) => {
          console.error('[WebRTC Message Error] : ', err);
          reject(err);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
