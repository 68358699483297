/* eslint-disable prettier/prettier */
// Getters
// フォーム
export const GETTER_DELETE_PLAN_PAGE_GET_FORM_VALUES: string = 'deletePlanPage/GETTER_DELETE_PLAN_PAGE_GET_FORM_VALUES';
export const GETTER_DELETE_PLAN_PAGE_GET_FORM_VALUES_DELETE_PLAN_KEY: string =
  'deletePlanPage/GETTER_DELETE_PLAN_PAGE_GET_FORM_VALUES_DELETE_PLAN_KEY';
export const GETTER_DELETE_PLAN_PAGE_GET_FORM_VALUES_DATE: string =
  'deletePlanPage/GETTER_DELETE_PLAN_PAGE_GET_FORM_VALUES_DATE';

// Mutations
// フォーム
export const MUTATION_DELETE_PLAN_PAGE_CLEAR_FORM_VALUES: string =
  'deletePlanPage/MUTATION_DELETE_PLAN_PAGE_CLEAR_FORM_VALUES';
export const MUTATION_DELETE_PLAN_PAGE_SET_FORM_VALUES: string =
  'deletePlanPage/MUTATION_DELETE_PLAN_PAGE_SET_FORM_VALUES';
export const MUTATION_DELETE_PLAN_PAGE_SET_FORM_VALUES_DELETE_PLAN_KEY: string =
  'deletePlanPage/MUTATION_DELETE_PLAN_PAGE_SET_FORM_VALUES_ACTOR_KIND';
export const MUTATION_DELETE_PLAN_PAGE_SET_FORM_VALUES_DATE: string =
  'deletePlanPage/MUTATION_DELETE_PLAN_PAGE_SET_FORM_VALUES_SORT';

// Actions
// フォーム
export const ACTION_DELETE_PLAN_PAGE_CLEAR_FORM_VALUES: string =
  'deletePlanPage/ACTION_DELETE_PLAN_PAGE_CLEAR_FORM_VALUES';
export const ACTION_DELETE_PLAN_PAGE_SET_FORM_VALUES: string = 'deletePlanPage/ACTION_DELETE_PLAN_PAGE_SET_FORM_VALUES';
export const ACTION_DELETE_PLAN_PAGE_SET_FORM_VALUES_DELETE_PLAN_KEY: string =
  'deletePlanPage/ACTION_DELETE_PLAN_PAGE_SET_FORM_VALUES_DELETE_PLAN_KEY';
export const ACTION_DELETE_PLAN_PAGE_SET_FORM_VALUES_DATE: string =
  'deletePlanPage/ACTION_DELETE_PLAN_PAGE_SET_FORM_VALUES_DATE';
// API
export const ACTION_DELETE_PLAN_PAGE_DOWNLOAD: string = 'deletePlanPage/ACTION_DELETE_PLAN_PAGE_DOWNLOAD';
